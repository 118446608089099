import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import myModel from '../Services/api';

const initialState = {
   watchlist_loading: false,
   watchlist_status: '',
   watchlist_status2: '',
   allWatchList: [],
   watchListByCategory: [],
   first_load: true
}

export const addWatchList = createAsyncThunk(
   'watchList/addWatchList',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.create(`add-to-watchlist`, param.id)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const removeWatchList = createAsyncThunk(
   'watchList/removeWatchList',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.create(`remove-from-watchlist`, param.id)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const loadAllWatchList = createAsyncThunk(
   'watchList/loadAllWatchList',
   async (arg, { rejectWithValue }) => {
      try {
         const response = await myModel.find('my-watchlist')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const loadWatchListByCategory = createAsyncThunk(
   'watchList/loadWatchListByCategory',
   async (param, { rejectWithValue }) => {
      try {
         const response = await myModel.find(`my-watchlist`, param.id)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const watchListSlice = createSlice({
   name: 'watchList',
   initialState,
   reducers: {
      updateWatchlistPrice: (state, { payload: { auction_id, price, ws_no_of_bids } }) => {
         const idx = state.allWatchList.findIndex(w => w.id === auction_id)
         if(idx >= 0) {
            const selected = state.allWatchList[idx]
            selected.current_price = price
            selected.no_of_bids = ws_no_of_bids + 1
            state.allWatchList[idx] = selected
         }
      },
      updateWatchlistEndTime: (state, { payload: { id, endTime } }) => {
         const idx = state.allWatchList.findIndex(w => w.id === id)
         if(idx >= 0) {
            const selected = state.allWatchList[idx]
            selected.end_time = endTime
            state.allWatchList[idx] = selected
         }
      }
   },
   extraReducers: ({ addCase }) => {
      addCase(addWatchList.pending, (state) => {
         state.watchlist_loading = true
         state.watchlist_status = 'pending'
      })
      addCase(addWatchList.fulfilled, (state) => {
         state.watchlist_status = 'success'
      })
      addCase(addWatchList.rejected, (state) => {
         state.watchlist_status = 'rejected'
         state.watchlist_loading = false
      })

      addCase(removeWatchList.pending, (state) => {
         state.watchlist_loading = true
         state.watchlist_status = 'pending'
      })
      addCase(removeWatchList.fulfilled, (state) => {
         state.watchlist_status = 'success'
      })
      addCase(removeWatchList.rejected, (state) => {
         state.watchlist_status = 'rejected'
         state.watchlist_loading = false
      })

      addCase(loadAllWatchList.pending, (state) => {
         state.watchlist_loading = true
         state.watchlist_status = 'pending'
      })
      addCase(loadAllWatchList.fulfilled, (state, { payload }) => {
         if(payload?.length){
            let list = payload.map(p => p.auction)
            state.allWatchList = list
            state.watchlist_status = 'success'
            state.watchlist_loading = false
            state.first_load = false
         }else{
            state.allWatchList = []
            state.watchlist_status = 'success'
            state.watchlist_loading = false
            state.first_load = false
         }
      })
      addCase(loadAllWatchList.rejected, (state) => {
         state.watchlist_status = 'rejected'
         state.watchlist_loading = false
      })

      addCase(loadWatchListByCategory.pending, (state) => {
         state.watchlist_loading = true
         state.watchlist_status = 'pending'
      })
      addCase(loadWatchListByCategory.fulfilled, (state, { payload }) => {
         state.watchlist_status = 'success'
         state.watchListByCategory = payload
         state.watchlist_loading = false
      })
      addCase(loadWatchListByCategory.rejected, (state) => {
         state.watchlist_status = 'rejected'
         state.watchlist_loading = false
      })
   }
});

export const { updateWatchlistPrice, updateWatchlistEndTime } = watchListSlice.actions;
export default watchListSlice.reducer;