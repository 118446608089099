import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CountdownTimer, opt } from '../../utils';
import { hit_gavel, image_placeholder, sealed_icon, top_bidder_cup_1, zig_chereta } from '../../assets';
import { selectCategory } from '../../features/categorySlice';
import dayjs from 'dayjs';
import Loading from '../common/Loading';
import translate from '../../Services/translate';


const BidList = ({ showNotifierPopup, bidStatuses, canSeeTopBidder }) => {

   const navigate = useNavigate();
   const dispatch = useDispatch();

   const { language } = useSelector(({ language }) => language);
   const { bids } = useSelector(({ bid }) => bid);
   const {  updatedBidId } = useSelector(({ auction }) => auction);

   return (
      <div className = 'pt-2'>
         {
            bids.length && bidStatuses.length ?
         
            <div className = 'text-center pb-1 hidden md:block'>
               {bidStatuses.map(({ bid, current_price, isTopBidder, isAuctionEndingSoon, processing, hitGavel, ended }) => (
                  <div 
                     key = { bid.auction.id } 
                     className = {`relative border border-gray-400 text-gray-600 h-[102px] mb-3 rounded-lg flex w-full shadow-md shadow-stone-400 
                     ${ended ? 'bg-black/10' : 'bg-gradient-to-b from-gray-100 to-gray-300'}`}
                  >

                     <div className = 'w-[10%] rounded-l-lg overflow-hidden h-[102px] hover:overflow-visible hover:relative pr-2'>
                        <img 
                           className = 'cursor-pointer w-full h-full object-contain hover:scale-[2] origin-left duration-200 hover:z-10 hover:absolute' 
                           src = { bid?.auction?.product?.thumbnail ? bid.auction.product.thumbnail : image_placeholder } 
                           alt = { bid.auction.product.name } 
                           onClick = { () => { dispatch(selectCategory({ id: bid.auction.product.category })); navigate(`/auction/${bid.auction.id}`) }}
                        />
                     </div>

                     <div className = 'border-r border-gray-400 w-[70%] relative'>
                        <div className = 'border-b border-gray-400 pl-2 flex justify-start h-[55%]'>
                           <div className = 'relative'>
                              <h3
                                 onClick = { () => { dispatch(selectCategory({ id: bid.auction.product.category })); navigate(`/auction/${bid.auction.id}`) }}
                                 className = 'text-left text-lg hover:text-sky-600 cursor-pointer'
                              >
                                 { bid?.auction.product.name }
                              </h3>

                              <p className = 'text-xs text-left'>{ bid?.auction?.product?.summary ? bid?.auction.product.summary.slice(0, 120) + ' ...' : '---' }</p>

                              {current_price && !bid?.auction?.is_closed ?
                                 <div className = {`absolute top-0 right-0 left-0 h-14 flex items-center justify-center ${showNotifierPopup && (updatedBidId === bid?.auction?.id) ? 'show-notifier-popup' : 'hide-notifier-popup'}`}>
                                    <div className = 'flex text-white h-full rounded-lg border border-black w-[90%] justify-center items-center notifier-popup  px-3 bg-sky-800'>
                                       <div>
                                          { translate(266) } 
                                          <span className = 'text-lg font-bold'> {current_price && current_price?.toLocaleString('en', opt)} </span>
                                          { translate(267) }
                                       </div>
                                    </div>
                                 </div>
                                 : 
                                 null
                              }
                           </div>
                        </div>

                        {isTopBidder && !bid?.auction?.is_closed ?
                           <div 
                              title = {`${!ended ? translate(12) : translate(13)}`}
                              className = 'absolute right-2 top-1 flex flex-col items-center justify-center'>
                                 <img src = { top_bidder_cup_1 } alt = '' className = 'h-8 py-1 top-bidder'/>
                                 <p className = {`${!ended ? 'text-yellow-600' : 'text-lime-700'} text-sm`}>
                                    { !ended ? translate(45) : translate(46) }
                                 </p>
                           </div>
                           :
                           null
                        }

                        <div className = 'flex h-[45%]'>
                           {bid.auction.is_closed ?
                              <div className = 'border-r border-gray-400 w-[45%] flex items-center justify-center gap-3'>
                                 <img src = { language === 'Amharic' ? zig_chereta : language === 'Afaan-Oromoo' ? sealed_icon : sealed_icon } alt = '' className = 'h-10 w-10' />
                                 <div className = 'text-red-400 text-lg'>{translate(14)}</div>
                              </div>
                              :
                              <>
                                 <div className = 'border-r border-gray-400 w-[23%] bg-fourth-50 text-white flex flex-col justify-between'>
                                    <p className = 'text-xs text-left pl-2'>{ translate(3) }</p>
                                    {canSeeTopBidder() ?
                                       <p className = 'text-sm'>
                                          <span className = 'text-base lg:text-lg font-semibold pr-2'> { bid.auction.current_price.toLocaleString('en', opt) }</span>
                                          { translate(4) }
                                       </p>
                                       :
                                       <p 
                                          title = 'Click to go to Subscription page' 
                                          className = 'sm:text-sm lg:text-base font-semibold pr-2 text-green-300 hover:text-green-400  cursor-pointer' 
                                          onClick = {() => navigate('/subscription')}
                                       >
                                          { translate(343) }
                                       </p>
                                    }
                                 </div>

                                 <div className = 'border-r border-gray-400 w-[17%] flex flex-col justify-between'>
                                    <p className = 'text-xs text-left pl-2 truncate'>{ translate(15) }</p>
                                    <p className = 'text-sm'>
                                       <span className = 'text-base lg:text-lg font-semibold pr-2'> { bid.auction.min_increment.toLocaleString('en', opt) }</span> 
                                       { translate(4) }
                                    </p>
                                 </div>

                                 <div className = 'border-r border-gray-400 w-[18%] flex flex-col justify-between'>
                                    <p className = 'text-xs text-left pl-2 truncate'>{ translate(16) }</p>
                                    <p className = 'text-sm'>
                                       <span className = {`text-base lg:text-lg ${language === 'Amharic' && !bid.auction.max_increment ? 'font-normal' : 'font-semibold font-Roboto'} pr-2`}> { bid.auction.max_increment ? bid.auction.max_increment.toLocaleString('en', opt) : translate(81) }</span> 
                                       { bid.auction.max_increment ? translate(4) : null }
                                    </p>
                                 </div>
                              </>
                           }
                              <div className = 'border-r border-gray-400 w-[7%] flex flex-col justify-between'>
                                 <p className = 'text-xs text-left pl-2'>{ translate(17) }</p>
                                 <p className = 'text-lg font-semibold'>{ bid.auction.no_of_bids ? bid.auction.no_of_bids : '-' }</p>
                              </div>
                              

                           <div className = 'border-r border-gray-400 w-[15%] flex flex-col justify-between'>
                              <p className = 'text-xs text-left pl-2'>{ translate(20) }</p>
                              <CountdownTimer
                                 timestampMs = { new Date(bid.auction.end_time).getTime() }
                                 view = 'list'
                              />
                           </div>
                           <div className = 'w-[20%] flex flex-col justify-between'>
                              <p className = 'text-xs text-left pl-2'>{ translate(25) }</p>
                              <p className = 'text-lg font-semibold '>{ dayjs(new Date(bid.auction.end_time).toISOString()).format('h:mm a, MMM D, YYYY') }</p>
                           </div>
                        </div>
                     </div>

                     <div className = 'w-[22%]'>
                        <div className = 'flex w-full border-b border-gray-400 h-[55%]'>
                           <div className = 'border-r border-gray-400 w-1/3'>
                              <p className = 'text-xs text-left pl-2'>{ translate(18) }</p>
                              <p className = 'text-lg font-semibold pt-2'>{ bid?.auction?.lot_number ? bid.auction.lot_number : '-' }</p>
                           </div>

                           <div className = ' w-2/3 relative'>
                              {/* {!bid.auction.is_closed ?
                                 <> */}
                                    <p className = 'text-xs text-left pl-2'>{ translate(19) }</p>
                                    <p className = 'text-sm text-center pt-2'>
                                       <span className = 'text-base lg:text-lg font-semibold pr-2'> { bid.auction.initial_price.toLocaleString('en', opt) }</span> 
                                       { translate(4) }
                                    </p>
                                 {/* </>
                                 : 
                                 null
                              } */}

                              {isAuctionEndingSoon ?
                                 <div 
                                    title = { translate(29) }
                                    className = { `blob absolute right-1 top-1` }>
                                 </div>
                                 : 
                                 null
                              }
                           </div>

                        </div>

                        <div className = 'h-[45%]'>
                           <div className = 'h-full w-full p-1'>
                              <button 
                                 className = 'bg-primary-100 h-full w-full text-white text-sm hover:bg-lime-600 rounded-br'
                                 onClick = { () => { dispatch(selectCategory({ id: bid.auction.product.category })); navigate(`/auction/${bid.auction.id}`) }}
                                 name = 'view-details'
                              >
                                 { translate(37) }
                              </button>
                           </div>
                        </div>
                     </div>

                     {processing ?
                        <div className = 'bg-black/80 h-[88%] w-[12%] rounded-[50%] absolute right-[45%] top-[7%] flex items-center justify-center gap-3'>
                           <Loading min loading={true} loadingStyle= 'text-white w-7 h-7' /><div className = 'text-white text-lg font-semibold'>Processing</div>
                        </div>
                        : 
                        null
                     }

                     {ended ?
                        <div className = 'absolute inset-0 -rotate-[15deg] flex items-center justify-center text-orange-500 font-bold text-4xl h-10 w-[250px] mx-auto my-auto pb-4' style = {{textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'}}>
                           <div className = {`${language === 'Afaan-Oromoo' ? 'tracking-[.2em]' : 'tracking-[.8em]'} h-10`}>
                              { translate(33) }
                           </div>
                        </div>
                        : 
                        null
                     }

                     {hitGavel ? 
                        <div className = 'absolute right-[40%] -inset-y-4 pb-3 mx-auto my-auto h-full flex items-center justify-center'>
                           <img src = { hit_gavel } alt = '' className = 'h-40 object-cover' />
                        </div>
                        : 
                        null
                     }

                  </div>
               ))}

            </div>
            : 
            <div className = 'w-full flex justify-center pt-12 text-sky-600 font-semibold'>{ translate(265) }</div>
         }
      </div>
   )
}

export default BidList;
