import {  useState } from 'react';
import { useSelector } from 'react-redux';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';
import translate from '../../Services/translate';
import faq from '../../utils/faq.json';


const MobViewFaq = () => {

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   
   const [selected, setSelected] = useState(null);
   const [faqCategory, setFaqCategory] = useState(1);


   const toggleFaq = i => {
      if(selected === i){
         return setSelected(null)
      }
      setSelected(i)
   }

   
   return (
      <div className = 'px-1 sm:px-3'>
         <div className = 'self-center pb-1 mb-2 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(127) }</h2>
         </div>

         <div className = 'flex w-full px-1'>

            <div className = 'w-1/2 md:w-2/5 pr-1 md:pr-4 border-r border-gray-400 text-[10px] md:text-base mr-2 pt-2'>
               {faq.map((f, i) => (
                  <button 
                     key = { i } onClick = { () => {
                        setSelected(null)
                        setFaqCategory(f.category.id)
                     } } 
                     className = {`w-full h-12 mb-1 sm:mb-2 px-2 py-1 md:py-3  border border-gray-300 cursor-pointer shadow-lg rounded-lg mt-1 ${faqCategory === f.category.id ? 'bg-sky-600 text-white' : 'text-primary-100 bg-gray-100 hover:bg-gray-200'}`}
                     >
                     { f.category.name }
                  </button>
               ))}
            </div>

            <div className = 'wrapper mt-2 w-full'>
               <div className = 'accordion'>
                  {faq.find(f => f.category.id === faqCategory).body.map((item, i) => (
                     <div key = { i } className = 'item'>
                        <div className = 'title bg-sky-5 p-1 border-b border-gray-400 text-primary-100 hover:bg-gray-100' onClick = { () => toggleFaq(i) }>
                           <div className = 'flex'>
                              <div className = 'pt-1 pr-2 transition-all duration-200'>
                              { selected === i ? <FaCaretDown /> : <FaCaretRight /> }
                              </div>
                              <h2 className = 'text-sm md:text-base'>{ item.question }</h2>
                           </div>
                           <span className = 'text-lg'>{ selected === i ? '-' : '+' }</span>
                        </div>
                        <div className = { `${selected === i ? 'content show' : 'content'} text-xs sm:text-sm border-l-2 border-primary-100` }>
                           { item.answer }
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   )
}

export default MobViewFaq;