
const Modal = ({ visible, children, fullWidth, customWidth, bgBlur, bg }) =>  
   (
      <div className = {`${visible ? 'modal-open' : 'modal-close'} fixed z-50 overflow-y-hidden w-full h-screen inset-0 flex justify-center items-center md:pt-0 ${bgBlur ? 'backdrop-blur-md' : ''}`}>
         <div className = {`z-50 relative rounded-2xl shadow-stone-600 shadow-md  ${customWidth ? customWidth : ''} ${fullWidth ? 'min-w-[70%]' : 'min-w-[40%]'} ${bg ? bg : 'bg-white'}`} >

            {children}

         </div>
      </div>
   )
        
export default Modal;
