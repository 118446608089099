import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllWatchList, removeWatchList } from '../../features/watchListSlice';
import { getMySubscriptions, toggleBidSubmitModal } from '../../features/bidSlice';
import { loadAuctionById, setNewBidder } from '../../features/auctionsSlice';
import { isAuctionAboutToEnd, isInMyBids } from '../../utils';
import { toast } from 'react-hot-toast';
import WatchlistGrid from './WatchlistGrid';
import WatchlistList from './WatchlistList';
import ViewTypeToggle from '../viewControls/ViewTypeToggle';
import translate from '../../Services/translate';
import Loading from '../common/Loading';


const MyWatchList = () => {

   const dispatch = useDispatch();
   const navigate = useNavigate();

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { watchlist_loading, allWatchList, first_load, watchlist_status } = useSelector(({ watchList }) => watchList);
   const { viewType } = useSelector(({ viewControls }) => viewControls );
   const { newBidder, nowUtcTime } = useSelector(({ auction }) => auction);
   const { myBidsLight, is_subscription_started, my_subscriptions, subscriptionCheck_status } = useSelector(({ bid }) => bid);

   const [selectedWatchList, setSelectedWatchList] = useState(0);
   const [showNotifierPopup, setShowNotifierPopup] = useState(false);
   const [watchlistStatuses, setWatchlistStatuses] = useState([]);

   const canSeeTopBidder = () => {
      if(is_subscription_started){
         return subscriptionCheck_status
      }else{
        return true
      }
   }

   useEffect(() => {
      if (allWatchList.length) {
         setWatchlistStatuses(allWatchList.map((watchList) => ({
            watchList,
            current_price: null,
            startedBidding: false,
            isTopBidder: false,
            isAuctionEndingSoon: false,
            processing: false,
            hitGavel: false,
            ended: false,
         })));
      }
   }, [allWatchList]);

   useEffect(() => {
      if(allWatchList.length){
			setWatchlistStatuses((prevWatchlistStatuses) =>
            prevWatchlistStatuses.map((watchlistStatus) => {
               let idx = allWatchList.findIndex(watch => watch.id === watchlistStatus.watchList.id)
               const current_price = allWatchList[idx].current_price
               const { startedBidding, isTopBidder } = isInMyBids(allWatchList[idx], myBidsLight)
               const isAuctionEndingSoon = isAuctionAboutToEnd(watchlistStatus.watchList.end_time, nowUtcTime);
               const processing = new Date(watchlistStatus.watchList.end_time).getTime() - nowUtcTime < 0 && nowUtcTime < (new Date(watchlistStatus.watchList.end_time).getTime() + 2500)
               const hitGavel = (new Date(watchlistStatus.watchList.end_time).getTime() + 2500) - nowUtcTime < 0 && nowUtcTime - (new Date(watchlistStatus.watchList.end_time).getTime() + 2500) <= 5200
               const ended = new Date(watchlistStatus.watchList.end_time).getTime() + 2500 + 5200 < nowUtcTime

               return {
                  ...watchlistStatus,
                  current_price,
                  startedBidding,
                  isTopBidder,
                  isAuctionEndingSoon,
                  processing,
                  hitGavel,
                  ended,
               };
            })
         );
      }
      // eslint-disable-next-line
	}, [allWatchList, myBidsLight, nowUtcTime]);

   useEffect(() => {
      let timer;
      if(newBidder){
         setShowNotifierPopup(true)
         timer = setTimeout(() => {
            dispatch(setNewBidder(false))
            setShowNotifierPopup(false)
         }, 4000);
      }
      return () => clearTimeout(timer)
      // eslint-disable-next-line
   }, [newBidder])


   useEffect(() => {
      dispatch(getMySubscriptions())
      getWatchedList()
      // eslint-disable-next-line
   }, [])

   const getWatchedList = () => {
      dispatch(loadAllWatchList())
   }

   const goToBidSubmission = id => {
      if(is_subscription_started){
         if(my_subscriptions.length && my_subscriptions?.some((pack) => pack.is_active === true)){
            setSelectedWatchList(id)
            dispatch(loadAuctionById({ id })).then(() => {
               dispatch(toggleBidSubmitModal({ value: true }))
            })
         }else{
            navigate('/subscription')
         }
      }else{
         setSelectedWatchList(id)
         dispatch(loadAuctionById({ id })).then(() => {
            dispatch(toggleBidSubmitModal({ value: true }))
         })
      }
   }

   const removeFromWatchList = id => {
      dispatch(removeWatchList({ id })).then(() => {
         if(watchlist_status === 'success'){
            dispatch(loadAllWatchList())
            setTimeout(() => {
               if(watchlist_status === 'success'){
                  toast.success(translate(2), { duration: 3000 })
               }
            }, 500);
         }
      })
   }

   const watchlistProps = {
      goToBidSubmission,
      selectedWatchList,
      removeFromWatchList,
      showNotifierPopup,
      watchlistStatuses,
      canSeeTopBidder
   }

   if(watchlist_loading && first_load)
      return (
         <div className = 'flex justify-center w-full pt-12'>
            <Loading loading = { watchlist_loading } loadingStyle = 'text-sky-600 w-7 h-7' />
         </div>
      )
   
   return (
      <>
         <div className = 'self-center pb-1 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(147) }</h2>
         </div>

         {allWatchList && watchlistStatuses ?
            <div className = 'px-3'>

               <ViewTypeToggle />

               {viewType === 'grid' ?
                  <WatchlistGrid { ...watchlistProps } />
                  :
                  <WatchlistList { ...watchlistProps } />
               }
            </div>
            :
            null
         }
      </>
   )
}

export default MyWatchList;