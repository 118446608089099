import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CountdownTimer, opt } from '../../utils';
import { selectCategory } from '../../features/categorySlice';
import { image_placeholder, sealed_icon } from '../../assets';
import dayjs from 'dayjs';
import translate from '../../Services/translate';


const PurchaseList = () => {

   const navigate = useNavigate();
   const dispatch = useDispatch();
   
   const { language } = useSelector(({ language }) => language);
   const { purchases } = useSelector(({ purchase }) => purchase);


   return (
      <div className = 'pt-2'>
         {purchases.length ?
            <div className ='text-center pb-1 hidden md:block'>
               {purchases.map((purchase, i) => (
                  <div key = { i } className = 'border border-gray-400 text-gray-600 h-[102px] mb-3 rounded-lg flex w-full shadow-md shadow-stone-400 bg-gradient-to-b from-gray-100 to-gray-300'>

                     <div className = 'w-[10%] rounded-l-lg overflow-hidden h-[102px] hover:overflow-visible hover:relative pr-2'>
                        <img
                           className = 'cursor-pointer w-full h-full object-contain hover:scale-[2] origin-left duration-200 hover:z-10 hover:absolute'
                           src = { purchase?.product?.thumbnail ? purchase.product.thumbnail : image_placeholder }
                           alt = { purchase.product.name }
                           onClick = {() => { 
                              dispatch(selectCategory({ id: purchase.product.category }))
                              navigate(`/auction/${purchase.id}`)
                           }}
                        />
                     </div>

                     <div className = 'border-r border-gray-400 w-[70%]'>
                        <div className = 'border-b border-gray-400 pl-2 flex justify-start h-[55%]'>
                           <div>
                              <h3 
                              onClick = { () => { dispatch(selectCategory({ id: purchase.product.category })); navigate(`/auction/${purchase.id}`) }}
                              className = 'text-left text-lg hover:text-sky-600 cursor-pointer'
                              >
                              { purchase.product.name }
                              </h3>

                              <p className = 'text-xs text-left'>
                              { purchase?.product?.summary ? purchase.product.summary.slice(0, 100) + ' ...' : '---' }
                              </p>

                           </div>
                        </div>

                        <div className = 'flex h-[42%]'>
                           {purchase.is_closed ?
                              <div className = 'border-r border-gray-400 w-[45%] flex items-center justify-center gap-3'>
                                 <img src = { sealed_icon } alt = '' className = 'h-10 w-10' />
                                 <div className = 'text-red-400 text-lg'>{translate(14)}</div>
                              </div>
                              :
                              <>
                                 <div className = 'border-r border-gray-400 w-[23%] bg-fourth-50'>
                                       <p className = 'text-xs text-left text-white pl-2' name = 'current_price'>{ translate(3) } </p>
                                       <p className = 'text-sm text-white'>
                                       <span className = 'text-base lg:text-lg font-semibold pr-2'> { purchase.current_price.toLocaleString('en', opt) }</span>
                                       { translate(4) }
                                       </p>
                                 </div>

                                 <div className = 'border-r border-gray-400 w-[17%]'>
                                       <p className = 'text-xs text-left pl-2' name = 'min_increment'>{ translate(15) }</p>
                                       <p className = 'text-sm'>
                                          <span className = 'text-base lg:text-lg font-semibold pr-2'> { purchase.min_increment.toLocaleString('en', opt) }</span>
                                          { translate(4) }
                                       </p>
                                 </div>

                                 <div className = 'border-r border-gray-400 w-[18%]'>
                                    <p className = 'text-xs text-left pl-2 truncate' name = 'max_increment'>{ translate(16) }</p>
                                    <p className = 'text-sm'>
                                       <span className = {`text-base lg:text-lg ${language === 'Amharic' && !purchase.max_increment ? 'font-normal' : 'font-semibold font-Roboto'}pr-2`}> 
                                          { purchase.max_increment ? purchase.max_increment.toLocaleString('en', opt) : translate(81)}
                                       </span> 
                                       { purchase.max_increment ? translate(4) : null }
                                    </p>
                                 </div>
                              </>
                           }

                              <div className = 'border-r border-gray-400 w-[7%]'>
                                 <p className = 'text-xs text-left pl-2' name = 'bidders'>{ translate(17) }</p>
                                 <p className = 'text-lg font-semibold'>{ purchase.no_of_bids ? purchase.no_of_bids : '-' }</p>
                              </div>

                           <div className = 'border-r border-gray-400 w-[15%]'>
                              <p className = 'text-xs text-left pl-2' name = 'remaining_time'>{ translate(20) }</p>
                              <CountdownTimer
                              timestampMs = { new Date(purchase.end_time).getTime() }
                              forComp = 'auction'
                              />
                           </div>
                           <div className = 'w-[20%]'>
                              <p className = 'text-xs text-left pl-2' name = 'end_time'>{ translate(25) }</p>
                              <p className = 'text-lg font-semibold'>{ dayjs(new Date(purchase.end_time).toISOString()).format('h:mm a, MMM D, YYYY') }</p>
                           </div>
                        </div>
                     </div>

                     <div className = 'w-[22%]'>

                        <div className = 'flex w-full border-b border-gray-400 h-[55%]'>
                           <div className = 'border-r border-gray-400 w-1/3'>
                              <p className = 'text-xs text-left pl-2' name = 'lot_number'>{ translate(18) }</p>
                              <p className = 'text-lg font-semibold'>{ purchase.lot_number ? purchase.lot_number : '-' }</p>
                           </div>

                           <div className = 'w-2/3'>
                              {/* {!purchase.is_closed ?
                                 <> */}
                                    <p className = 'text-xs text-left pl-2' name = 'initial_price'>{ translate(19) }</p>
                                    <p className = 'text-sm text-center'>
                                       <span className = 'text-base lg:text-lg font-semibold pr-2'> { purchase.initial_price.toLocaleString('en', opt) }</span> 
                                       { translate(4) }
                                    </p>
                                 {/* </> 
                                 : 
                                 null
                              } */}
                           </div>
                        </div>

                        <div className = 'h-[45%]'>
                           <div className = 'h-full w-full p-1'>
                              <button
                              className = 'bg-primary-100 h-full w-full text-white text-xs hover:bg-lime-600'
                              onClick = { () => { dispatch(selectCategory({ id: purchase.product.category })); navigate(`/auction/${purchase.id}`) }}
                              >
                                 { translate(37) }
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
            : 
            <div className = 'w-full flex justify-center pt-12 text-sky-600 font-semibold'>{ translate(269) }</div> 
         }
      </div>
   )
}

export default PurchaseList;
