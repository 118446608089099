import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../features/languageSlice';
import translate from '../../Services/translate';


const SubMenu = ({ item, showSidebar }) => {
  
   const dispatch = useDispatch()
   const [subnav, setSubnav] = useState(false);

   const showSubnav = () => setSubnav(!subnav);

   const handleClick = (lang) => dispatch(setLanguage({ lang }))


   return (
      <>
         <NavLink 
            className = { ({ isActive }) => (isActive && item.title !== 'Language' && item.title !== 'Login' ? 'text-sky-600 sidebar-link-active' : 'text-gray-600 sidebar-link')}
            to = { item.path }
            onClick = { item.subNav ? showSubnav : showSidebar }>

            <div>{ item.icon }</div>

            <div>
               <div className = 'sidebar-label'>{ translate(item.id) }</div>
            </div>

            <div className = 'pl-4'>
               {item.subNav && subnav
                  ? item.iconOpened
                  : item.subNav
                  ? item.iconClosed
                  : null}
            </div>
         </NavLink>

         {
            subnav && item.subNav.map((item, index) => {
               return (
                  <div className = 'dropdown-link' key = { index } onClick = { showSidebar }>
                     <div>{ item.icon }</div>
                     <div onClick = { () => item.onClick ? handleClick(item.value) : null} className = 'sidebar-label w-full'>{ item.title }</div>
                  </div>
               );
            })
         }
      </>
   );
};

export default SubMenu;