import { MdOutlineMood } from "react-icons/md";

const MobViewSubscriptionSucess = () => {

   // const navigate = useNavigate();

   return (
      <div className = 'w-full flex justify-center items-center h-56 sm:h-[500px] px-3 pt-10'>
         <div className = {`h-40 w-full sm:w-1/2 pb-3 sm:pb- px-3 md:px-8 border border-gray-400 rounded-lg shadow-md shadow-gray-500 bg-white pt-3`}>
         
            <div className = 'w-full flex items-center justify-center'>
               <MdOutlineMood className = 'text-green-500 text-[55px]' />
            </div>
            
            <div className = 'w-full h-full flex justify-center font-Roboto'>
               <p className = 'text-center pt-5 text-gray-800 text-xl'>You have Subscribed successfully!</p>
            </div>
         </div>
      </div>
   )
}

export default MobViewSubscriptionSucess;
