
const AuthService = (function(){
  
  function setToken(item, remember) {
    let storage = sessionStorage
    if(remember) storage = localStorage
    storage.setItem('auction_ethiopia', JSON.stringify(item));
  }
  
  function getToken() {
    return JSON.parse(localStorage.getItem('auction_ethiopia') ?? sessionStorage.getItem('auction_ethiopia'))
  }
  
  function clearToken() {
    localStorage.removeItem('auction_ethiopia');
    sessionStorage.removeItem('auction_ethiopia');
  }
  
  function setLanguage(language) {
    return localStorage.setItem('language', language)
  }
  
  function getLanguage() {
    return localStorage.getItem('language')
  }

  function setSelectedCategory(cat) {
    return sessionStorage.setItem('selected_category', JSON.stringify(cat))
  }

  function getSelectedCategory() {
    let res = sessionStorage.getItem('selected_category')
    if(res !== 'undefined') return JSON.parse(res)
    return null
  }

  function clearSelectedCategory() {
    sessionStorage.removeItem('selected_category')
  }

  function dontShowSidePopup() {
    return localStorage.setItem('dont_show_side_popup', 'true')
  }

  function getDontShowSidePopup() {
    return localStorage.getItem('dont_show_side_popup')
  }
  
  function clearShowSidePopup() {
    localStorage.removeItem('dont_show_side_popup')
  }

  function dontShowTutoPrompt() {
    return localStorage.setItem('dont_show_tuto_prompt', 'true')
  }

  function getDontShowTutoPrompt() {
    // let res = localStorage.getItem('dont_show_tuto_prompt')
    // if(res !== 'undefined') return JSON.parse(res)
    return localStorage.getItem('dont_show_tuto_prompt')
  }

  function clearShowTutoPrompt() {
    localStorage.removeItem('dont_show_tuto_prompt')
  }

  function registerQuestionnaireSubmitted(val) {
    localStorage.setItem('q', JSON.stringify(val))
  }

  function checkQuestionnaireSubmitted() {
    let res = localStorage.getItem('q')
    if(res !== undefined){
      return JSON.parse(res)
    }
    return null
  }

  // function clearQuestionnaireSubmitted() {
  //   localStorage.removeItem('q')
  // }

  function saveGiveawayId(arr) {
    return localStorage.setItem('giveaway', JSON.stringify(arr))
  }

  function getGiveawayId() {
    let res = localStorage.getItem('giveaway')
    if(res !== (undefined || null)){
      return JSON.parse(res)
    }
    return []
  }
  
  return {
    setToken,
    getToken,
    clearToken,
    setLanguage,
    getLanguage,
    setSelectedCategory,
    getSelectedCategory,
    clearSelectedCategory,
    dontShowSidePopup,
    getDontShowSidePopup,
    clearShowSidePopup,
    dontShowTutoPrompt,
    getDontShowTutoPrompt,
    clearShowTutoPrompt,
    registerQuestionnaireSubmitted,
    checkQuestionnaireSubmitted,
    // clearQuestionnaireSubmitted,
    saveGiveawayId,
    getGiveawayId
  }
    
})();

export default AuthService;