import { Routes, Route } from 'react-router-dom';
import { routes, privateRoutes } from './routes/routes';
import Layout from './components/layout/Layout';
import PrivateRoute from './routes/PrivateRoute';


const App = () => {
  return (
    <Routes>
      <Route path = '/' element = { <Layout /> } >
       {
          routes.map((route, i) => (
            <Route key = { i } { ...route } />
          ))
        }
        <Route element = { <PrivateRoute /> } >
          {
            privateRoutes.map((route, i) => (
              <Route key = { i } { ...route } />
            ))
          }
        </Route>
      </Route>
    </Routes>
  )
}

export default App;
