import HomeGrid from './HomeGrid';


const Home = () => {

  return (
    <div className = 'w-full'>
      <HomeGrid />
    </div>
  );
};

export default Home;
