import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMyBids } from '../../features/bidSlice';
import { setNewBidder } from '../../features/auctionsSlice';
import { TfiHandPointRight } from 'react-icons/tfi';
import { isAuctionAboutToEnd, isInMyBids } from '../../utils';
import BidGrid from './BidGrid';
import BidList from './BidList';
import ViewTypeToggle from '../viewControls/ViewTypeToggle';
import translate from '../../Services/translate';
import Loading from '../common/Loading';


const Bid = () => {
  
   const dispatch = useDispatch();

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { viewType } = useSelector(({ viewControls }) => viewControls );
   const { bid_loading, bids, myBidsLight, is_subscription_started, subscriptionCheck_status } = useSelector(({ bid }) => bid);
   const { newBidder, nowUtcTime } = useSelector(({ auction }) => auction);

   const [showNotifierPopup, setShowNotifierPopup] = useState(false);
   const [bidStatuses, setBidStatuses] = useState([]);

   const canSeeTopBidder = () => {
      if(is_subscription_started){
         return subscriptionCheck_status
      }else{
        return true
      }
   }

   useEffect(() => {
      if (bids.length) {
         setBidStatuses(bids.map((bid) => ({
            bid,
            current_price: null,
            isTopBidder: false,
            isAuctionEndingSoon: false,
            processing: false,
            hitGavel: false,
            ended: false,
         })));
      }
   }, [bids]);

   useEffect(() => {
      if(bids.length){
         setBidStatuses((prevBidStatuses) =>
            prevBidStatuses.map((bidStatus) => {
               let idx = bids.findIndex(myBid => myBid.auction.id === bidStatus.bid.auction.id)
               const current_price = bids[idx].auction.current_price
               const { isTopBidder } = isInMyBids(bids[idx].auction, myBidsLight)
               const isAuctionEndingSoon = isAuctionAboutToEnd(bidStatus.bid.auction.end_time, nowUtcTime);
               const processing = new Date(bidStatus.bid.auction.end_time).getTime() - nowUtcTime < 0 && nowUtcTime < (new Date(bidStatus.bid.auction.end_time).getTime() + 2500)
               const hitGavel = (new Date(bidStatus.bid.auction.end_time).getTime() + 2500) - nowUtcTime < 0 && nowUtcTime - (new Date(bidStatus.bid.auction.end_time).getTime() + 2500) <= 5200
               const ended = new Date(bidStatus.bid.auction.end_time).getTime() + 2500 + 5200 < nowUtcTime

               return {
                  ...bidStatus,
                  current_price,
                  isTopBidder,
                  isAuctionEndingSoon,
                  processing,
                  hitGavel,
                  ended,
               };
            })
         );
      }
      // eslint-disable-next-line
	}, [bids, myBidsLight, nowUtcTime]);

   useEffect(() => {
      let timer;
      if(newBidder){
         setShowNotifierPopup(true)
         timer = setTimeout(() => {
         dispatch(setNewBidder(false))
         setShowNotifierPopup(false)
         }, 4000);
      }
      return () => {
         clearTimeout(timer)
      }
      // eslint-disable-next-line
   }, [newBidder])

   useEffect(() => {
      dispatch(loadMyBids())
      // eslint-disable-next-line
   }, [])

   const bidProps = {
      showNotifierPopup,
      bidStatuses,
      canSeeTopBidder
   }

   return (
      <>
         <div className = 'self-center text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(129) }</h2>
         </div>
         {
            bid_loading
            ?
            <div className = 'pt-12'>
               <Loading loading = { bid_loading } loadingStyle = 'text-sky-600 w-7 h-7'/>
            </div>
            :
            <>
               {
                  bids && bidStatuses
                  ?
                  <div className = 'px-3'>
                     <div className = 'flex justify-between items-center'>

                        <ViewTypeToggle />

                        <div className = 'text-gray-600 flex items-center'>
                           <TfiHandPointRight className = 'text-lg text-sky-600 mr-3' />
                           { translate(157) } 
                           <span className = 'text-sky-600 font-bold px-2'>{ bids.length }</span> 
                           { translate(158) }
                        </div>

                     </div>
                     {
                        viewType === 'grid'
                        ?
                        <BidGrid { ...bidProps } />
                        :
                        <BidList { ...bidProps } />
                     }
                  </div>
                  :
                  null
               }
            </>        
         }
      </>
   )
}

export default Bid;