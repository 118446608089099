import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleViewBy } from '../../features/viewControlsSlice';
import AuthService from '../../Services/AuthService';
import translate from '../../Services/translate';


const ViewByToggle = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { viewBy } = useSelector(({ viewControls }) => viewControls);
  const { language } = useSelector(({ language }) => language);
  const selectedCategory  = useSelector(({ category }) => category.selectedCategory) ?? AuthService.getSelectedCategory();
  const Auth = AuthService.getToken();

  const handleClick = () => {
    if(Auth?.token){
        dispatch(toggleViewBy('my'))
    }else{
      navigate('/login')
    }
  }

  return (
    <>
      {
        selectedCategory ?
        <div className = 'flex justify-end pb-1 '>
          <div className = 'items-center flex pt-3 pb-1'>
            {/* <p className = 'text-gray-700 pr-3 text-sm'>{ translate(308) } </p> */}
            <button
              onClick = { () => dispatch(toggleViewBy ('all')) }
              className = { `px-2 ${language === 'Amharic' ? 'text-xs py-[6px]' : 'text-sm py-1'} min-w-[140px] border rounded-l-xl bg-gray-200 truncate ${viewBy === 'all' ? 'bg-gray-500 text-white border-gray-500' : 'border-gray-400 hover:bg-white hover:border-sky-600 hover:text-sky-600'}` }
              title = { `${translate(309)} ${selectedCategory ? (language === 'Afaan-Oromoo' ? selectedCategory?.name_or : language === 'Amharic' ? selectedCategory?.name_am : selectedCategory?.name) : ''} ${translate(310)}` }
            >
              {language === 'Afaan-Oromoo' ? 
              selectedCategory?.name_or + ' ' + translate(304) 
              : language === 'Amharic' ? 
              translate(304) + ' ' + selectedCategory?.name_am 
              : 
              translate(304) + ' ' + selectedCategory?.name}
            </button>
            <button
              onClick = { () => handleClick() }
              className = { `px-2 ${language === 'Amharic' ? 'text-xs py-[6px]' : 'text-sm py-1'} border min-w-[140px] rounded-r-xl bg-gray-200 truncate ${viewBy === 'my' ? 'bg-gray-500 text-white border-gray-500' : 'border-gray-400 hover:bg-white hover:border-sky-600 hover:text-sky-600'}` }
              title = { `${translate(311)} ${selectedCategory ? (language === 'Afaan-Oromoo' ? selectedCategory?.name_or : language === 'Amharic' ? selectedCategory?.name_am : selectedCategory?.name) : ''} ${translate(312)}` }
            >
              {language === 'Afaan-Oromoo' ? 
              selectedCategory?.name_or + ' ' + translate(305) : 
              language === 'Amharic' ? 
              translate(305) + ' ' + selectedCategory?.name_am : 
              translate(305) + ' ' + selectedCategory?.name}
            </button>
          </div>
        </div>
        :
        null
      }
    </>
  );
};

export default ViewByToggle;