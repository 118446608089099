import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myModel from '../Services/api';

const initialState = {
   news_loading: false,
   tenders_loading: false,
   news: [],
   tenders: [],
   news_status: '',
   tenders_status: '',
   news_page_number: 1,
   tenders_page_number: 1,
   news_limit: 4,
   tenders_limit: 4,
   news_count: 0,
   tenders_count: 0
}

export const getNews = createAsyncThunk(
   'news/getNews',
   async (param, { rejectWithValue }) => {
      try {
         const response = myModel.find('news', '', param)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const getTenders = createAsyncThunk(
   'news/getTenders',
   async (param, { rejectWithValue }) => {
      try {
         const response = myModel.find('tenders', '', param)
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const newsSlice = createSlice({
   name: 'news',
   initialState,
   reducers: {
      setCurrentNewsPage: (state, { payload }) =>{
         state.news_page_number = payload.num
      },
      setCurrentTendersPage: (state, { payload }) =>{
         state.tenders_page_number = payload.num
      }
   },
   extraReducers: ({ addCase }) => {
      addCase(getNews.pending, (state) => {
         state.news_loading = true
         state.news_status = 'pending'
      })
      addCase(getNews.fulfilled, (state, { payload }) => {
         state.news = state.news_page_number > 1 ? [...state.news, ...payload.results] : payload.results
         state.news_count = payload.count
         state.news_status = 'success'
         state.news_loading = false
      })
      addCase(getNews.rejected, (state) => {
         state.news_status = 'rejected'
         state.news_loading = false
      })

      addCase(getTenders.pending, (state) => {
         state.tenders_loading = true
         state.tenders_status = 'pending'
      })
      addCase(getTenders.fulfilled, (state, { payload }) => {
         state.tenders = state.tenders_page_number > 1 ? [...state.tenders, ...payload.results] : payload.results
         state.tenders_count = payload.count
         state.tenders_status = 'success'
         state.tenders_loading = false
      })
      addCase(getTenders.rejected, (state) => {
         state.tenders_status = 'rejected'
         state.tenders_loading = false
      })
   }
});

export const { setCurrentNewsPage, setCurrentTendersPage } = newsSlice.actions;
export default newsSlice.reducer;