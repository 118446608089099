import { useMemo, Children, useLayoutEffect, useState, useRef, useEffect, useCallback } from 'react';
 
const ImageSlider = ({ children }) => {

   const containerRef = useRef();
   const intervalRef = useRef(null);
   const [current, setCurrent] = useState(1);
   const [translateX, setTranslateX] = useState(0);
   const [isHovering, setIsHovering] = useState(false);

    const pauseSlider = () => setIsHovering(true);
    const resumeSlider = () => setIsHovering(false);

   const actionHandler = useCallback((mode) => {
      if (isHovering) return;
      containerRef.current.style.transitionDuration = '1500ms';
      if (mode === "prev") {
         if (current <= 1) {
            setTranslateX(0);
            setCurrent(children.length);
         } else {
            setTranslateX(containerRef.current.clientWidth * (current - 1));
            setCurrent((prev) => --prev);
         }
      } else if (mode === "next") {
         if (current >= children.length) {
            setTranslateX(
               containerRef.current.clientWidth * (children.length + 1)
            );
            setCurrent(1);
         } else {
            setTranslateX(containerRef.current.clientWidth * (current + 1));
            setCurrent((prev) => ++prev);
         }
      }
   }, [current, children, isHovering]);

   // for infinite scroll smooth effect
   useEffect(() => {
      const transitionEnd = () => {
         if (current <= 1) {
            containerRef.current.style.transitionDuration = "0ms";
            setTranslateX(containerRef.current.clientWidth * current);
         }

         if (current >= children.length) {
            containerRef.current.style.transitionDuration = "0ms";
            setTranslateX(containerRef.current.clientWidth * children.length);
         }
      };

      document.addEventListener("transitionend", transitionEnd);

      return () => {
         document.removeEventListener("transitionend", transitionEnd);
      };
   }, [current, children]);

   //   for autoplay
   useEffect(() => {
      if (isHovering) return;
      if (intervalRef.current) {
         clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
         actionHandler("next");
      }, 5000);

      return () => {
         if (intervalRef.current) {
            clearInterval(intervalRef.current);
         }
      };
   }, [actionHandler, isHovering]);

   const slides = useMemo(() => {
      if (children.length > 1) {
         let items = Children.map(children, (child, index) => (
            <li key = {index} className = 'slider-slide relative'>
               {child}
               {/* <button
               className = {`absolute ${index === 0 ? 'top-8' : 'bottom-12'} right-[44%] rounded-lg border border-black bg-lime-600 hover:bg-lime-700 hover:scale-105 duration-200 text-white py-2 px-16`}
                  // onClick={() => console.log(`Button on slide ${index + 1} clicked`)}
                  onClick={() => navigate(links[index])}
               >
                  View More
               </button> */}
            </li>
         ));

         return [
            <li key = {children.length + 1} className = 'slider-slide'>
               {children[children.length - 1]}
            </li>,
            ...items,
            <li key = {children.length + 2} className = 'slider-slide'>
               {children[0]}
            </li>
         ];
      }

      return <li className = 'slider-slide'>{children[0]}</li>;
      // eslint-disable-next-line
   }, [children]);

   useLayoutEffect(() => {
      setTranslateX(containerRef.current.clientWidth * current);
      // eslint-disable-next-line
   }, []);

   return (
      <section className = 'slider-root' onMouseEnter = {pauseSlider} onMouseLeave = {resumeSlider}>
         <ul
            ref = {containerRef}
            className = 'slider-container'
            style = {{
               transform: `translate3d(${-translateX}px, 0, 0)`,
            }}
         >
            {slides}
         </ul>
      </section>
   );
};

export default ImageSlider;