
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone';
import isAuctionAboutToEnd from './isAuctionAboutToEnd';

dayjs.extend(utc);
dayjs.extend(timezone);
  
export const getRemainingTimeUntilMsTimestamp = (timestampMs, nowUtc) => {
    
    const timestampDayjs = dayjs(timestampMs)
    const nowDayjs = dayjs(nowUtc)

    if(timestampDayjs.isBefore(nowDayjs)) {
        return {
            seconds: '00',
            minutes: '00',
            hours: '00',
            days: '00',
            isAboutToEnd: false,
        }
    }
    
    return {
        seconds : getRemainingSeconds(nowDayjs, timestampDayjs),
        minutes : getRemainingMinutes(nowDayjs, timestampDayjs),
        hours : getRemainingHours(nowDayjs, timestampDayjs),
        days : getRemainingDays(nowDayjs, timestampDayjs),
        // isAboutToEnd: new Date(timestampMs).getTime() <= new Date().setUTCDate(new Date().getUTCDate() + 1) && new Date(timestampMs).getTime() > Date.now(),
        isAboutToEnd: isAuctionAboutToEnd(timestampDayjs, nowDayjs),
    }
    
}

const getRemainingSeconds = (nowDayjs, timestampDayjs) => padWithZeros(timestampDayjs.diff(nowDayjs, 'seconds') % 60, 2)
const getRemainingMinutes = (nowDayjs, timestampDayjs) => padWithZeros(timestampDayjs.diff(nowDayjs, 'minutes') % 60, 2)
const getRemainingHours = (nowDayjs, timestampDayjs) => padWithZeros(timestampDayjs.diff(nowDayjs, 'hours') % 24, 2)
const getRemainingDays = (nowDayjs, timestampDayjs) => timestampDayjs.diff(nowDayjs, 'days').toString()

const padWithZeros = (number, minLength) => {
    const numberString = number.toString();
    if(numberString.length >= minLength) return numberString;
    return '0'.repeat(minLength - numberString.length) +  numberString;
}

