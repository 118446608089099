import { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import translate from '../Services/translate';

const CustomField = ({ 
    error,
    placeholder,
    name,
    onBlur,
    onChange,
    type,
    label,
    rules,
    hasErrorCallback,
    defaultValue,
    options,
    valueKey,
    labelKey,
    horizontal = false,
    selectStyle = '',
    inputStyle = '',
    labelStyle = '',
    divStyle = '',
    minLength = undefined,
    maxLength = undefined,
    notMatch,
    autoFocus,
    autoComplete,
    value,
    id
}) => {

    // eslint-disable-next-line
    const { language } = useSelector(({ language }) => language);

    const [validationError, setValidationError] = useState(error ? true : false);
    const [validationErrorMessage, setValidationErrorMessage] = useState(error);
    const [internalValue, setInternalValue] = useState(defaultValue);
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        onChange(internalValue)
        if (rules && dirty) applyValidators()
        setDirty(true)
        // eslint-disable-next-line
    }, [internalValue])

    useEffect(() => {
        if (dirty && typeof (hasErrorCallback) === 'function') hasErrorCallback(validationError)
        // eslint-disable-next-line
    }, [validationError])

    const requiredValidator = (message) => {
        let msg = typeof (message) === 'string' && message ? message : 'required'
        msg = !internalValue ? msg : error
        if (msg) {
            setValidationErrorMessage(msg)
        }
        setValidationError(msg ? true : false)
    }

    const minValidator = (val) => {
        const isObject = typeof (val) === 'object'
        let message = isObject ? val.message : 'less than minimum value ' + (isObject ? val.value : val) + ' given'
        message = (isObject && internalValue.toString().length < val.value) || (!isObject && internalValue.toString().length < val) ? message : error
        if (message) {
            setValidationErrorMessage(message)
        }
        setValidationError(message ? true : false)
    }

    const maxValidator = (val) => {
        const isObject = typeof (val) === 'object'
        let message = isObject ? val.message : 'greater than maximum value ' + (isObject ? val.value : val) + ' given'
        message = (isObject && internalValue.toString().length > val.value) || (!isObject && internalValue.toString().length > val) ? message : error
        if (message) {
            setValidationErrorMessage(message)
        }
        setValidationError(message ? true : false)
    }

    const applyCustomValidator = (customValidator) => {
        const isFunction = typeof (customValidator) === 'function'
        // eslint-disable-next-line
        if (!isFunction) throw 'given custom validator is not a function'
        const customValidationError = customValidator(internalValue)
        setValidationError(customValidationError ? true : false)
    }

    const availableValidators = {
        'required': msg => requiredValidator(msg),
        'min': val => minValidator(val),
        'max': val => maxValidator(val),
        'custom': custom => applyCustomValidator(custom)
    }

    const applyValidators = () => {
        if (typeof (rules) !== 'object')
        // eslint-disable-next-line
            throw `rules must be object of {<rule-name>: value}`

        Object.keys(rules).forEach(rule => {
            const validator = Object.keys(availableValidators).includes(rule) ?
                availableValidators[rule]
                :
                // eslint-disable-next-line
                () => { throw 'validation key not found. available options are (required, min, max, custom)' }
            validator(rules[rule])

        })
        applyOnBlur()
    }

    const applyOnBlur = () => {
        if (typeof (onBlur) === 'function')
            onBlur({
                error: validationError,
                value: internalValue
            })
        else
        // eslint-disable-next-line
        throw 'onBlur is not a function'
    }

    const renderSelect = () => {
        return (
            <select
                id = { id }
                name = { name }
                onBlur = { () => rules ? applyValidators() : applyOnBlur() }
                defaultValue = { defaultValue }
                onChange = { e => setInternalValue(e.target.value) }
                className = { `w-full pb-1 pt-0 pl-4 ${selectStyle ? selectStyle : '' } ${validationError ? 'border-red-500' : 'border-gray-300 focus:border-gray-400 focus:outline-none'}` }
            >
                {
                    options.map(option => (
                        <option key = { option[valueKey] } value = { option[valueKey] } >{ option[labelKey] }</option>
                    ))
                }
            </select>
        )
    }

    const renderTextArea = () => {
        return (
            <textarea 
                id = { id }
                className = { `border w-full p-2 rounded text-xs h-20 -mt-1 ${validationError ? 'border-red-500' : 'border-gray-300 focus:border-gray-400 focus:outline-none'}` }
                name = { name } 
                placeholder = { placeholder }
                onBlur = { () => rules ? applyValidators() : applyOnBlur() }
                rowSpan = { 20 } 
                defaultValue = { defaultValue }
                onChange = { e => setInternalValue(e.target.value) } 
            />
        )
    }

    const renderOthers = () => {
        let otherAttrs = {}
        if (type === 'number') otherAttrs['step'] = 'any'
        return (
            <Fragment>
                <input 
                    id = { id }
                    className={ `w-full p-2 -mt-1 focus:outline-none ${validationError ? 'border-red-500' : 'border-gray-300 focus:border-gray-400 '} ${inputStyle}` }
                    type = { type }
                    name = { name }
                    onChange = { e => setInternalValue(e.target.value) }
                    defaultValue = { defaultValue }
                    value = { value }
                    onBlur = { () => rules ? applyValidators() : applyOnBlur() }
                    // autoComplete = 'off'
                    autoComplete = { autoComplete ? autoComplete : 'off' }
                    placeholder = { placeholder }
                    maxLength = { maxLength ? maxLength : '' }
                    minLength = { minLength ? minLength : '' }
                    autoFocus = { autoFocus }
                    { ...otherAttrs } 
                />
            </Fragment>
        )

    }

    const renderInput = () => {
        switch (type) {
            case 'select':
                return renderSelect()
            case 'textarea':
                return renderTextArea()
            default:
                return renderOthers();
        }
    }

    return (
        <div className = { `w-full ${divStyle} ${horizontal ? 'flex md:flex-row px-1 pt-1' : ''}` } >
            {/* {
                label 
                ?
                <label className = { `${labelStyle} ${horizontal ? 'w-2/5 md:flex-1 pt-0 text-right' : ''} text-gray-600 text-left` } htmlFor = { id } >{ label }</label>
                : 
                null
            } */}
            <label className = { `${labelStyle} ${horizontal ? 'w-2/5 md:flex-1 pt-0 text-right' : ''} text-gray-600 text-left` } htmlFor = { id } >{ label }</label>
            <div className = { `pt-1 w-full ${horizontal ? 'w-3/5 md:flex-1 pt-0 pl-4' : 'pt-2'}` }>

                { renderInput() }

                { !validationError && !notMatch ? <div className = 'h-6'></div> : null }
                <div className = { `w-full flex items-start justify-end` }>
                    <small className = { `text-[0.7rem] ${!validationError && !notMatch ? 'hidden' : ''} text-red-500 pb-2` } >
                        { notMatch ? translate(115) : validationErrorMessage }
                    </small>
                </div>

            </div>
        </div>
    )
}

export default CustomField;