
const isAuctionAboutToEnd = (endTime, nowUtc) => {
    const endTimeUtc = new Date(endTime).getTime();

    return endTimeUtc <= nowUtc + 24 * 60 * 60 * 1000 && endTimeUtc >= nowUtc; // 24 hours
    // return endTimeUtc <= nowUtc + 60 * 60 * 1000 && endTimeUtc > nowUtc; // 1 hour

    // return new Date(endTime).getUTCTime() <= new Date().getUTCTime() + 24 * 28 * 60 * 60 * 1000 && new Date(endTime).getUTCTime() > new Date().getUTCTime(); // 24 hours

    // return new Date(endTime).getTime() <= new Date().setDate(new Date().getDate() + 1) && new Date(endTime).getTime() > Date.now()   // less than or equal to 1 day

    // return new Date(endTime).getTime() <= new Date().setHours(new Date().getHours() + 1) && new Date(endTime).getTime() > Date.now()   // less than or equal to 1 hour
}

export default isAuctionAboutToEnd;