import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkSubscriptionStarted, loadBanks, loadBidsLight, loadMyBids, requestRefund, resetCheckoutUrl, submitBid, submitBidSecurity, submitBidSecurityOnline, toggleBidSecurityModal, toggleBidSubmitModal, toggleBidSuccessModal, toggleUploadReceiptModal, updateBidEndTime, updateBidPrice, updateBidStatus, uploadBankReceipt } from '../../features/bidSlice';
import { updateWatchlistEndTime, updateWatchlistPrice } from '../../features/watchListSlice';
import { getGiveAway, getHowToUseVideos, getServerTime, setLive, setNewBidder, setShowGuidePrompt, updateAuctionByIdEndTime, updateAuctionByIdPrice, updateAuctionEndTime, updateAuctionPrice } from '../../features/auctionsSlice';
import { getDocuments, toggleUploadDocumentModal, uploadDocuments } from '../../features/documentsSlice';
import { changePassword, getProfile, getQuestionnaire, toggleChangePasswordModal, toggleQuestionnairePopup } from '../../features/authSlice';
import { getNewNotifications, getNotifications, markNotificationAsSeen, resetMarkAsSeenStatus, sendMessages, toggle_notification_modal } from '../../features/chatSlice';
import toast, { Toaster } from 'react-hot-toast';
import AuthService from '../../Services/AuthService';
import CategoryTabs from '../common/CategoryTabs';
import Footer from '../common/Footer';
import Popups from '../common/Popups';
import Header from '../common/Header';
import { opt } from '../../utils';
import { WsUrl } from '../../Services/apiConstants';
import ThumbnailGrid from '../common/ThumbnailGrid';
import translate from '../../Services/translate';
import { Gift, winner_cup } from '../../assets';
import { FaGift } from "react-icons/fa";


const Layout = () => {

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   
   const Auth = AuthService.getToken();
   const dont_show_tuto_Prompt = AuthService.getDontShowTutoPrompt();
   const checkQuestionnaire = AuthService.checkQuestionnaireSubmitted();

   const { show_bid_success_modal, bid_status, bidding_status, bidding_loading, bid_security_status, bid_security_loading, myBidsLight, bid_security_online_checkout_url } = useSelector(({ bid }) => bid);
   const { password_status, myProfile, user, questionnaire } = useSelector(({ auth }) => auth);
   const { auctionById, nowUtcTime, showVideoModal, showGuidePrompt, promptClosed, giveaway } = useSelector(({ auction }) => auction);
   const { markAsSeen_status, notifications } = useSelector(({ chat }) => chat);
   const { language } = useSelector(({ language }) => language);

   const initBidSubmitData = { amount: '' }
   const initBidSecurityData = {
      type: '',
      bank: myProfile?.bank_info?.bank ? myProfile.bank_info.bank : 0,
      amount: '',
      cpo_number: ''
   }
   const initUploadReceiptData = {
      bank: myProfile?.bank_info?.bank ? myProfile.bank_info.bank : '',
      amount: ''
   }
   const initComparePassword = {
      password_1: '',
      password_2: ''
   }    
   const [showDepositCard, setShowDepositCard] = useState(true);
   const [agree, setAgree] = useState(true);
   const [bidSubmitData, setBidSubmitData] = useState(initBidSubmitData);
   const [bidSecurityData, setBidSecurityData] = useState(initBidSecurityData);
   const [showPreview, setShowPreview] = useState('');
   const [receipt, setReceipt] = useState(initUploadReceiptData);
   const [tempTitle, setTempTitle] = useState('');
   const [selectedFile, setSelectedFile] = useState(null);
   const [openChat, setOpenChat] = useState(false);
   const [msg, setMsg] = useState({ message: '' });
   const [changePW, setChangePW] = useState({current_password: ''});
   const [comparePassword, setComparePassword] = useState(initComparePassword);
   const [showPassword, setShowPassword] = useState(false);
   const [tempNotification, setTempNotification] = useState(null);
   const [showGenericNotification, toggleGenericNotification] = useState(false);
   const [showError, setShowError] = useState(false);
   const [showButton, setShowButton] = useState(false);
   const [socket, setSocket] = useState(null);
   const [tempAmount, setTempAmount] = useState(0);
   const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
   const [bidSubmitError, setBidSubmitError] = useState({ txt1: '', val: '', txt2: '' });
   const [showSidePopup, setShowSidePopup] = useState(false);
   const [selectedOnlinePayment, setSelectedOnlinePayment] = useState(null);
   const [requestRefundDtata, setRequestRefundData] = useState({ amount: ''});
   const [refundError, setRefundError] = useState('');


   const showBidModals = (value, type) => {
      switch (type) {
         case 'submit':
            dispatch(toggleBidSubmitModal({ value }))
            break;
         case 'success':
            if(value === false){
               dispatch(updateBidStatus({ value: '' }))
            }
            dispatch(toggleBidSuccessModal({ value }))
            setBidSubmitData(initBidSubmitData)
            break;
         case 'bid_security':
            if(bid_security_status === 'success'){
               setSelectedFile(null)
               setShowPreview('')
               setShowError(false)
               dispatch(toggleBidSecurityModal({ value }))
               setBidSecurityData(initBidSecurityData)
               setTempAmount(0)
            }else{
               toast.error(translate(233))
            }
            break;
         case 'upload_receipt':
            dispatch(toggleUploadReceiptModal({ value }))
            setReceipt({amount: ''})
            setSelectedFile('')
            setShowPreview('')
            setAgree(false)
            setTempAmount(0)
            break;
         case 'upload_document':
            dispatch(toggleUploadDocumentModal({ value }))
            setTempTitle('')
            setShowPreview('')
            setSelectedFile('')
            setAgree(false)
            break;
         default:
            break;
      }
   }

   const submit_Bid = () => {
      if(bidSubmitData.amount){       
         if(new Date(auctionById?.detail?.end_time).getTime() > nowUtcTime){
               if(!auctionById?.detail?.is_closed){
                  if(auctionById?.detail?.min_increment){
                     if(bidSubmitData.amount < auctionById?.detail?.current_price + auctionById?.detail?.min_increment){
                        return toast.error(`${translate(159)} ${(auctionById?.detail?.current_price + auctionById?.detail?.min_increment).toLocaleString('en', opt)} ${translate(160)}`, { duration: 5000 })
                     }
                  }
                  if(auctionById?.detail?.max_increment){
                     if(bidSubmitData.amount > auctionById?.detail?.current_price + auctionById?.detail?.max_increment){
                        return toast.error(`${translate(161)} ${(auctionById?.detail?.current_price + auctionById?.detail?.max_increment).toLocaleString('en', opt)} ${translate(162)}`, { duration: 5000 })
                     }
                  }
                  dispatch(submitBid({ ...bidSubmitData, id: auctionById?.detail.id }))
                  setBidSubmitError({txt1: '', val: '', txt2: ''})
               }else{
                  dispatch(submitBid({ ...bidSubmitData, id: auctionById?.detail.id }))
                  setBidSubmitError({txt1: '', val: '', txt2: ''})
               }
         }else{
            toast.error(translate(234))
         }
      }else{
         toast.error(translate(133))
      }
   }

   const attachFile = () => {
      var input = document.createElement('input');
      input.type = 'file';
      // input.accept = 'image/*'
      input.accept = '.jpg, .jpeg, .png'
   
      input.onchange = e => {
         if(e.target.files && e.target.files[0].size > 2 * 1000 * 1024){
            let fs = e.target.files[0].size
            let fileSize = (fs / 1024 / 1024).toFixed(2) + '𝐌𝐁 '
            toast.error(`${translate(236)} 2𝐌𝐁 ${translate(237)},\n ${translate(238)} ${fileSize} ${translate(239)}`, { duration: 5000 })
            return
         }
         if(e.target.files && e.target.files[0]){
            let reader = new FileReader()
            reader.onload = e => {
               setShowPreview(e.target.result)
            }
            reader.readAsDataURL(e.target.files[0])
         }
         setSelectedFile(e.target.files[0]) 
      }
      input.click();
   }

   const submit_bid_security = () => {
      dispatch(submitBidSecurity({
         id: auctionById.detail.id,
         image: selectedFile,
         ...bidSecurityData
      }))
   }
   


   const submit_bidSecurity_online = () => {
      console.log('selectedOnlinePayment',selectedOnlinePayment)

      if(selectedOnlinePayment){
         dispatch(submitBidSecurityOnline({ auction_id: auctionById?.detail?.id, payment_platform_id: selectedOnlinePayment?.id }))
      }else{
         toast.error('Please select payment mathod', { duration: 3000, id: 'copy'})
      }
   }

   const uploadReceipt = () => {
      dispatch(uploadBankReceipt({
         amount: receipt.amount, 
         image: selectedFile,
         bank: receipt.bank
      })).then(() => {
         if(bid_status === 'success'){
            showBidModals(false, 'upload_receipt')
            toast.success(translate(240))
         }
      })
   }

   const uploadDocument = () => {
      dispatch(uploadDocuments({
         [tempTitle]: selectedFile,
      })).then(() => {
         dispatch(toggleUploadDocumentModal({ value: false }))
         dispatch(getDocuments())
         setShowPreview(''); setSelectedFile(''); setAgree(false); setTempTitle('')
         toast.success(translate(241), { duration: 3000, id: 'copy'})
      })
   }

   const sendMsg = e => {
      e.preventDefault()
      dispatch(sendMessages(msg)).then(() => {
         setMsg({message: ''})
         toast.success(translate(242), { duration: 3000 })
      })
   }
   
   const handleChangePassword = () => {
      dispatch(changePassword(changePW))
   }
   
   const checkPasswordMatch = () => {
      if(comparePassword.password_2 && comparePassword.password_2 !== comparePassword.password_1)
         return true
      return false
   }

   const redirectTo = (type, id = null, notification) => {
      switch (type) {
         case 'update_bid': case 'auction_won': case 'auction_cancelled':
            id && navigate(`/auction/${id}`)
            break;
         case 'change_password': case 'generic':
            setTempNotification(notification)
            toggleGenericNotification(true)
            break;
         case 'message':
            navigate(`/chatting`)
            break;
         default:
            break;
      }
      dispatch(markNotificationAsSeen({ notification_id: notification.id }))
      dispatch(toggle_notification_modal({value: false}))
   }

   const isValid = (state) => {
      let err = 0
      Object.keys(state).forEach((key, i) => {
         if(!state[key]) err += 1
      })
      if(!selectedFile) err += 1
      return err ? false : true
   }

   const playNotificationSound = (sound) => {
      const audio = new Audio(sound);
   
      const playAudio = () => {
         audio.play().then(() => {}).catch(error => {});
      };
   
      audio.addEventListener('error', event => {});
   
      const button = document.createElement('button');
      button.style.display = 'none';
      document.body.appendChild(button);
      button.addEventListener('click', playAudio, { once: true });
      button.click();  
      button.parentNode.removeChild(button);
   };

   const connectWebSocket = () => {

      let ws = new WebSocket(WsUrl)
      let connectInterval;
   
      ws.onopen = () => {
         // toast.success('WS Connected !', {duration: 2000, id: 'copy'})
         dispatch(setLive(true))
         setSocket(ws);
         clearTimeout(connectInterval);
      };
   
      ws.onmessage = (evt) => {
         dispatch(loadBidsLight())
         handleWsMessage(JSON.parse(evt.data))
      }
      
      ws.onclose = e => {
         dispatch(setLive(false))
         connectInterval = setTimeout(checkWS, 4000);
         console.log(`WebSocket is closed. Reconnect will be attempted in 4 seconds.`);
      };
   
      ws.onerror = err => {
         console.error("WebSocket encountered error: ", "Closing socket");
         ws.close();
      };
   }

   const checkWS = () => {
      if (!socket || socket.readyState === WebSocket.CLOSED) {
         connectWebSocket();
      }
   };

   const handleWsMessage = (WSMessage) => {        
      if(WSMessage.type === 'price_update'){
         const params = { 
            auction_id: WSMessage.auction_id, 
            price: WSMessage.price, 
            userId: WSMessage.user_id, 
            _userId: Auth?.userId, 
            firstName: WSMessage.fullname.split(' ')[0], 
            lastName: WSMessage.fullname.split(' ')[1],
            ws_no_of_bids: WSMessage.no_of_bids
         }
         playNotificationSound('/cash-register-sound.mp3');
         dispatch(updateAuctionPrice({ ...params }))
         dispatch(updateBidPrice({ ...params }))
         dispatch(updateWatchlistPrice({ ...params  }))
         dispatch(updateAuctionByIdPrice({ ...params }))
         setTimeout(() => {
            dispatch(setNewBidder(true))
         }, 1000);
      }
      if(WSMessage.type === 'extend_auction_end_time'){
         const params = {
            id: WSMessage.auction_id,
            endTime: WSMessage.end_time
         }
         dispatch(updateAuctionEndTime({ ...params }))
         dispatch(updateBidEndTime({ ...params }))
         dispatch(updateWatchlistEndTime({ ...params  }))
         dispatch(updateAuctionByIdEndTime({ ...params }))
      }
   }

   const removeLayout = () => {
      const paths = ['/termsAndConditions', '/mobile-aboutUs', '/mobile-faq', '/monitor/:id', '/privacyPolicy', '/subscription-success']
      const regex = /\/monitor\/\d+/
      return paths.includes(location.pathname) || regex.test(location.pathname)
   }

   const removeCategoryTabs = () => {
      const paths = ['/termsAndConditions', '/mobile-aboutUs', '/mobile-faq', '/home', '/not-found', '/monitor/:id', '/privacyPolicy', '/subscription-success']
      const regex = /\/monitor\/\d+/
      return paths.includes(location.pathname) || regex.test(location.pathname)
   }

   const goToAddNewDeposit = () => {
      dispatch(loadBanks()).then(() => {
         dispatch(toggleUploadReceiptModal({ value: true }))
      })
   }

   const submitRefund = () => {
      dispatch(requestRefund({ amount: requestRefundDtata.amount }))
   }

   const popupsProps = {
      bidSubmitData,
      setBidSubmitData,
      initBidSubmitData,
      bidSecurityData,
      setBidSecurityData,
      initBidSecurityData,
      agree,
      setAgree,
      submit_Bid,
      showBidModals,
      showError,
      setShowError,
      showPreview,
      setShowPreview,
      attachFile,
      selectedFile,
      isValid,
      submit_bid_security,
      receipt,
      setReceipt,
      initUploadReceiptData,
      setSelectedFile,
      uploadReceipt,
      tempTitle,
      setTempTitle,
      uploadDocument,
      showPassword,
      setShowPassword,
      changePW,
      setChangePW,
      comparePassword,
      setComparePassword,
      checkPasswordMatch,
      handleChangePassword,
      redirectTo,
      showGenericNotification,
      toggleGenericNotification,
      tempNotification,
      setTempNotification,
      openChat,
      setOpenChat,
      msg,
      setMsg,
      sendMsg,
      showDepositCard,
      setShowDepositCard,
      showButton,
      setShowButton,
      tempAmount,
      setTempAmount,
      showConfirmationPopup,
      setShowConfirmationPopup,
      bidSubmitError,
      setBidSubmitError,
      showSidePopup,
      setShowSidePopup,
      goToAddNewDeposit,
      initComparePassword,
      selectedOnlinePayment,
      setSelectedOnlinePayment,
      submit_bidSecurity_online,
      requestRefundDtata,
      setRequestRefundData,
      submitRefund,
      refundError,
      setRefundError
   }

   useEffect(() => {
      dispatch(getServerTime())
      connectWebSocket()
      dispatch(getHowToUseVideos())
      let timer1
      if(!dont_show_tuto_Prompt){
         timer1 = setTimeout(() => {
            dispatch(setShowGuidePrompt({ value: true }))
         }, 5000);
      }
      // if((!dont_show_tuto_Prompt && !showGuidePrompt && !showVideoModal && promptClosed) || dont_show_tuto_Prompt){
      //    timer2 = setTimeout(() => {
      //       dispatch(toggleQuestionnairePopup(true))
      //    }, 10000);
      // }

      return () => {
         clearTimeout(timer1)
      }
      // eslint-disable-next-line
   }, [])

   useEffect(() => {
      if(Auth){
         console.log('Me run in layout-------------------------')
         if(!checkQuestionnaire){
            dispatch(getQuestionnaire())
         }
         // dispatch(loadBidsLight())
         dispatch(getProfile())
         dispatch(checkSubscriptionStarted())           // Here we check subscription started first.....
         dispatch(getNotifications())
         dispatch(getNewNotifications())
         dispatch(loadMyBids())
         if(!myBidsLight.length){
            dispatch(loadBidsLight())
         }
      }
      // eslint-disable-next-line
   }, [user])

   useEffect(() => {
      let timer2;
      if(((!dont_show_tuto_Prompt && !showGuidePrompt && !showVideoModal && promptClosed) || dont_show_tuto_Prompt) && questionnaire.length){
         timer2 = setTimeout(() => {
            dispatch(toggleQuestionnairePopup(true))
         }, 10000);
      }
      return () => {
         clearTimeout(timer2)
      }
      
      // eslint-disable-next-line
   }, [promptClosed])

   useEffect(() => {
      if(bidding_status === 'success' && !bidding_loading ){
         dispatch(toggleBidSubmitModal({ value: false }))
         setTempAmount(0)
         setTimeout(() => {
               dispatch(toggleBidSuccessModal({ value: true }))
         }, 500);
      }
      // eslint-disable-next-line
   }, [bidding_status, bidding_loading])

   useEffect(() => {
      if(bid_security_status === 'success' && !bid_security_loading){
         showBidModals(false, 'bid_security')
      }
      // eslint-disable-next-line
   }, [bid_security_status, bid_security_loading])
   
   useEffect(() => {
      if(password_status === 'Password changed successfully'){
         dispatch(toggleChangePasswordModal({ value: false }))
         toast.success(translate(219), { duration: 2000 })
         navigate('/logout')
      }
      // eslint-disable-next-line
   }, [password_status])

   useEffect(() => {
      if(comparePassword.password_1 === comparePassword.password_2){
         setChangePW({...changePW, password1: comparePassword.password_1, password2: comparePassword.password_2})
         return
      }
      setChangePW({...changePW, password1: '', password2: ''})
      // eslint-disable-next-line
   }, [comparePassword.password_1, comparePassword.password_2])

   useEffect(() => {
      if(show_bid_success_modal) dispatch(loadMyBids())
      // eslint-disable-next-line
   }, [show_bid_success_modal])

   useEffect(() => {
      if(Auth){
         if(markAsSeen_status === 'success'){
            dispatch(getNotifications())
            dispatch(getNewNotifications())
            dispatch(resetMarkAsSeenStatus())
         }
      }
      // eslint-disable-next-line
   }, [markAsSeen_status])

   useEffect(() => {
      if(location.pathname === '/my-bids'){
         setShowSidePopup(false)
         return
      }
      let timer = setTimeout(() => {
         setShowSidePopup(true)
      }, 8000);
      return () => clearTimeout(timer)
   }, [location.pathname])

   useEffect(() => {
      if(Auth){
         let nInterval = setInterval(() => {
            dispatch(getNotifications())
            dispatch(getNewNotifications())
            dispatch(getGiveAway())
         // }, 300000);
         }, 60000);
         return () => clearInterval(nInterval)
      }
      // eslint-disable-next-line
   }, [notifications])

   useEffect(() => {
      if(giveaway){
         playNotificationSound('/short-success-sound.mp3');
         toast.custom((t) => (
            <div
            className={`${
               t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-md w-full bg-white shadow-lg shadow-slate-500 rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 overflow-hidden border border-slate-300`}
            >
            <div className="flex-1 w-0 p-4 bg-lime-400 bg-gradient-to-br from-indigo-700 to-lime-700">
               <div className="flex items-center">
                  <div className = 'h-12 w-12 bg-gray-200 rounded-full flex items-center justify-center top-bider-cup'>
                     {/* <FaGift className = 'text-orange-700 text-4xl'/> */}
                     <img
                        className="h-10 w-10 rounded-full"
                        src= {Gift}
                        alt=""
                     />
                  </div>

                  <div className="ml-3 sm:ml-5 flex-1 justify-center">
                  <p className="text- font-medium text-gray-900 rounded-xl bg-white text-center">
                     {/* Congratulation to <b>Abe Kebe</b>! */}
                     <b>{giveaway.name}</b>
                  </p>
                  <p className="mt-1 text-sm text-center text-white">
                     {/* In round 1 of the Easter Auction games, Abe Kebe emerged victorious! */}
                     {giveaway.message}
                  </p>
                  </div>
                  

               </div>
            </div>
            <div className="flex border-l border-gray-200">
               <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-sky-800 focus:outline-none"
               >
                  Close
               </button>
            </div>
            </div>
         ), {duration: 30000, id: 'cpoy'})
      }
      // eslint-disable-next-line
   }, [giveaway])

   useEffect(() => {
      if(bid_security_online_checkout_url) {
         window.open(bid_security_online_checkout_url, '_blank')
         navigate('/home')
      }
      dispatch(resetCheckoutUrl())
      // eslint-disable-next-line
   }, [bid_security_online_checkout_url])

   useEffect(() => {
      if(myProfile?.bank_info?.bank){
         setBidSecurityData({ ...bidSecurityData, bank: myProfile?.bank_info?.bank })
         setReceipt({ ...receipt, bank: myProfile?.bank_info?.bank })
      }
      // eslint-disable-next-line
   }, [myProfile])
   


   return (
      <div className = {`w-full h-full ${language === 'Amharic' ? 'font-Abyssinica' : ''}`}>
         <Toaster 
               position = 'top-left'
               toastOptions = {{
                  success: {
                     className: 'bg-stone-50 w-full md:w-[800px] shadow-md shadow-stone-300',
                     style: {
                        border: '1px solid #713200',
                        padding: '16px',
                     },
                  },
                  error: {
                     className: 'bg-red-100 w-full md:w-96 shadow-md shadow-stone-300',
                     style: {
                        border: '1px solid #713200',
                        padding: '16px',
                     },
                  },                    
               }}
         />
         
         {!removeLayout() ? <Header playNotificationSound = { playNotificationSound } /> : null }

         <div className = 'hidden lg:block'>
            {!removeCategoryTabs() ? <CategoryTabs /> : null}
         </div>
         
            {/* <div class="card-wrapper h-10 w-28 text-white">
               <div class="card-content flex items-center justify-center text-xs">
                  <div class=" text-center">Check out on YouTube</div>
               </div>
            </div> */}


         {/* <div className = 'min-h-[60vh] bg-sky-100 layout-scroll relative w-full pb-1'> */}
         <div className = 'min-h-screen bg-sky-100 layout-scroll relative w-full pb-1'>
            <Outlet />
         </div>

         {!removeLayout() ? <Footer /> : null }

         {!removeLayout() ? <Popups { ...popupsProps } /> : null }

         {showVideoModal ? <ThumbnailGrid /> : null }
      </div>
   )
}

export default Layout;