import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Modal from "./Modal";
import Loading from "./Loading";
import { getPaymentPlatforms, setDontShowSidePopup, toggleBidSecurityModal, toggleOneTimeModal, toggleRequestRefundModal, toggleUploadReceiptModal } from "../../features/bidSlice";
import { toggleUploadDocumentModal } from "../../features/documentsSlice";
import { setShowGuidePrompt, setShowVideoModal } from '../../features/auctionsSlice';
import CustomField from "../../utils/CustomField";
import { BsEye, BsEyeSlash, BsYoutube } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
import { postQuestionnaire, resetSubmitted, toggleChangePasswordModal, toggleQuestionnairePopup } from "../../features/authSlice";
import { toggle_notification_modal } from "../../features/chatSlice";
import { FiX } from "react-icons/fi";
import { MdCircle } from "react-icons/md";
import dayjs from 'dayjs';
import { logo_blur, cartoon_man_pointing } from "../../assets";
import AuthService from "../../Services/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { IoCameraOutline } from "react-icons/io5";
import opt from "../../utils/opt";
import { toWords } from '../../utils';
import translate from '../../Services/translate';


const Popups = ({ bidSubmitData, setBidSubmitData, initBidSubmitData, bidSecurityData, setBidSecurityData, initBidSecurityData, agree, setAgree, submit_Bid, showBidModals, showError, setShowError, showPreview, setShowPreview, attachFile, selectedFile, isValid, submit_bid_security, receipt, setReceipt, initUploadReceiptData, setSelectedFile, uploadReceipt, tempTitle, setTempTitle, uploadDocument, showPassword, setShowPassword, changePW, setChangePW, comparePassword, setComparePassword, checkPasswordMatch,handleChangePassword, redirectTo, showGenericNotification, toggleGenericNotification, tempNotification, setTempNotification, showDepositCard, setShowDepositCard, showButton, setShowButton, tempAmount, setTempAmount, showConfirmationPopup,setShowConfirmationPopup, bidSubmitError, setBidSubmitError, showSidePopup, setShowSidePopup, goToAddNewDeposit, initComparePassword, selectedOnlinePayment, setSelectedOnlinePayment, submit_bidSecurity_online, requestRefundDtata, setRequestRefundData, submitRefund, refundError, setRefundError }) => {

   const navigate = useNavigate();
   const dispatch = useDispatch();
   // const location = useLocation();
   const Auth = AuthService.getToken();
   const dont_show_Side_Popup = AuthService.getDontShowSidePopup();
   const dont_show_tuto_Prompt = AuthService.getDontShowTutoPrompt();
   
   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { show_bid_submit_modal,show_one_time_subscription_modal, show_bid_success_modal, bidding_loading, bid_security_loading, show_bid_security_modal, banks, show_upload_receipt_modal, upload_loading, bids, payment_platforms, show_request_refund_modal, balance, refund_loading } = useSelector(({ bid }) => bid);
   const { show_upload_document_modal, document_loading2 } = useSelector(({ document }) => document);
   const { show_chang_password_modal, password_loading, myProfile, questionnaire, show_questionnaire_popup, questionnaire_loading, submitted } = useSelector(({ auth }) => auth);
   const { auctionById, nowUtcTime, showGuidePrompt, dont_show_again_guide_prompt } = useSelector(({ auction }) => auction);
   const { notifications, show_notification_modal } = useSelector(({ chat }) => chat);

   const [currentBidAmount, setCurrentBidAmount] = useState(null);
   const [dontShowPopup, setDontShowPopup] = useState(false);
   const [selectedTab, setSelectedTab] = useState('bank-receipt');
   const [openBankInfo, setOpenBankInfo] = useState(false);
   const [dontShowGuidePromptPopup, setDontShowGuidePromptPopup] = useState(false);
   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
   const [formData, setFormData] = useState([]);
   const [transitioning, setTransitioning] = useState(false);

   const { is_closed, min_increment, max_increment, current_price, available_balance, min_amount_to_bid, end_time, can_bid, is_land_bid, land_area } = auctionById?.detail || {}

   // const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

   const addCommas = num => {
      if(num - Math.floor(num) === 0){
         return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }else{
         const [integerPart, decimalPart] = num.toString().split('.');
         const integerPartWithSeparator = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
         return `${integerPartWithSeparator}.${decimalPart || ''}`;
      }
   }

   const format = (num) => {
      setTempAmount(addCommas(num)) 
   }

   // const handleChange = (evt, state, prop) => {
   //    const re = /^[0-9.]+$/
   //    let val = evt.target.value
   //    if (val === '' || re.test((val).replace(/,/g, ''))) {
   //       // eslint-disable-next-line
   //       format(val.replace(/\,/g, ''))
   //       if(state === 'bid_submit'){

   //          if(prop === 'amount'){
   //             setBidSubmitData({ ...bidSubmitData, amount: val.replace(/,/g, '') })
   //             // eslint-disable-next-line
   //             !is_closed && checkBidAmount(val.replace(/\,/g, ''))
   //             // eslint-disable-next-line
   //             setCurrentBidAmount(val.replace(/\,/g, ''))
   //          }
   //          if(prop === 'per_meter_square'){
   //             setBidSubmitData({ ...bidSubmitData, per_meter_square: val.replace(/,/g, ''), amount: val.replace(/,/g, '') * land_area })
   //             // eslint-disable-next-line
   //             setCurrentBidAmount(val.replace(/\,/g, '') * land_area)
   //          }
   //          if(prop === 'percentage'){
   //             setBidSubmitData({ ...bidSubmitData, percentage: val.replace(/,/g, '') })
   //          }
   //          if(prop === 'payment_years'){
   //             setBidSubmitData({ ...bidSubmitData, payment_years: val.replace(/,/g, '') })
   //          }
   //       }
   //       if(state === 'bid_security'){
   //          setBidSecurityData({ ...bidSecurityData, amount: val.replace(/,/g, '') })
   //       }
   //       if(state === 'bid_receipt'){
   //          setReceipt({ ...receipt, amount: val.replace(/,/g, '') })
   //       }
   //       if(state === 'refund'){
   //          setRequestRefundData({ ...requestRefundDtata, amount: val.replace(/,/g, '') })
   //          // eslint-disable-next-line
   //          checkRefundAmount(val.replace(/\,/g, ''))
   //       }
   //    }
   // }

   const handleChange = (evt, state, prop) => {
      const val = evt.target.value.replace(/,/g, '');
      const re = /^[0-9.]+$/
      if (val === '' || re.test(val)) {
         format(val)
         switch (state) {
            case 'bid_submit':
               switch (prop) {
                  case 'amount':
                     setBidSubmitData({ ...bidSubmitData, [prop]: val })
                     !is_closed && checkBidAmount(val)
                     setCurrentBidAmount(val)
                     break;
                  case 'per_meter_square':
                     setBidSubmitData({ ...bidSubmitData, [prop]: val, amount: val * land_area })
                     setCurrentBidAmount(val * land_area)
                     break;
                  case 'percentage': case 'payment_years':
                     setBidSubmitData({ ...bidSubmitData, [prop]: val})
                     break;
                  default:
                     break;
               }
               break;
            case 'bid_security':
               setBidSecurityData({ ...bidSecurityData, amount: val })
               break;
            case 'bid_receipt':
               setReceipt({ ...receipt, amount: val })
               break;
            case 'refund':
               setRequestRefundData({ ...requestRefundDtata, amount: val })
               checkRefundAmount(val)
               break;
            default:
               break;
         }
      }
   }

   useEffect(() => {
      console.log('Bid Submit data ====>', bidSubmitData)
   }, [bidSubmitData])

   const cancel = () => {
      setBidSubmitError(prev => '')
      setBidSubmitData(initBidSubmitData)
      showBidModals(false, 'submit')
      // setAgree(false) 
      setTempAmount('')
      setShowConfirmationPopup(false)
      setCurrentBidAmount(null)
   
   }

   const closeModal = () => {
      dispatch(toggleQuestionnairePopup(false))
      setFormData({})
      setTimeout(() => {
         dispatch(resetSubmitted())
      }, 500);
   }

   const checkBidAmount = (value) => {
      if(value){
         if(!is_closed){
            if(min_increment){
               if(value < current_price + min_increment){
                  setBidSubmitError({ txt1: translate(159), val: `${(current_price + min_increment).toLocaleString('en', opt)}`, txt2: translate(160)})
                  return false
               }
            }
            if(max_increment){
               if(value > current_price + max_increment){
                  setBidSubmitError({txt1: translate(161), val: `${(current_price + max_increment).toLocaleString('en', opt)}`, txt2: translate(162)})
                  return false
               }
            }
            setBidSubmitError({txt1: '', val: '', txt2: ''})
            return true
         }else{
            setBidSubmitError({txt1: '', val: '', txt2: ''})
            return true
         }
      }else{
         setBidSubmitError({txt1: translate(133), val: null, txt2: ''})
         return false
      }
   }

   const checkRefundAmount = (value) => {
      if(value){
         if(value < 200){
            setRefundError('Minimum withdrawal amount is 200 etb')
         }else if(value > balance?.available_balance ){
            setRefundError('Maximum withdrawal amount can not exceed the available balance')
         }else{
            setRefundError('')
         }
      }else{
         setRefundError('')
      }
   }

   const dontShowSidePopup = () => {
      if(dontShowPopup){
         dispatch(setDontShowSidePopup(true))
         AuthService.dontShowSidePopup()
      }
   }

   const handleNextQuestion = () => {
      if (currentQuestionIndex < questionnaire.length - 1 && !transitioning) {
         setTransitioning(true);
         setTimeout(() => {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setTransitioning(false);
         }, 500);
      }
   };

   const handlePrevQuestion = () => {
      if (currentQuestionIndex > 0 && !transitioning) {
         const updatedFormData = [...formData];
         updatedFormData.splice(currentQuestionIndex, 1);
         setFormData(updatedFormData);
         setTransitioning(true);
         setTimeout(() => {
         setCurrentQuestionIndex(currentQuestionIndex - 1);
         setTransitioning(false);
         }, 500);
      }
   };

   const handleAnswerChange = (event) => {
      const { value } = event.target;
      const updatedFormData = [...formData];
      updatedFormData[currentQuestionIndex] = {
        question: questionnaire[currentQuestionIndex].id,
        answer: parseInt(value)
      };
      setFormData(updatedFormData);
   };
  
   const handleSubmit = () => {
      const payload = {};
      formData.forEach(({ question, answer }) => {
         payload[question] = answer;
      });
      dispatch(postQuestionnaire(payload))
   };

   useEffect(() => {
      if(Auth){
         dispatch(getPaymentPlatforms())
      }
      // eslint-disable-next-line
   }, [selectedTab])

   const handlePrompt = () => {
      dispatch(setShowGuidePrompt({ value: false }))
      if(dontShowGuidePromptPopup) {
         setDontShowGuidePromptPopup(false)
         AuthService.dontShowTutoPrompt()
      }
   }

   return (
      <div className = 'px-2'>
         <div className = {`${showSidePopup && Auth && bids.length && !dont_show_Side_Popup && !showGuidePrompt && !show_questionnaire_popup ? 'side-popup' : 'not-side-popup'} fixed right-0 top-[50%] z-10`}>
            <div className = {`rounded-[50%] h-[175px] w-[350px] bg-white text-sky-600 border border-gray-500 text-center py-3 flex justify-between items-center px-4 cursor-pointer shadow-lg shadow-slate-400 relative`} >

               <div className = 'flex-col justify-end w-[85%] flex-grow' onClick = {() => {navigate('/my-bids'); dontShowSidePopup(); setShowSidePopup(false);}}>
                  <div className = 'absolute top-[-3.2rem] w-full flex justify-center pr-3 z-10'>
                     <img src = { cartoon_man_pointing } alt = '' className = 'h-24' />
                  </div>

                  <div className = 'flex flex-col justify-end pl-4 pt-6 h-full leading-3'>
                     <div className = 'font-bold text-sky-600'>{ translate(129) }</div>

                     {bids.length ?
                        <div className = 'text-sm pt-1'>
                           <div>
                              { translate(157) }
                              <span className = 'font-bold text-lg'>{ bids?.length }</span>
                              { translate(158) }
                           </div>
                        </div>
                        : 
                        null
                     }
                     <div className = 'text-gray-700 text-sm font-Roboto pt-1'>{ translate(153) }</div>
                  </div>

                  <div className = 'flex items-center justify-center pl-4 pt-3 w-full' onClick = {(e) => e.stopPropagation()}>
                     <input
                        checked = { dontShowPopup }
                        onChange = { e => {setDontShowPopup(e.target.checked)} } 
                        id = 'dont' 
                        type='checkbox' 
                        className = 'h-4 w-4 rounded border-gray-300 accent-lime-700 cursor-pointer'
                     />
                     <label htmlFor = 'dont' className = 'ml-2 block text-xs text-gray-900' name = 'dont-show-this-message'>
                        { translate(154) }
                     </label>
                  </div>
                  

               </div>

               <button className = 'w-[15%] flex justify-end items-center text-xl hover:text-red-400 z-20' >
                  <FiX className = 'hover:scale-105 duration-200' 
                     onClick = {() => {
                        setShowSidePopup(false)
                        dontShowSidePopup()
                     }}
                  />
               </button>
            </div>
         </div>

         <div 
            className = { ` ${showDepositCard ? 'hidden sm:block' : 'hidden'} rotate-[24deg] h-28 w-20 border border-sky-500 bg-white 
            text-center bottom-0 right-[35%] fixed z-10 text-gray-800 rounded-lg overflow-hidden cursor-pointer shadow-lg shadow-slate-500` }
            onClick = {() => { 
               handlePrompt()
               dispatch(setShowVideoModal({ value: true })) 
            }}
         >
            <div 
               onClick = { e => {e.stopPropagation(); setShowDepositCard(false)} } 
               className = ' cursor-pointer  flex items-center justify-end text-lg'>
                  <FiX className = 'h-5 w-5 hover:text-red-700 text-gray-400' />
            </div>
            <div className = 'w-full leading-4 text-sm px-1 pt-2'>{ translate(155) }</div>
            <div className = 'w-full flex justify-center pt-2 pb-1'>
               <BsYoutube className = 'text-3xl text-red-700 hover:text-red-950' />
            </div>
         </div>
         
         <Modal visible = { show_bid_submit_modal } customWidth = 'w-[94%] md:w-[45%]'>
            <div className = 'flex items-center justify-between w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg' id = 'drag-me'>
               <div className = 'text-center text-white text-lg font-semibold pl-4 md:pl-8'>{ translate(156) }</div>
               <div className = 'pr-4 pt-1'>
                  <Loading loading = { bidding_loading } loadingStyle = 'text-sky-600 w-5 h-5'/>
               </div>
               <div className = 'pr-4'> 
                  <FiX className = 'text-gray-800 text-xl cursor-pointer hover:scale-105' onClick = { cancel }/>
               </div>
            </div>

            {!showConfirmationPopup ?
               <div className = 'w-full pb-7 px-4 sm:px-6 md:px-8 flex flex-col justify-center'>
                  {!can_bid ?
                     <div className = 'text-xs text-gray-700 pt-4 text-center md:px-10'>
                        { translate(164) } <br/>
                        { translate(165) }
                        <span className = 'text-sky-600 cursor-pointer' onClick = {() => Auth ? goToAddNewDeposit() : navigate('/login')}>
                           { translate(168) } 
                        </span> 
                        { translate(166) } 
                        <span className = 'font-semibold'> {Number(min_amount_to_bid).toLocaleString('en', opt)}</span> 
                        { translate(167) }
                     </div>
                     :
                     null
                  }

                  {/* start here... */}
                  {!is_land_bid ?
                     <>
                        <div className = 'text-sm text-red-500 text-center h-8 mt-4'>
                           <span className = ''>{ bidSubmitError.txt1 }</span>
                           <span className = 'font-bold'>{ bidSubmitError.val }</span>
                           <span className = ''>{ bidSubmitError.txt2 }</span> 
                        </div> 

                        <div className = 'flex justify-center w-full'>
                           <input
                              value = { tempAmount ? tempAmount : '' } 
                              onChange = { e => handleChange(e, 'bid_submit', 'amount')}
                              type = 'text' 
                              id = 'number' 
                              className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-gray-500 block w-full md:w-[90%] p-2' 
                              placeholder = { translate(235) } 
                           />
                        </div>

                        <div className = 'h-6'>
                           <div className = 'text-sm text-gray-800 w-full text-center font-thin'>{bidSubmitData.amount ? toWords(bidSubmitData.amount) : null}</div>
                        </div>
                     </>
                      :
                     <div className = 'pt-2 w-full'>
                        <div className = 'font-Roboto text-gray-600'>{`Total Size (m`}<sup>2</sup>{`)`}</div>
                        <div className = 'text-sky-600 text-lg pl-6'>{land_area}</div>

                        <div className = 'flex justify-center w-full'>
                           <div className = 'flex justify-between w-[90%] gap-8'>
                              <div className = 'w-1/2 relative'>
                                 <p className = 'text-xs pb-1'>{`Your Price /m`}<sup>2</sup></p>
                                 <input
                                    // value = { tempAmount ? tempAmount : '' } 
                                    value = { bidSubmitData.per_meter_square ? bidSubmitData.per_meter_square : '' } 
                                    onChange = { e => handleChange(e, 'bid_submit', 'per_meter_square')}
                                    type = 'text' 
                                    id = 'per_meter_square' 
                                    className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-gray-500 block w-full p-2' 
                                    // placeholder = { translate(235) } 
                                 />
                                 <div className = 'absolute top-7 right-3 text-sm text-gray-600'>{`/m`}<sup>2</sup></div>
                              </div>
                              <div className = 'w-1/2 relative'>
                                 <p className = 'text-xs pb-1'>Total Price</p>
                                 <input
                                    value = { bidSubmitData.per_meter_square ? (bidSubmitData.per_meter_square * land_area).toLocaleString('en', opt) : 0} 
                                    // onChange = { e => handleChange(e, 'bid_submit')}
                                    type = 'text' 
                                    id = 'total_price' 
                                    className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2' 
                                    // placeholder = { translate(235) } 
                                    readOnly
                                 />
                                 <div className = 'absolute top-7 right-3 text-sm text-gray-600'>ETB</div>
                              </div>
                           </div>
                        </div>

                        <div className = 'flex justify-center w-full pt-3'>
                           <div className = 'flex justify-between w-[90%] gap-8'>
                              <div className = 'w-1/2 relative'>
                                 <p className = 'text-xs pb-1'>Advance Payment</p>
                                 <input
                                    value = { bidSubmitData.percentage ? bidSubmitData.percentage : '' } 
                                    onChange = { e => handleChange(e, 'bid_submit', 'percentage')}
                                    type = 'text' 
                                    id = 'percentage' 
                                    className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-gray-500 block w-full p-2' 
                                    // placeholder = { translate(235) } 
                                 />
                                 <div className = 'absolute top-7 right-3 text-sm text-gray-600'>%</div>
                              </div>
                              <div className = 'w-1/2 relative'>
                                 <p className = 'text-xs pb-1'>Advance in ETB</p>
                                 <input
                                    value = { bidSubmitData.percentage ? ((bidSubmitData.percentage /100) * (bidSubmitData.per_meter_square * land_area)).toLocaleString('en', opt) : '' } 
                                    // onChange = { e => handleChange(e, 'bid_submit')}
                                    type = 'text' 
                                    id = 'advance_etb' 
                                    className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2' 
                                    // placeholder = { translate(235) } 
                                    readOnly
                                 />
                                 <div className = 'absolute top-7 right-3 text-sm text-gray-600'>ETB</div>
                              </div>
                           </div>
                        </div>
                        <div className = 'flex justify-center w-full pt-3'>
                           <div className = 'flex justify-start w-[90%] pr-7'>
                              <div className = 'w-1/2 relative'>
                                 <p className = 'text-xs pb-1'>{`Installment period (optional)`}</p>
                                 <input
                                    value = { bidSubmitData.payment_years ? bidSubmitData.payment_years : '' } 
                                    onChange = { e => handleChange(e, 'bid_submit', 'payment_years')}
                                    type = 'text' 
                                    id = 'payment_years' 
                                    className = 'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:border-gray-500 block w-full p-2' 
                                    // placeholder = { translate(235) } 
                                 />
                                 <div className = 'absolute top-7 right-3 text-sm text-gray-600'>year</div>
                              </div>
                              
                           </div>
                        </div>
                     </div>
                  } 
                  {/* End here... */}

                  <div className = 'flex justify-center pt-10 w-full'>
                     <div className = 'w-full md:w-[90%]'>

                        {is_closed ?
                           <div className = 'w-full flex justify-between text-gray-500'>
                              <div className = 'w-full'>
                                 <p className = 'text-sm'>{ translate(169) }</p>
                                 <p className = 'text-sky-500'>{ available_balance?.toLocaleString('en', opt) }</p>
                              </div>
                              <div className = 'w-full text-right'>
                                 <p className = 'text-sm'>{ translate(170) }</p>
                                 <p className = 'text-sky-500'>{ min_amount_to_bid ? min_amount_to_bid.toLocaleString('en', opt) : '' }</p>
                              </div>
                           </div>
                           :
                           <>
                              <div className = 'w-full flex justify-between text-gray-500'>
                                 <div className = 'w-full'>
                                    <p className = 'text-sm'>{ translate(169) }</p>
                                    <p className = 'text-sky-500'>{ available_balance?.toLocaleString('en', opt) }</p>
                                 </div>
                                 <div className = 'w-full text-right'>
                                    <p className = 'text-sm'>{ translate(171)}</p>
                                    <p className = 'text-sky-500'>{ min_increment ? (min_increment + current_price).toLocaleString('en', opt) : '-' }</p>
                                 </div>
                              </div>

                              <div className = 'w-full flex justify-between text-gray-500 pt-5'>
                                 <div className = 'w-full'>
                                    <p className = 'text-sm'>{ translate(170) }</p>
                                    <p className = 'text-sky-500'>{ min_amount_to_bid ? min_amount_to_bid.toLocaleString('en', opt) : '' }</p>
                                 </div>
                                 <div className = 'w-full text-right'>
                                    <p className = 'text-sm'>{ translate(172) }</p>
                                    <p className = 'text-sky-500'>{ max_increment ? (max_increment + current_price).toLocaleString('en', opt) : '-' }</p>
                                 </div>
                              </div>
                           </>
                        }
                     </div>
                  </div>

                  <div className = 'flex justify-center w-full pt-7'>
                     <div className = 'flex items-center w-full sm:w-[500px]'>
                        <input
                           checked = { agree }
                           onChange = { e => setAgree(e.target.checked) } 
                           id = 'agree1' 
                           type='checkbox' 
                           className = 'h-4 w-4 rounded border-gray-300 accent-lime-700'
                        />
                        <label htmlFor = 'agree1' className = 'ml-2 block text-sm text-gray-900'>
                           { translate(173) } 
                           <Link to = '/termsAndConditions' target = '_blank' className = 'text-primary-100'>
                              {` (${translate(42)}) `}
                           </Link>
                        </label>
                     </div>
                  </div>

                  <div className = 'flex justify-center w-full pt-7'>
                     <div className = 'flex justify-between w-full sm:w-[500px]'>
                        <button
                           className = 'bg-stone-500 rounded w-[40%] py-2 text-white hover:bg-stone-700'
                           onClick = { cancel }
                        >
                           { translate(63) }
                        </button>
                        <button 
                           className = { `${!agree ? 'bg-gray-300 text-gray-400' : 'bg-sky-600 hover:bg-sky-700 text-white' } rounded w-[40%] py-2 `}
                           title = { !agree ? translate(174) : translate(175)  }
                           disabled = { !agree }
                           onClick = { () => checkBidAmount(currentBidAmount) && (new Date(end_time).getTime() > nowUtcTime) ? setShowConfirmationPopup(true) : submit_Bid() }
                        >
                           { translate(175) }
                        </button>
                     </div>
                  </div>
               </div>
               :
               <div className = {`w-full pb-7 px-4 md:px-8 flex flex-col justify-center`}>
                  <div className = {`flex flex-col ${is_land_bid ? 'gap-2 sm:gap-3 pt-5' : 'gap-4 sm:gap-8 pt-12'} `}>
                     {is_land_bid ?
                        <div>
                           <h2 className = 'text-gray-500 font-semibold text-base sm:text-lg'>Your bid detail:</h2>
                           <div className = 'flex justify-between gap-8 w-full px-3 sm:px-10'>
                              <div className = 'text-gray-500'>{`Your Price /m`}<sup>2</sup></div>
                              <div className = 'text-sky-600'>{bidSubmitData.per_meter_square} Br.</div>
                           </div>
                           <div className = 'flex justify-between gap-8 w-full px-3 sm:px-10'>
                              <div className = 'text-gray-500'>Total Price</div>
                              <div className = 'text-sky-600'>{(bidSubmitData.per_meter_square * land_area).toLocaleString('en', opt)} Br.</div>
                           </div>
                           <div className = 'flex justify-between gap-8 w-full px-3 sm:px-10'>
                              <div className = 'text-gray-500'>Advance payment in percent</div>
                              <div className = 'text-sky-600'>{bidSubmitData.percentage} %</div>
                           </div>
                           <div className = 'flex justify-between gap-8 w-full px-3 sm:px-10'>
                              <div className = 'text-gray-500'>Advance payment in ETB</div>
                              <div className = 'text-sky-600'>{((bidSubmitData.percentage /100) * (bidSubmitData.per_meter_square * land_area)).toLocaleString('en', opt)} Br.</div>
                           </div>
                           <div className = 'flex justify-between gap-8 w-full px-3 sm:px-10'>
                              <div className = 'text-gray-500'>Installment period</div>
                              <div className = 'text-sky-600'>{bidSubmitData.payment_years ? bidSubmitData.payment_years + `${bidSubmitData.payment_years > 1 ? ' years' : ' year'}` : '--'}</div>
                           </div>
                        </div>
                        :
                        <>
                        <h2 className = 'text-gray-700 text-base text-center'>
                           { translate(216) }
                           {/* <span className = 'text-sky-500 text-lg font-semibold'>{ tempAmount }</span> */}
                           <span className = 'text-sky-500 text-lg font-semibold'>{ bidSubmitData.amount.toLocaleString('en', opt) }</span>
                           <span className = 'text-base'> { translate(217) }</span>
                        </h2>
                        <p className = 'text-sky-500 w-full text-center'>{`/ ${toWords(bidSubmitData.amount)} Br. /`}</p>
                        </>
                     }

                     <div className = 'mt-8 flex justify-between w-full'>
                        <button 
                           onClick = {() => {
                              submit_Bid()
                              setShowConfirmationPopup(false)
                           }}
                           className = 'bg-sky-600 hover:bg-sky-700 text-white p-2 rounded w-[40%]'>
                              { translate(218) }
                        </button>

                        <button 
                           onClick = {() => setShowConfirmationPopup(false)} 
                           className = 'bg-gray-300 hover:bg-gray-500 hover:text-white p-2 rounded border border-gray-400 w-[40%]'>
                              { translate(63) }
                        </button>
                     </div>
                  </div> 
               </div>
            }
         </Modal>
         <Modal visible = { show_one_time_subscription_modal } customWidth = 'w-[94%] md:w-[45%]'>
            <div className = 'flex items-center justify-between w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg' id = 'drag-me'>
               <div className = 'text-center text-white text-lg font-semibold pl-4 md:pl-8'>{ translate(347) }</div>
               <div className = 'pr-4 pt-1'>
                  <Loading loading = { bidding_loading } loadingStyle = 'text-sky-600 w-5 h-5'/>
               </div>
               <div className = 'pr-4'> 
                  <FiX className = 'text-gray-800 text-xl cursor-pointer hover:scale-105' onClick = {()=> {dispatch(toggleOneTimeModal({ value: false }))} }/>
               </div>
            </div>
            <div className="p-10">
            <div className=" text-center">
            { translate(346) }
            </div>
            <div className = 'mt-8 flex justify-between w-full '>
                        <button 
                           onClick = {() => {
                             
                              navigate("/subscription", { state: { auction_id: auctionById.detail.id } });
                              dispatch(toggleOneTimeModal({ value: false }))
                           
                           }}
                           className = 'bg-sky-600 hover:bg-sky-700 text-white p-2 rounded w-[40%]'>
                              { translate(75) }
                        </button>

                        <button 
                           onClick = {() => dispatch(toggleOneTimeModal({ value: false }))} 
                           className = 'bg-gray-300 hover:bg-gray-500 hover:text-white p-2 rounded border border-gray-400 w-[40%]'>
                              { translate(63) }
                        </button>
                     </div>
            </div>
        
          

          
         </Modal>

         <Modal visible = { show_bid_success_modal } customWidth = 'w-[94%] md:w-[45%]'>
            <div className = 'w-full pb-7 px-4 md:px-8'>
            
               <p className = 'text-center text-lime-600 text-2xl pt-10'>{ translate(176) }</p>
               <p className = 'text-center font-semibold pt-2'>{ translate(177) }</p>
               {!can_bid ?
                  <div className = 'text-sm text-gray-500 pt-3 text-center font-b'>
                     { translate(164) } <br/>
                     { translate(165) }
                     <span className = 'text-sky-600 cursor-pointer' onClick = { goToAddNewDeposit }>
                        { translate(168) } 
                     </span> 
                     { translate(166) } 
                     <span className = 'font-semibold'> {Number(min_amount_to_bid).toLocaleString('en', opt)}</span> 
                     { translate(167) }

                     <p className = 'sm:px-12'>
                        { translate(178) } 
                        <span className = 'text-sky-600 cursor-pointer'>{ translate(179) }</span>
                        { translate(180) }
                     </p>
                  </div>
                  :
                  <p className = 'pt-3 text-center text-gray-500 text-sm'>
                     { translate(181) }
                     <span className = 'text-sky-600 font-semibold cursor-pointer underline' 
                        onClick = {() => {
                           navigate('my-bids')
                           showBidModals(false, 'success')
                        }}
                     >
                        { translate(129) }
                     </span> 
                     { translate(182) }
                     { translate(178) }
                     <span className = 'text-sky-600 cursor-pointer'>{ translate(179) }</span>
                     { translate(180) }
                  </p>
               }
               <p className = 'font-Msoft text-center pt-5 text-gray-500'>{ translate(183) }</p>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex justify-center w-full md:w-80'>
                     <button 
                        className = 'bg-lime-600 rounded w-24 py-1 text-white hover:bg-lime-700'
                        onClick = {() => showBidModals(false, 'success')} 
                     >
                        { translate(90) }
                     </button>
                  </div>
               </div>
            </div>
         </Modal>

         <Modal visible = { show_upload_receipt_modal } customWidth = 'w-[94%] md:w-[45%]' >
            <div className = 'flex justify-between items-center w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg'>
               <div className = 'text-center text-white text-lg font-semibold pl-4 md:pl-8'>{ translate(199) }</div>
               <div className = 'pr-4 pt-1'><Loading loading = { upload_loading } loadingStyle = 'text-sky-600 w-5 h-5'/></div>
               <div className = 'pr-4'> 
                  <FiX 
                     className = 'text-gray-500 text-2xl cursor-pointer hover:scale-105 hover:text-red-500' 
                     onClick = { () => {
                        setReceipt(initUploadReceiptData)
                        setTempAmount('')
                        setSelectedFile('')
                        setShowPreview('')
                        setShowError(false)
                        dispatch(toggleUploadReceiptModal({ value: false }))
                     }}
                  />
               </div>
            </div>
            <div className = 'w-full py-7 px-4 md:px-8'>
               <div className = 'md:flex w-full'>
                  <div className = 'w-full sm:w-3/5'>

                     <div className = 'w-full'>
                        <label htmlFor = 'select_bank' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400'>{ translate(189) }</label>
                        <select 
                           value = { receipt.bank } 
                           onChange = { e => setReceipt({ ...receipt, bank: e.target.value }) } 
                           id = 'select_bank' 
                           className = 'focus:outline-none block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm'
                        >
                           <option className = 'hidden font-thin text-blue-600 hover:text-blue-600'>{ translate(190) }</option>
                           {
                              banks.map((b, i) => <option key = { i }  value = { b.id }>{ b.name }</option>)
                           }
                        </select>
                        <p className = {`${!receipt.bank && showError ? 'visible' : 'invisible'} text-red-500 text-xs text-right`}>{ translate(191) }</p>
                     </div>

                     <div className = 'w-full md:mr-2'>
                        <label htmlFor = 'receipt_amount' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400'>{ translate(192) }</label>
                        <input 
                           value = { tempAmount ? tempAmount : '' } 
                           onChange = { e => handleChange(e, 'bid_receipt')} 
                           type = 'text' 
                           id = 'receipt_amount' 
                           className = 'block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:outline-none' 
                           placeholder = { translate(193) } 
                        />
                     </div>

                     <div className = 'text-sm text-gray-800 w-full font-thin'>
                        {receipt.amount ? toWords(receipt.amount) : null}
                     </div>
                  </div>

                  <div className = ' flex justify-center md:w-1/2 pt-4 md:pt-0'>
                     <div className = ' rounded-lg overflow-hidden w-[70%] md:w-full md:pl-5 cursor-pointer' onMouseOver = { () => setShowButton(true) } onMouseOut = { () => setShowButton(false) }>
                        <div className = 'border border-gray-300 flex justify-center items-center h-20 md:h-36 bg-gray-200 rounded-lg relative'>
                           {showPreview ? 
                              <img src = { showPreview } alt = '' className = 'w-full h-40 md:h-56 object-cover '/> 
                              : 
                              <p className = 'text-sm text-gray-500 px-2 text-center'> { translate(200) } </p>
                           }
                           <div
                              className = {`${showButton ? 'show-button bottom-0' : 'hide-button' } rounded-b-lg absolute w-full bg-gray-700/40 hover:bg-gray-800/40 text-white flex justify-center items-center cursor-pointer`}
                              onClick = { attachFile }
                           >
                              <span className = 'pr-4'><IoCameraOutline className = 'text-lg'/> </span> { translate(198) }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex items-center w-full md:w-full'>
                     <input 
                        checked = { agree }
                        onChange = { e => setAgree(e.target.checked) } 
                        id = 'agree2' 
                        type = 'checkbox' 
                        className = 'h-4 w-4 rounded border-gray-300 accent-lime-700'
                     />
                     <label htmlFor = 'agree2' className = 'ml-2 block text-sm text-gray-900 '>
                        { translate(173) } 
                        <Link to = '/termsAndConditions' target = '_blank' className = 'text-primary-100'> {` (${translate(42)}) `}</Link>
                     </label>
                  </div>
               </div>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex justify-between w-full md:w-full'>
                     <button
                        className = 'bg-stone-500 rounded w-[40%] py-2 text-white hover:bg-stone-700'
                        onClick = { () => {
                           setReceipt(initUploadReceiptData)
                           setTempAmount('')
                           setSelectedFile('')
                           setShowPreview('')
                           setShowError(false)
                           dispatch(toggleUploadReceiptModal({ value: false }))
                        }}
                     >
                        { translate(63) }
                     </button>
                     <button 
                        className = { `${!receipt.amount || !selectedFile || !agree ? 'bg-gray-300 text-gray-400' : 'bg-lime-600 hover:bg-lime-700 text-white' } rounded w-[40%] py-2` }
                        title = { !agree ? translate(174) : translate(175) }
                        disabled = { !receipt.amount || !selectedFile || !agree }
                        onClick = { () => {isValid(receipt) ? uploadReceipt() : setShowError(true)} }
                     >
                        { translate(175) }
                     </button>
                  </div>
               </div>
            </div>
         </Modal>
               
         <Modal visible = { show_upload_document_modal } customWidth = 'w-[94%] md:w-[45%]' >
            <div className = 'flex justify-between items-center w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg'>
               <div className = 'text-center text-white text-lg font-semibold pl-4 md:pl-8'>{ translate(201) }</div>
               <div className = 'pr-4 pt-1'><Loading loading = { document_loading2 } loadingStyle = 'text-sky-600 w-5 h-5'/></div>
               <div className = 'pr-4'> 
                  <FiX 
                     className = 'text-gray-500 text-2xl cursor-pointer hover:scale-105 hover:text-red-500' 
                     onClick = { () => {
                        setTempTitle('')
                        setShowPreview('')
                        setSelectedFile('')
                        dispatch(toggleUploadDocumentModal({ value: false }))
                     }}
                  />
               </div>
            </div>
            <div className = 'w-full py-7 px-4 md:px-8'>
               
               <div className = 'md:flex w-full'>
                  <div className = 'w-full md:w-3/5 mr-2'>
                     <label htmlFor = 'title' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400 '>{ translate(202)}</label>
                     <input
                        value = { tempTitle } 
                        onChange = { e => setTempTitle(e.target.value) } 
                        type = 'text' 
                        id = 'title' 
                        className = 'block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-gray-500' 
                        placeholder = { translate(203) }
                        autoComplete = 'new-title'
                     />
                  </div>


                  <div className = 'flex justify-center md:w-1/2 pt-4 md:pt-0'>
                     <div 
                        className = 'rounded-lg overflow-hidden w-[70%] md:w-full md:pl-5 cursor-pointer' 
                        onMouseOver = {() => setShowButton(true)} 
                        onMouseOut = {() => setShowButton(false)}
                     >
                        <div className = 'border border-gray-300 flex justify-center items-center h-20 md:h-36 bg-gray-200 rounded-lg relative'>
                           {showPreview ? 
                              <img src = { showPreview } alt = '' className = 'w-full h-40 md:h-56 object-cover '/> 
                              : 
                              <p className = 'text-sm text-gray-500 px-2 text-center'>{ translate(334) }</p>
                           }
                           <div
                              className = {`${showButton ? 'show-button bottom-0' : 'hide-button' } rounded-b-lg absolute w-full bg-gray-700/40 hover:bg-gray-800/40 text-white flex justify-center items-center cursor-pointer`}
                              onClick = { attachFile }
                           >
                              <span className = 'pr-4'><IoCameraOutline className = 'text-lg'/> </span> { translate(198) }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex items-center w-full md:w-full'>
                     <input
                        checked = { agree }
                        onChange = { e => setAgree(e.target.checked) } 
                        id = 'agree3' 
                        type = 'checkbox' 
                        className = 'h-4 w-4 rounded border-gray-300 accent-lime-700'
                     />
                     <label htmlFor = 'agree3' className = 'ml-2 block text-sm text-gray-900'>
                        { translate(173) }
                        <Link to = '/termsAndConditions' target = '_blank' className = 'text-primary-100'>{` (${translate(42)}) `}</Link>
                     </label>
                  </div>
               </div>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex justify-between w-full md:w-full'>
                     <button 
                        className = 'bg-stone-500 rounded w-[40%] py-2 text-white hover:bg-stone-700'
                        onClick = { () => {
                           setTempTitle('')
                           setShowPreview('')
                           setSelectedFile('')
                           setAgree(false)
                           dispatch(toggleUploadDocumentModal({ value: false }))
                        }}
                     >
                        { translate(63) }
                     </button>

                     <button 
                        className = { `${!agree || !selectedFile || !tempTitle ? 'bg-gray-300 text-gray-400' : 'bg-lime-600 hover:bg-lime-700 text-white '} rounded w-[40%] py-2` }
                        title = { !agree ? translate(174) : translate(175) }
                        disabled = { !agree || !selectedFile || !tempTitle}
                        onClick = { () => uploadDocument() }
                     >
                        { translate(175) }
                     </button>
                  </div>
               </div>
            </div>
         </Modal>

         <Modal visible = { show_chang_password_modal } customWidth = 'w-[94%] md:w-[35%]'>
            <div className = 'flex justify-between items-center w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg'>
               <div className = 'text-center text-white text-lg font-semibold pl-4 md:pl-8'>{ translate(148) }</div>
               <div className = 'pr-4 pt-1'><Loading loading = { password_loading } loadingStyle = 'text-sky-600 w-5 h-5'/></div>
               <div className = 'pr-4'> 
                  <FiX 
                     className = 'text-gray-500 text-2xl cursor-pointer hover:scale-105 hover:text-red-500' 
                     onClick = {() => {
                        setComparePassword(initComparePassword)
                        setChangePW({current_password: ''})
                        dispatch(toggleChangePasswordModal({ value: false }))
                     }} 
                  />
               </div>
            </div>
            <div className = 'w-full py-7 px-4 md:px-8'>

               <div className = 'w-full pt-2 md:pt-1 px-4 relative'>
                  <CustomField
                     id = 'current_password'
                     name = 'current_password'
                     type = { showPassword ? 'text' : 'password'}
                     placeholder = { translate(205) }
                     value = {changePW?.current_password ? changePW.current_password : ''}
                     rules = {{ required: translate(206) }}
                     onBlur = { () => console.log() }
                     onChange = { value => setChangePW({ ...changePW, current_password: value }) }
                     labelStyle ='text-gray-800 text-xs'
                     inputStyle = 'bg-white h-10 font-sans text-gray-700 text-sm border rounded-md'
                     divStyle = 'text-start'
                     label = { translate(204) }
                     autoComplete = 'new-password'
                  />
                  <button 
                     onClick = {() => setShowPassword(!showPassword)}
                     className = 'flex flex-row bg-transparent rounded-md items-center justify-center p-3 absolute top-[30px] right-4'
                     title = { showPassword ? translate(208) : translate(207) }
                  >
                     {showPassword ? 
                        <BsEyeSlash className = 'text-primary-dark text-xl'/> 
                        : 
                        <BsEye className = 'text-primary-dark text-xl'/>
                     }
                  </button>
               </div>
               <div className = 'w-full px-4 relative'>
                  <CustomField
                     id = 'password'
                     name = 'password'
                     type = 'password'
                     placeholder = { translate(210) }
                     value = { comparePassword?.password_1 ? comparePassword.password_1 : '' }
                     rules = {{ required: translate(211) }}
                     onBlur = { () => console.log() }
                     onChange = { value => setComparePassword({ ...comparePassword, password_1: value }) }
                     labelStyle ='text-gray-800 text-xs'
                     inputStyle = 'bg-white h-10 font-sans text-gray-700 text-sm border rounded-md'
                     divStyle = 'text-start'
                     label = { translate(209) }
                     autoComplete = 'new-password'
                  />
                  <div className = 'text-end flex justify-start w-full h-2 absolute top-[68px]'>
                     <p className = 'text-[0.6rem] sm:text-[0.7rem] text-gray-500'>{ translate(112) }</p>
                  </div>
               </div>
               <div className = 'w-full px-4'>
                  <CustomField
                     id = 'confirmPassword'
                     name = 'confirmPassword'
                     type = 'password'
                     placeholder = { translate(213) }
                     value = {comparePassword?.password_2 ? comparePassword.password_2 : ''}
                     rules = {{ required: translate(214) }}
                     onBlur = { () => console.log() }
                     onChange = { value => setComparePassword({ ...comparePassword, password_2: value }) }
                     labelStyle = 'text-gray-800 text-xs'
                     inputStyle = 'bg-white h-10 font-sans text-gray-700 text-sm border rounded-md'
                     divStyle = 'text-start'
                     notMatch = { checkPasswordMatch() }
                     label = { null }
                  />
               </div>
               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex justify-between w-full md:w-full p-4'>
                     <button 
                        className = 'bg-stone-500 rounded w-[40%] py-2 text-white hover:bg-stone-700'
                        onClick = {() => {
                           setComparePassword(initComparePassword)
                           setChangePW({current_password: ''})
                           dispatch(toggleChangePasswordModal({ value: false })) 
                        }}
                     >
                        { translate(63) }
                     </button>
                     <button 
                        className = { `${!changePW.current_password || !changePW.password1 || !changePW.password2 ? 
                        'bg-gray-300 text-gray-400' : 
                        'bg-lime-600 hover:bg-lime-700 text-white '} rounded w-[40%] py-2` }
                        disabled = { !changePW.current_password || !changePW.password1 || !changePW.password2 }
                        onClick = { handleChangePassword }
                     >
                        { translate(175) }
                     </button>
                  </div>
               </div>
            </div>
         </Modal>

         <Modal visible = { show_notification_modal } customWidth = 'w-[94%] md:w-[60%] max-h-[530px] overflow-y-auto others notification'>
            <div className = 'flex justify-between py-2 sticky top-0 right-0 left-0 z-10 bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50'>
               <div className = 'text-center text-white pl-4 text-lg font-semibold'>{ translate(105) }</div>
               <div onClick = { () => dispatch(toggle_notification_modal({ value: false }))} className = 'text-gray-700 text-right cursor-pointer pr-3'>
                  <FiX className = 'text-2xl text-slate-500 hover:text-red-600' />
               </div>
            </div>
            {notifications.length ?
               notifications.map((notification, i) => (
                  <div key = { i } onClick = { () => redirectTo(notification?.message?.data?.msg_type, notification?.message?.data?.object_id, notification) } className = 'w-full sm:px-2 pb-1 bg-gray-200 hover:bg-gray-50 rounded mb-2 relative cursor-pointer flex justify-between h-auto'>
                     
                     <div className = {` w-[85%] sm:w-[90%] min-h-[50px] flex flex-row items-center  ${!notification.seen ? 'text-gray-800' : 'text-gray-400'}`}>
                        {
                           notification?.message?.notification?.image
                           ?
                           <div className='w-[45%] sm:w-[12%] h-full'>
                              <img src = { notification?.message?.notification?.image ? notification.message.notification.image : logo_blur } alt = '' align = 'left' className = {`w-full sm:w-24 pt-1 pr-3 object-cover ${!notification?.message?.notification?.image ? 'blur-sm' : ''}`}/>
                           </div>
                           :
                           null
                        }
                        <div className='w-[55%] sm:w-[88%]'>
                           <div className = 'text-xs sm:text-sm sm:font-semibold'>{ notification?.message?.notification?.title }</div>
                           <div className = 'text-[10px] sm:text-xs font-Roboto leading-none'>{ notification?.message?.notification?.body }</div>
                        </div>
                     </div>

                     <div className='flex flex-col justify-between h-full w-[15%] sm:w-[10%]'>
                        <div>
                           {
                              !notification.seen
                              ? 
                              <div className = 'pt-2 pr-1 sm:pr-2 absolute top-0 right-0'>
                                 <MdCircle className = 'h-2 w-2 text-blue-500'/>
                              </div>
                              :
                              null
                           }
                        </div>
                        <div className = {`absolute bottom-2 right-1 sm:right-2 text-[9px] sm:text-[10px]  ${!notification.seen ? 'text-gray-800 font-semibold' : 'text-gray-400 font-thin'}`}>{ dayjs(notification.created_at).format('MMM D, YYYY') }</div>
                     </div>

                  </div>
               ))
               :
               <div className = 'text-center'>
                  <div className = 'italic text-lg text-gray-500 pb-5 px-auto'>{ translate(215) }</div>
               </div>
            }
         </Modal>

         <Modal visible = { showGenericNotification } customWidth = 'w-[94%] md:w-[50%] pb-4'>
            <div className = 'flex justify-between py-2 bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 px-4 rounded-t-lg'>
               <div className = 'text-center text-white text-lg font-semibold'>{ tempNotification?.message?.notification?.title ? tempNotification.message.notification.title : ''}</div>
               <div onClick = { () => { toggleGenericNotification(false); setTempNotification(null) }} className = 'text-gray-700 text-right cursor-pointer'>
                  <FiX className = 'text-2xl text-slate-500 hover:text-red-600' />
               </div>
            </div>

            <div className='sm:flex items-center justify-between h-full px-3'>
               <div className = 'h-full flex sm:flex-none justify-center'>
                  <img src = { tempNotification?.message?.notification?.image || logo_blur } alt = '' className={`h-20 object-cover ${!tempNotification?.message.notification.image ? 'blur-sm' : ''}`}/>
               </div>
               <div className = {`sm:pl-5 flex-grow h-full`}>
                  <div className = 'text-lg text-black'>{ tempNotification?.message?.notification?.title ? tempNotification.message.notification.title : ''}</div>
                  <div className = 'text-sm text-gray-700 pt-1'>{ tempNotification?.message?.notification?.body ? tempNotification.message.notification.body : ''}</div>
                  <div className = {`text-sm font-thin text-gray-700 text-right`}>{ dayjs(tempNotification?.created_at).format('MMM D, YYYY') }</div>
               </div>
            </div>
         </Modal>

         <Modal visible = { show_bid_security_modal } customWidth = 'w-[94%] sm:w-[80%] md:w-[45%] max-h-[96%] sm:max-h-[85%] overflow-y-auto bid-security'>
            <div className = 'flex justify-between items-center w-full border-b border-stone-400 py-2 rounded-t-lg bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50'>
               <div className = 'text-center text-white text-lg font-semibold pl-4 md:pl-8'>{ translate(30) }</div>
               <div className = 'pr-4 pt-1'><Loading loading = { bid_security_loading } loadingStyle = 'text-sky-600 w-5 h-5'/></div>
               <div className = 'pr-4'> 
                  <FiX 
                     className = 'text-gray-500 text-2xl cursor-pointer hover:scale-105 hover:text-red-500' 
                     onClick = { () => {
                        setBidSecurityData(initBidSecurityData)
                        setTempAmount('')
                        setSelectedFile(null)
                        setSelectedOnlinePayment(null)
                        setShowPreview('')
                        dispatch(toggleBidSecurityModal({ value: false }))
                        setShowError(false)
                        setOpenBankInfo(false)
                     }}
                  />
               </div>
            </div>

            <div className = 'px-2 sm:px-6 pt-4'>
               <div className = {`sm:flex justify-center`}>
                  <nav className ="flex flex-row justify-between w-full sm:w-[ bg-gray-100 text-xs sm:text-base">
                     <button onClick = {() => {setSelectedTab('bank-receipt'); setSelectedOnlinePayment(null)}} className = {`text-gray-500 py-2 sm:py-4 flex-1 block hover:text-sky-600 hover:bg-gray-100 focus:outline-none border ${selectedTab === 'bank-receipt' ? 'rounded-t-lg border-t-sky-600 border-x-sky-600 border-b-transparent font-medium bg-white text-gray-800' : 'border-b-sky-600 border-x-white'}`}>
                        Upload Bank Receipt
                     </button>
                     <button onClick = {() => setSelectedTab('pay-online')} className = {`text-gray-500 py-2 sm:py-4 flex-1 block hover:text-sky-600 hover:bg-gray-100 focus:outline-none border ${selectedTab === 'pay-online' ? 'rounded-t-lg border-t-sky-600 border-x-sky-600 border-b-transparent font-medium bg-white text-gray-800' : 'border-b-sky-600 border-x-white'}`}>
                        Pay Online
                     </button>
                  </nav>
               </div>
            </div>

            <div className = 'w-full pb-7 px-4 md:px-8 relative'>
               <div className = 'w-full pt-1 sm:pt-4'>
                  {selectedTab === 'bank-receipt' ?
                  <>
                     <div className = 'text-sm'>
                     Please order your CPO or make the bank transfer using Auction Ethiopia's account details before you fill this form. <span className = 'italic text-sky-600 hover:text-sky-700 cursor-pointer underline' onClick = {() => setOpenBankInfo(true)}>Click here to see CPO and bank account details.</span>
                     </div>

                     { openBankInfo ? 
                        <div className = 'absolute z-30 -top-12 left-[42%] min-h-[100px] rounded-xl px-2 py-2 border border-gray-400 bg-black/90 text-sm text-white w-56 sm:w-96'>
                           <div className = 'relative'>
                              <h1 className = 'text-lg font-bold'>For CPO:</h1>
                              <p className = ''>Order your CPO with the name "Auction Ethiopia S.C."</p>

                              <h1 className = 'text-lg font-bold pt-4'>For Bank Transfer:</h1>

                              <p className = 'pt-1'>1000547266289</p>
                              <p className = ''>Auction Ethiopia S.C</p>
                              <p className = ''>CBE</p>

                              <p className = 'pt-4'>150335965</p>
                              <p className = ''>Auction Ethiopia Share Company</p>
                              <p className = ''>Bank Of Abyssinia</p>

                              <p className = 'pt-4'>0254536558011</p>
                              <p className = ''>Auction Ethiopia Share Company</p>
                              <p className = ''>Dashen Bank</p>

                              <div className = 'absolute top-1 right-1 text-white hover:scale-105 duration-200 cursor-pointer' onClick = {() => setOpenBankInfo(false)}>X</div>
                           </div>
                        </div> : null 
                     }
                     <div className = 'sm:flex md:flex gap-4 w-full pt-4'>
                        <div className = 'w-full sm:w-1/2 md:w-1/2'>
                           <label htmlFor = 'bid_security_type' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400 '>{ translate(184) }</label>
                           <select value = { bidSecurityData.type } onChange = { e => setBidSecurityData({ ...bidSecurityData, type: e.target.value }) }  id = 'bid_security_type' className = 'focus:outline-none block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 shadow-sm'>
                              <option className = 'hidden font-thin text-blue-600 hover:text-blue-600'>{ translate(185) }</option>
                              <option value = 'CPO'>{ translate(186) }</option>
                              <option value = 'BANK_TRANSFER'>{ translate(187) }</option>
                           </select>
                           <p className = {`${!bidSecurityData.type && showError ? 'visible' : 'invisible'} text-red-500 text-[11.5px] text-right`}>
                              { translate(188) }
                           </p>
                        </div>
                        
                        <div className = 'w-full sm:w-1/2 md:w-1/2'>
                           <label htmlFor = 'select_bank2' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400'>{ translate(189) }</label>
                           <select 
                              value = { bidSecurityData.bank } 
                              onChange = { e => setBidSecurityData({ ...bidSecurityData, bank: e.target.value }) } 
                              id = 'select_bank2' 
                              className = 'focus:outline-none block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 shadow-sm'
                           >
                              <option className = 'hidden font-thin text-blue-600 hover:text-blue-600'>{ translate(190) }</option>
                              {
                                 banks.map((b, i) => <option key = { i }  value = { b.id }>{ b.name }</option>)
                              }
                           </select>
                           <p className = {`${!bidSecurityData.bank && showError ? 'visible' : 'invisible'} text-red-500 text-[11.5px] text-right`}>{ translate(191) }</p>
                        </div>
                     </div>
                  
                     <div className = 'sm:flex md:flex w-full gap-4'>
                        <div className = 'w-full sm:w-1/2 md:w-1/2'>
                           <div className = 'pt-2'>
                              <label htmlFor = 'amount_online' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400 '>{ translate(192) }</label>
                              <input 
                                 value = { tempAmount ? tempAmount : '' } 
                                 onChange = { e => handleChange(e, 'bid_security')} 
                                 type = 'text' 
                                 id = 'amount_online' 
                                 className = 'block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 shadow-sm focus:outline-none focus:border-gray-500' 
                                 placeholder = { translate(193) } 
                              />
                              <p className = {`${!bidSecurityData.amount && showError ? 'visible' : 'invisible'} text-red-500 text-[11.5px] text-right`}>{ translate(194) }</p>
                           </div>

                           <div className = 'h-6'>
                              <div className = 'text-sm text-gray-800 w-full text-center font-thin'>
                                 { bidSecurityData.amount ? toWords(bidSecurityData.amount) : null }
                              </div>
                           </div>

                           <div className = 'pt-2'>
                              <label htmlFor = 'cpo_number' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400 '>{ translate(333) }</label>
                              <input 
                                 value = { bidSecurityData.cpo_number } 
                                 onChange = { e => setBidSecurityData({ ...bidSecurityData, cpo_number: e.target.value }) } 
                                 type = 'number' 
                                 id = 'cpo_number' 
                                 className = 'block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 shadow-sm focus:outline-none focus:border-gray-500' 
                                 placeholder = { translate(333) } 
                              />
                              <p className = {`${!bidSecurityData.cpo_number && showError ? 'visible' : 'invisible'} text-red-500 text-[11.5px] text-right`}>{ translate(196) }</p>
                           </div>
                        </div>

                        <div className = 'pt-3 sm:pt-7 flex justify-center w-full sm:w-1/2 md:w-1/2'>
                           <div 
                              className = ' rounded-lg w-[80%] md:w-full cursor-pointer' 
                              onClick = {() => setShowButton(!showButton)} 
                              onMouseOver = {() => setShowButton(true)} 
                              onMouseOut = {() => setShowButton(false)}
                           >
                              <div className = 'border border-gray-300 rounded-lg overflow-hidden flex justify-center items-center h-32 md:h-36 bg-gray-200 relative'>
                                 {showPreview ? 
                                    <img src = { showPreview } alt = '' className = 'w-full h-40 md:h-56 object-cover '/> 
                                    : 
                                    <p className = 'text-sm text-gray-500 px-2 text-center'>{ translate(197) }</p>
                                 }
                                 <div
                                    className = {`${showButton ? 'show-button bottom-0' : 'hide-button' } absolute w-full bg-gray-700/40 hover:bg-gray-800/40 text-white flex justify-center items-center cursor-pointer`}
                                    onClick = { () => attachFile() }
                                 >
                                    <span className = 'pr-4'><IoCameraOutline className = 'text-lg'/> </span> { translate(198) }
                                 </div>
                              </div>

                              <p className = {`${!selectedFile && showError ? 'visible' : 'invisible'} text-red-500 text-[11.5px] text-right`}>{ translate(192) }</p>
                           </div>
                        </div>
                     </div>
                  </>
                  :
                  <div className = 'w-full px-4 md:px-8 h-[80%] '>

                     <div className = {`py-2 flex flex-col gap-2 sm:gap-5 bg-white w-full overflow-hidden px-1`}>
                        <p className = 'text-gray-600 pl-2 py-1 text-base sm:text-lg'>{ translate(303) }</p>
                        <div className = 'grid grid-cols-2 gap-5 sm:gap-24 sm:mx-5'>
                           {payment_platforms.map((p, i) => (
                              <div 
                                 key = { i } 
                                 className = {`rounded-lg h-32 sm:h-40 p-1 flex flex-col items-center justify-center border-gray-300 text-sm shadow-md sm:text-base shadow-gray-500 hover:scale-105 duration-200 cursor-pointer ${selectedOnlinePayment?.id === p.id ? 'border-2 border-sky-600' : 'border'}`}
                                 onClick = {() => setSelectedOnlinePayment(p)}
                              >
                                 <img src = {p?.logo ? p?.logo : null } className = {`h-[80%] object-cover rounded-xl`} alt = '' />
                                 <p className = 'text-gray-700 pt-1' >{ p.name }</p>
                              </div>
                           ))}
                        </div>

                     </div>

                  </div>
                  }

               </div>

               <div className = 'flex justify-center pt-3  '>
                  <div className = 'w-full md:w-full flex justify-between text-gray-500'>
                     <div className = 'w-full'>
                        <p className = 'text-sm'>{ translate(170) }</p>
                        <p className = 'text-sky-500'>{ min_amount_to_bid && min_amount_to_bid.toLocaleString('en', opt) }
                        </p>
                     </div>
                     <div className = 'w-full text-right'>
                        <p className = 'text-sm'>{ translate(169) }</p>
                        <p className = 'text-sky-500'>{ available_balance ? available_balance.toLocaleString('en', opt) : '0.00' }</p>
                     </div>
                  </div>
               </div>
                     
               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex items-center w-full md:w-full'>
                     <input 
                        checked = { agree }
                        onChange = { e => setAgree(e.target.checked) } 
                        id = 'agree4' 
                        type = 'checkbox' 
                        className = 'h-4 w-4 rounded border-gray-300 accent-lime-700'
                     />
                     <label htmlFor = 'agree4' className = 'ml-2 block text-xs sm:text-sm text-gray-900 '>
                        { translate(173) } 
                        <Link to = '/termsAndConditions' target = '_blank' className = 'text-primary-100'> {` (${translate(42)}) `}</Link>
                     </label>
                  </div>
               </div>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex justify-between w-full md:w-full'>
                     <button
                        className = 'bg-stone-500 rounded w-[48%] sm:w-[40%] py-2 text-white hover:bg-stone-700'
                        onClick = { () => {
                        setBidSecurityData(initBidSecurityData)
                        setTempAmount('')
                        setSelectedFile(null)
                        setSelectedOnlinePayment(null)
                        setShowPreview('')
                        dispatch(toggleBidSecurityModal({ value: false }))
                        setShowError(false)
                        setOpenBankInfo(false)
                     }}
                     >
                        { translate(63) }
                     </button>
                     {selectedTab === 'bank-receipt' ?
                        <button 
                           className = { `${!agree ? 'bg-gray-300 text-gray-400 border border-gray-300' : 'bg-lime-600 hover:bg-lime-700 text-white '} rounded w-[48%] sm:w-[40%] py-2` }
                           title = { !agree ? translate(174) : translate(175) }
                           disabled = { !agree }
                           onClick = { () => {isValid(bidSecurityData) ? submit_bid_security() : setShowError(true)} }
                        >
                           { translate(175) }
                        </button>
                        :
                        <button 
                           className = { `${!agree || !selectedOnlinePayment ? 'bg-gray-300 text-gray-400 border border-gray-300' : 'bg-lime-600 hover:bg-lime-700 text-white '} rounded w-[48%] sm:w-[40%] py-2` }
                           title = { !agree ? translate(174) : translate(75) }
                           disabled = { !agree || !selectedOnlinePayment }
                           onClick = { submit_bidSecurity_online }
                        >
                           { translate(75) }
                        </button>
                     }
                  </div>
               </div>

            </div>
         </Modal>

         <Modal visible = { !dont_show_again_guide_prompt && showGuidePrompt && !dont_show_tuto_Prompt } customWidth = 'w-[94%] md:w-[45%]'>
            <div className = 'flex items-center justify-end w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-59 border-b border-stone-400 py-2 rounded-t-lg'>
               <div className = 'pr-4'> 
                  <FiX className = 'text-gray-800 hover:text-red-600 text-xl cursor-pointer hover:scale-105' onClick = {() => dispatch(setShowGuidePrompt({ value: false })) }/>
               </div>
            </div>
            <div className = 'flex items-center justify-center w-full text-2xl py-12 px-2 text-gray-700'>
               <div className = 'flex flex-col items-center justify-center gap-3'>
                  <p className = 'text-center font-Roboto'>New to Auction Ethiopia? Watch our introductory video to get started.</p>
                  <p className = 'text-center font-Abyssinica'>የኦክሽን ኢትዮጵያ የአጠቃቀም መማሪያ አጫጭር ቪዲዮዎችን ይመልከቱ።</p>
               </div>
            </div>

            <div className = 'flex items-center justify-center pl-4 pt-4 w-full' onClick = {(e) => e.stopPropagation()}>
               <input
                  checked = { dontShowGuidePromptPopup }
                  onChange = {e => {setDontShowGuidePromptPopup(e.target.checked)}} 
                  id = 'dontShow' 
                  type='checkbox' 
                  className = 'h-4 w-4 rounded border-gray-300 accent-lime-700 cursor-pointer'
               />
               <label htmlFor = 'dontShow' className = 'ml-2 block text-gray-900' name = 'dont-show-this-message'>
                  { translate(154) }
               </label>
            </div>
            
            <div className = 'flex justify-center w-full pt-10 pb-7 px-3'>
               <div className = 'flex justify-between w-full sm:w-[500px]'>
                  <button
                     className = 'bg-stone-500 rounded-lg w-[45%] py-2 text-white hover:bg-stone-700'
                     onClick = { handlePrompt }
                  >
                     { translate(336) }
                  </button>
                  <button 
                     className = { `bg-sky-600 hover:bg-sky-700 text-white rounded-lg w-[45%] py-2 `}
                     onClick = {() => { 
                        handlePrompt()
                        dispatch(setShowVideoModal({ value: true }))
                     }}
                  >
                     { translate(335) }
                  </button>
                  
               </div>
            </div>
         </Modal>

         <Modal visible = { show_request_refund_modal } customWidth = 'w-[94%] md:w-[45%]' >
            <div className = 'flex justify-between items-center w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-2 rounded-t-lg'>
               <div className = 'text-center text-white text-lg font-semibold pl-4 md:pl-8'>Cash Withdrawal Request Form</div>
               <div className = 'pr-4 pt-1'><Loading loading = { refund_loading } loadingStyle = 'text-sky-600 w-5 h-5'/></div>
               <div className = 'pr-4'> 
                  <FiX 
                     className = 'text-gray-500 text-2xl cursor-pointer hover:scale-105 hover:text-red-500' 
                     onClick = { () => {
                        setRequestRefundData({ amount: '' })
                        setTempAmount('')
                        setRefundError('')
                        dispatch(toggleRequestRefundModal({ value: false }))
                     }}
                  />
               </div>
            </div>

            {myProfile?.bank_info?.account_number ?
            <div className = 'w-full py-7 px-4 md:px-8'>
               <div className = 'md:flex w-full'>
                  <div className = 'w-full'>

                     <div className = 'flex w-full justify-center pb-5 pt-3 sm:pt-6'>
                        <div className = 'w-[94%] sm:w-[100%] flex justify-between'>
                           <div>
                              <h1 className = 'text-bold text-gray-500 text-sm sm:text-base md:text-xl'>{ translate(249) }</h1>
                              <p className = 'text-base sm:text-lg md:text-xl text-sky-500 text-center font-semibold'>{ balance.total_deposit ? balance.total_deposit.toLocaleString('en', opt) : '0.00'}
                                 <span className = 'text-gray-500 text-xs sm:text-sm font-normal'> { translate(4) }</span>
                              </p>
                           </div>

                           <div>
                              <h1 className = 'text-bold text-gray-500 text-sm sm:text-base md:text-xl'>{ translate(250) }</h1>
                              <p className = 'text-base sm:text-lg md:text-xl text-sky-500 text-center font-semibold'>{ balance.available_balance ? balance.available_balance.toLocaleString('en', opt) : '0.00'}
                                 <span className = 'text-gray-500 text-xs sm:text-sm font-normal'> { translate(4) }</span>
                              </p>
                           </div>
                        </div>
                     </div>

                     <div className = 'text-sm text-red-500 text-center h-8 pt-4 flex justify-center gap-2'>
                        { refundError && <IoWarningOutline className = 'text-xl' /> }
                        <div className = ''>{ refundError }</div>
                     </div>

                     <div className = 'w-full flex justify-center pt-8'>
                        {/* <label htmlFor = 'withdraw_amount' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400 '>{ translate(341) }</label> */}
                        <label>
                           <input 
                              value = { tempAmount ? tempAmount : '' } 
                              onChange = { e => {
                                 handleChange(e, 'refund')
                              }} 
                              type = 'text' 
                              id = 'withdraw_amount' 
                              className = 'block p-2 sm:p-3 w-full sm:w-[100%] text- text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:outline-none' 
                              placeholder = { translate(341) } 
                           />
                        </label>
                     </div>

                     <div className = 'text-sm text-gray-800 w-full  font-thin min-h-[40px] flex justify-center'>
                        <p className = 'w-full sm:w-[100%]'>{requestRefundDtata.amount ? toWords(requestRefundDtata.amount) : null}</p>
                     </div>
                  </div>

               </div>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex items-center w-full md:w-full'>
                     <input 
                        checked = { agree }
                        onChange = { e => setAgree(e.target.checked) } 
                        id = 'agree5' 
                        type = 'checkbox' 
                        className = 'h-4 w-4 rounded border-gray-300 accent-lime-700'
                     />
                     <label htmlFor = 'agree5' className = 'ml-2 block text-sm text-gray-900 '>
                        { translate(173) } 
                        <Link to = '/termsAndConditions' target = '_blank' className = 'text-primary-100'> {` (${translate(42)}) `}</Link>
                     </label>
                  </div>
               </div>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex justify-between w-full md:w-full'>
                     <button
                        className = 'bg-stone-500 rounded w-[40%] py-2 text-white hover:bg-stone-700'
                        onClick = { () => {
                           setRequestRefundData({ amount: '' })
                           setTempAmount('')
                           setRefundError('')
                           dispatch(toggleRequestRefundModal({ value: false }))
                        }}
                        name = 'cancel'
                     >
                        { translate(63) }
                     </button>
                     
                     <button 
                        className = { `${!requestRefundDtata.amount || !agree ? 'bg-gray-300 text-gray-400' : 'bg-lime-600 hover:bg-lime-700 text-white' } rounded w-[40%] py-2` }
                        title = { !agree ? translate(174) : translate(175) }
                        disabled = { !requestRefundDtata.amount || !agree }
                        onClick = { submitRefund }
                        name = 'submit'
                     >
                        { translate(175) }
                     </button>
                  </div>
               </div>
            </div>
            :
            <>
            <div className = 'w-full py-7 px-4 md:px-8'>
               <div className = 'pt-4 w-full text-center'>You need to submit your bank information to process your cash withdrawal request.</div>
               <div className = 'pt-4 w-full flex justify-center'>
                  <button 
                     className = {`bg-lime-600 hover:bg-lime-700 text-white rounded w-[90%] py-2 uppercase`}
                     onClick = { () => {
                        dispatch(toggleRequestRefundModal({ value: false }))
                        navigate('/myAccount')
                     } }
                     name = 'submit'
                  >
                     {/* { translate(175) } */}
                     Go To Account Setting
                  </button>
               </div>
            </div>

            <div className = 'py-4 pr-4 justify-end flex'>
               <button 
                  className = {`bg-gray-200 hover:bg-gray-400 hover:text-white rounded w-[30%] py-2 uppercase`}
                  onClick = { () => dispatch(toggleRequestRefundModal({ value: false })) }
                  name = 'close'
               >
                  {/* { translate(175) } */}
                  Close
               </button>
            </div>
            </>

            }
         </Modal>

         <Modal visible = { questionnaire.length && show_questionnaire_popup } customWidth = 'w-[94%] md:w-[45%]' >

            {/* <div className = 'flex justify-between items-center w-full bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 border-b border-stone-400 py-1 rounded-t-lg'> */}
            <div className = 'flex justify-between items-center w-full py-1 rounded-t-lg'>
               <div className = 'text-center text-white text-lg font-semibold pl-2 md:pl-4'>.</div>
               <div className = 'pr-4 pt-1'><Loading loading = { questionnaire_loading } loadingStyle = 'text-sky-600 w-5 h-5'/></div>
               <div className = 'pr-2'> 
                  <FiX 
                     className = 'text-gray-500 text-2xl cursor-pointer hover:scale-105 hover:text-red-500' 
                     onClick = { () => {
                        // setRequestRefundData({ amount: '' })
                        // setTempAmount('')
                        // setRefundError('')
                        // dispatch(toggleRequestRefundModal({ value: false }))
                        // setShowQuestionnaire(false)
                        dispatch(toggleQuestionnairePopup(false))
                        setFormData({})
                     }}
                  />
               </div>
            </div>
            {!submitted ?
               <>
                  <div className = 'flex gap-2 px-3'>
                     <div className="progress-bar pt-2 gap-1 px-1 w-[95%]">
                        {questionnaire.map((_, index) => (
                           // <div key={index} className={`progress-segment ${formData[index]?.answer !== undefined ? 'filled' : ''} ${index <= currentQuestionIndex ? 'current' : ''}`} />
                           <div key={index} className={`progress-segment rounded-lg ${index <= currentQuestionIndex && formData[index]?.answer !== undefined ? 'current' : ''}`} />
                           ))}
                     </div>
                     <div className = 'text-sm w-[5%] text-gray-700 text-center'>{currentQuestionIndex + 1 + '/' + questionnaire.length}</div>
                  </div>
            
                  {questionnaire.length && (
                     <div className = {`px-3 font-Roboto pt- min-h-[150px] question-container ${transitioning ? 'transitioning' : ''}`}>
                        <h2 className = 'font-semibold text-base sm:text-lg text-gray-600 pb-2'>{questionnaire[currentQuestionIndex].title}</h2>
                        {questionnaire[currentQuestionIndex].choice_set.map(choice => (
                           <div key={choice.id}>
                              <label htmlFor = {choice.id} className = 'flex items-center pb-1 gap-3 pl-2 sm:pl-3 text-sm sm:text-base'>
                                 <input
                                    id = {choice.id}
                                    checked = {formData[currentQuestionIndex]?.answer === choice.id}
                                    type = 'radio'
                                    name = {`answer${questionnaire[currentQuestionIndex].id}`}
                                    value = {choice.id}
                                    onChange = {(event) => handleAnswerChange(event)}
                                 /> 
                                 <span className = ''>{choice.title}</span>
                              </label>
                           </div>
                        ))}
                     </div>
                  )}

                  <div className = 'flex justify-evenly pt-6 pb-3 font-Roboto'>
                     <button className = {`bg-lime-700 hover:bg-lime-600 text-white disabled:bg-gray-200 rounded-lg py-1 px-2 w-[28%]`} onClick = {handlePrevQuestion} disabled = {currentQuestionIndex === 0}>{`<<`} &nbsp; &nbsp; Back</button>

                     {/* {formData.length ? ( */}
                     <button className = {`bg-lime-700 hover:bg-lime-600 text-white disabled:bg-gray-200 rounded-lg py-1 px-2 w-[28%]`} onClick = {handleNextQuestion} disabled = {currentQuestionIndex === questionnaire.length - 1 || !formData.length}>Next &nbsp; &nbsp; {`>>`}</button>
                     {/* ) : null } */}

                     {/* {currentQuestionIndex === questionnaire.length - 1 ? (
                        <button className = {`disabled:text-white bg-gray-400 rounded-lg py-1 px-2 w-[28%]`} onClick = {handleSubmit}>Submit</button>
                     ) : null } */}
                     {/* {formData.length === questionnaire.length ? ( */}
                        <button className = {`bg-lime-700 hover:bg-lime-600 text-white disabled:bg-gray-200 rounded-lg py-1 px-2 w-[28%]`} onClick = {handleSubmit} disabled = {formData.length !== questionnaire.length}>Submit</button>
                     {/* ) : null } */}
                  </div>
               </>
               :
               <>
                  <div className = 'flex justify-center pt-8'>
                     <h2 className = 'text-lg font-semibold text-gray-600'>Thank you!</h2>
                  </div>
                  
                  <div className = 'w-full flex justify-center pt-14 pb-6'>
                     <button 
                        className = {`bg-lime-700 hover:bg-lime-600 text-white rounded-lg py-1 px-2 w-[60%]`} 
                        // onClick = {() => {
                        //    dispatch(toggleQuestionnairePopup(false))
                        //    dispatch(resetSubmitted())
                        // }}
                        onClick = { closeModal }
                     >
                        Close
                     </button>
                  </div>
               </>
            }
            
         </Modal>

      </div>
   )
}

export default Popups;
