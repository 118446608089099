import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmActivationCode, resendCode, changePhoneNumber, resetActivationMessage, setLoginError } from '../../features/authSlice';
import { sms_icon } from '../../assets';
import toast from 'react-hot-toast';
import CustomField from '../../utils/CustomField';
import Loading from '../common/Loading';
import translate from '../../Services/translate';


const Activation = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // eslint-disable-next-line
    const { language } = useSelector(({ language }) => language);
    const { auth_loading, user, resend_status, activation_message, change_phone_loading, change_phone_status } = useSelector(({ auth }) => auth);

    const [editMode, setEditMode] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [activationCode, setActivationCode] = useState({ user: user?.id, code: '' });
    const [newPhone, setNewPhone] = useState('');
    const [tempPhone, setTempPhone] = useState();

    const checkActivationCode = () => {
        if(!activationCode.code){
            toast.error(translate(51))
            return
        }
        dispatch(confirmActivationCode(activationCode))
    }

    const handleChangePhone = () => {
        if(editMode && newPhone){
            dispatch(changePhoneNumber({ id: user.id, phone_number: newPhone}))
            if(change_phone_status === 'success' && !change_phone_loading){
                setEditMode(!editMode)
                setShowInput(!showInput)
            }
        }else{
            setEditMode(!editMode)
            setShowInput(!showInput)
        }
    }

    useEffect(() => {
        if(change_phone_status === 'success' && !change_phone_loading){
            setEditMode(!editMode)
            setShowInput(!showInput)
        }
        // eslint-disable-next-line
    }, [change_phone_status])

    const modifyPhone = num => {
        num?.length && setTempPhone(num[0])
        if(num?.length === 10){
            // eslint-disable-next-line
            if(num[0] == 0){
                return '+251' + num.slice(1)
            }
        }else if(num?.length === 9){
            // eslint-disable-next-line
            if(num[0] == 9){
                return '+251' + num
            }
        }
        else return null
    }

    const resend = () => {
        dispatch(resendCode({ user: user.id }))
    }

    useEffect(() => {
        if(activation_message === 'wrong') {
            toast.error(translate(52))
            dispatch(resetActivationMessage())
        }
        else if(activation_message === 'correct'){
            toast.success(translate(53))
            dispatch(setLoginError({ msg: '' }))
            navigate('/login')
            dispatch(resetActivationMessage())
        }
        // eslint-disable-next-line
    }, [activation_message])

    useEffect(() => {
        if(resend_status === 'success'){
            toast.success(translate(54))
        }
    }, [resend_status])


  return (
    <div className = 'pt-2 h-[100%]'>
        <div className = 'self-center pb-1 text-primary-100 font-semibold text-left pl-4 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl'>{ translate(55) }</h2>
        </div>

        <div className = 'bg-gray-100 md:pt-3 px-2 text-gray-800 pb-4 h-full'>
            <div className = 'p-2 md:flex justify-between md:px-16'>
                <div>
                    <p className = ''>
                        { translate(56) }<br/>
                        { translate(57) }
                    </p>
                    <div className = 'md:pl-10 pt-3 '>
                        <p className = 'w-full text-center md:text-start'>{ translate(58) }</p>
                        <div className = 'md:flex'>
                            <div className = 'pl-10 md:pl-0'>
                                {!editMode ?
                                    <p className = 'text-xl text-slate-400'>{ user?.phone_number }</p>
                                    :
                                    <div className = 'w-full md:w-full'>
                                        <CustomField
                                            id = 'phoneNumber'
                                            name ='phone_number'
                                            type ='text'
                                            placeholder = { translate(59) }
                                            defaultValue = { '0' + user?.phone_number.slice(4, user?.phone_number.length + 1) }
                                            rules = {{ required: translate(60)  }}
                                            onBlur = { () => console.log() }
                                            onChange = { value => setNewPhone(modifyPhone(value)) }
                                            inputStyle = 'bg-gray-100 h-7 font-sans text-gray-700 text-sm border rounded w-56'
                                            divStyle = 'text-start'
                                            // eslint-disable-next-line
                                            maxLength = { tempPhone == 9 ? 9 : 10 }
                                            label = { null }
                                        />
                                    </div>
                                }
                            </div>

                            <div className = 'md:pl-16 pl-10'>
                                <button 
                                    onClick = {() => handleChangePhone()}
                                    disabled = {editMode && !newPhone}
                                    className = {`${editMode && !newPhone ? 
                                        'bg-gray-300' : 
                                        'bg-sky-600 hover:bg-sky-700'} rounded text-white text-sm py-1 w-28 md:w-32 mt-2 cursor-pointer`} 
                                >
                                    {editMode ?
                                        <>
                                            {change_phone_loading ?
                                                <Loading loading = { change_phone_loading } loadingStyle = 'text-white text-center h-5 w-5'/>
                                                :
                                                translate(61)
                                            }
                                        </>
                                        :
                                        translate(62)
                                    }
                                </button>
                                {editMode ?
                                    <span 
                                        onClick = { () => setEditMode(false) }
                                        className = { `bg-white hover:bg-gray-400 rounded border border-gray-400 hover:text-white text-sm py-1 px-2 mt-2 ml-3 cursor-pointer` } >
                                            { translate(63) }
                                    </span>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className = 'invisible md:visible'>
                    <img src = { sms_icon } alt = '' className = 'h-0 md:h-72 text-white'/>
                </div>
            </div>

            <div className = 'pl-3 md:pl-16 md:-mt-16'>

                <p className = 'md:pt-0 pt-6'>{ translate(64) }</p>
                <div className = 'flex md:flex-none justify-between pb-4'>
                    <div className = 'md:flex'>
                        <div className = 'text-center'>
                            <CustomField
                                id = 'activation_code'
                                type = 'text'
                                rules = {{ required: translate(51) }}
                                onBlur = {() => console.log()}
                                onChange = {(value) => setActivationCode({ ...activationCode, code: value })}
                                labelStyle = 'text-gray-800 text-sm'
                                inputStyle = 'bg-gray-100 h-8 font-sans text-gray-700 text-sm rounded border'
                                divStyle = 'text-start'
                                label = { null }
                            />
                        </div>
                        <div className = 'md:pl-4'>
                            <button 
                                onClick = { checkActivationCode }  
                                className = 'rounded bg-lime-700 hover:bg-lime-600 w-40 py-1 mt-1 text-white text-sm md:text-base'>
                                    { auth_loading ? 
                                        <Loading loading = { auth_loading } loadingStyle = 'text-white text-center h-5 w-5'/>
                                        : 
                                        translate(65)
                                    }
                            </button>
                        </div>
                    </div>

                    <div className = 'visible md:invisible -mt-8 md:mt-0'>
                        <img src = { sms_icon } alt = '' className = 'h-32 md:h-0 text-white'/>
                    </div>

                </div>
            </div>

            <div className = 'text-gray-800 md:pl-16'>{ translate(66) } 
                <span onClick = { resend } className = 'text-sky-700 font-semibold hover:text-sky-800 cursor-pointer'>{ translate(67) }</span>
            </div>
        </div>
    </div>
  )
}

export default Activation;
