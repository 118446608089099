import SortByToggle from './SortByToggle';
import ViewByToggle from './ViewByToggle';
import ViewTypeToggle from './ViewTypeToggle';
import MobileControls from './MobileControls';


const ViewControls = () => (
    
    <div className = 'flex border-b border-stone-300 w-full'>

        <div className = 'hidden lg:block w-[13%]'>
            <ViewTypeToggle />
        </div>

        <div className = 'hidden lg:block w-[22%]'>
            <ViewByToggle />
        </div>

        <div className = 'hidden lg:block w-[65%]'>
            <SortByToggle />
        </div>

        <div className = 'block lg:hidden w-full'>
            <MobileControls />
        </div>

    </div>
);

export default ViewControls;