import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadCategories, selectCategory } from '../../features/categorySlice';
import { loadMyBids } from '../../features/bidSlice';
import { setCurrentPage } from '../../features/paginationSlice';
import { BsNewspaper, BsGrid } from 'react-icons/bs';
import { FcCalendar } from 'react-icons/fc';
import { logo_blur, banner_1, banner_2, banner_3, banner_4, banner_5 } from '../../assets';
import { getNews, getTenders, setCurrentNewsPage, setCurrentTendersPage } from '../../features/newsSlice';
import { BiRadioCircleMarked } from 'react-icons/bi';
import { TbArrowBigRightLines } from 'react-icons/tb';
import { setAuctionLoading } from '../../features/auctionsSlice';
import dayjs from 'dayjs';
import ReadMore from '../../utils/readMore';
import Loading from '../common/Loading';
import AuthService from '../../Services/AuthService';
import ImageSlider from './ImageSlider';
import translate from '../../Services/translate';


const HomeGrid = () => {

   const navigate = useNavigate();
   const dispatch = useDispatch();

   const Auth = AuthService.getToken();

   const { category_loading, categories } = useSelector(({ category }) => category);
   const { news_loading, news, news_page_number, news_limit, news_count, tenders_loading, tenders, tenders_page_number, tenders_limit, tenders_count } = useSelector(({ news }) => news);
   const { language } = useSelector(({ language }) => language);
   const { bids } = useSelector(({ bid }) => bid);
   
   const [toggle, setToggle] = useState();

   const banners = [
      {id: 1, url: banner_1,  redirectingLink: '/home'}, 
      {id: 2, url: banner_2,  redirectingLink: '/home'}, 
      {id: 3, url: banner_3, redirectingLink: '/home'}, 
      {id: 4, url: banner_4, redirectingLink: '/home'},
      {id: 5, url: banner_5, redirectingLink: '/home'},
   ]

   useEffect(() => {
      if(!categories.length) dispatch(loadCategories())
      if(Auth && !bids.length) dispatch(loadMyBids())
      // eslint-disable-next-line
   }, [])

   const memoizedSlider = useMemo(() => (
      banners.map(({url, redirectingLink}, idx) => (
         <div key = { idx }>
         <img src = { url } alt = '' className = 'h-full w-full' />
         {/* {idx !== 0 ? */}
         {false ?
         <button
            className = {`hidden absolute ${idx === 0 ? 'md:top-[10%] sm:top-3 right-[43%] sm:right-[41%]' : 'md:bottom-[16%] sm:bottom-3 right-[40%]'} rounded-lg border border-gray-500 bg-lime-600/60 hover:bg-lime-700 hover:scale-105 duration-200 text-white md:py-[0.5%] sm:py-1 md:px-[5%] sm:px-5 hidden sm:block shadow-md shadow-gray-400`}
               onClick={() => navigate(redirectingLink)}
         >
            View More
         </button> : null}
         </div>
      ))
      // eslint-disable-next-line
   ), [banners]);

   const handleClick = id => {
      dispatch(setAuctionLoading({ value: true }))
      navigate(`/category/auctions/${id}`)
      dispatch(selectCategory({ id }))
      dispatch(setCurrentPage({ num: 1 }))
   }

   useEffect(() => {
      dispatch(setCurrentNewsPage({ num: 1 }))
      dispatch(setCurrentTendersPage({ num: 1 }))
      if(!news.length)dispatch(getNews({ limit: news_limit, page: 1 }))
      if(!tenders.length)dispatch(getTenders({ limit: tenders_limit, page: 1 }))
      // eslint-disable-next-line
   }, [])

   const handleCurrentPage = () => {
      if(!toggle){
         dispatch(getNews({ limit: news_limit, page: news_page_number + 1 }))
         dispatch(setCurrentNewsPage({ num: news_page_number + 1 }))
      }else{
         dispatch(getTenders({ limit: tenders_limit, page: tenders_page_number + 1 }))
         dispatch(setCurrentTendersPage({ num: tenders_page_number + 1 }))
      }
   }

   const getContent = () => {
      return toggle ? tenders : news;
   }

   if(category_loading)
      return <div className = 'flex justify-center w-full pt-12'><Loading loading = { category_loading } loadingStyle = 'text-sky-600 w-7 h-7'/></div>

   return (
      <>
         <ImageSlider>
         { memoizedSlider }
         </ImageSlider>

         <div className = 'lg:flex md:px-4 bg-sky-50 md:min-h-max items-start justify-between md:pb-2'>
         <div className = 'md:px-1 w-full lg:w-[70%]'>
            {categories.length ?
               <div className = 'grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 sm:gap-6 md:gap-6 lg:gap-4 pb-3 pt-5 sm:py-0 px-3 sm:px-7 sm:pt-8 '>
               {categories.map((category, i) => (
                  <div key = { i } onClick = { () => handleClick(category.id) } className = {`${!news_loading ? '' : ''} md:hover:scale-105 duration-200 rounded shadow-md md:shadow-xl shadow-stone-400 cursor-pointer border border-stone-300 h-44 sm:h-48 md:h-64 mb-1 md:mb-0 lg:min-w-[8rem]`}>
                     <div className = 'bg-sky-50 h-1/2 rounded-t flex justify-center items-center relative'>
                     <div className = 'flex justify-end absolute right-0 top-0'><BiRadioCircleMarked /></div>
                     <img
                        src = { category.image ? category.image : logo_blur }
                        alt = { category.name }
                        className = { `${!category.image ? 'opacity-30 w-20 md:w-28' : 'w-24 md:w-32'}` }
                     />
                     </div>
                     <div className = 'bg-white rounded-b relative h-1/2 flex flex-col justify-start'>
                     <div className = { `${!category.no_of_auction ? 'hidden' : ''} flex absolute justify-end w-full -inset-3` }>
                           <div className = 'rounded-full flex pb-0.5 justify-center items-center h-5 w-5 md:h-6 md:w-6 bg-orange-400  border md:border-2 border-orange-400 text-white text-xs'>{ category.no_of_auction }</div>
                     </div>

                     <p className = {`h-[35%] text-center pt-2 font-ligh md:text-xl text-black ${language === 'Amharic' ? 'font-Abyssinica' : 'font-Roboto'}`}>{ language === 'Amharic' ? category.name_am : language === 'Afaan-Oromoo' ? category.name_or : category.name }</p>
                     <p className = {`h-[45%] text-center pt-1 md:pt-5 ${language === 'Amharic' ? 'font-Abyssinica text-gray-600' : 'font-Msoft text-gray-900'} leading-4 px-1 ${language === 'Amharic' ? 'text-sm' : 'text-lg'}`}>
                        { translate(223) }
                     </p>
                     <p className = 'h-[10%] text-gray-500 flex justify-end pr-2 pt-1'><TbArrowBigRightLines className = 'w-5 h-5'/></p>
                     </div> 
                  </div>
               ))}
               </div>
               : 
               null 
            }
         </div>

         {
            !news_loading || !tenders_loading 
            ?
            <div className = 'lg:w-[29%] md:right-0 bg-white md:h-auto text-center shadow-md shadow-stone-400 rounded-t-3xl overflow-y-auto news-scroll sm:max-h-[860px] mt-3 sm:mt-8'>
               <div className = {`text-2xl text-gray-500 py-3 ${language === 'Amharic' ? 'font-Abyssinica text-gray-600' : 'font-Roboto text-gray-900'}`}>
               { translate(224) }
               </div>

               <div className = 'flex justify-center pb-3'>
               <div className = {`switch-button rounded-[30px] h-8`} lablename = {language === 'Amharic' ? 'ጨረታዎች' : language === 'Afaan-Oromoo' ? 'Caalbaasii' : 'Tenders'}>
                  <input
                     className = 'switch-button-checkbox rounded-[30px] h-full'
                     type = 'checkbox'
                     onChange = { e => setToggle(e.target.checked) }
                     id = 'switch_button'
                  />
                  <label htmlFor = 'switch_button' className = 'switch-button-label before:rounded-[30px] h-full'>
                     <span className = 'switch-button-label-span h-8'>{ translate(225) }</span>
                  </label>
               </div>
               </div>

               <div className = 'text-primary-100 text-start pl-3 text-2xl animate-bounce font-thin w-24 ml-3 '>{ toggle ? translate(226) : translate(225) }</div>
               {getContent()?.length ?
               <>
                  {
                     getContent().map((news, i) => (
                     <div key = { i } className = 'mb-1 pt-1'>
                        {/* <div className = 'mx-2 bg-stone-200 h-10 rounded-full text-gray-700 items-center justify-between flex mb-1 px-3'> */}
                        <div className = 'mx-2 bg-sky-50 h-10 rounded-full text-gray-700 items-center justify-start flex mb-1 px-3'>
                           <div className = 'text-sky-700 flex-none pr-1'>
                           <BsNewspaper className = 'h-6 w-6'/>
                           </div>
                           <div className = 'overflow-hidden flex-grow'><p className = 'whitespace-nowrap font-Msoft text-lg font-semibold text-left w-full pl-2 scroll-title'>{ news.title }</p></div>
                           {/* <div className='overflow-hidden'><p className = ' font-Msoft text-lg font-semibold text-left w-full pl-2 '>{ news.title }</p></div> */}
                        </div>

                        <div className = 'border-gray-300 border-b  mt-2 pt-2 text-left mx-2'>
                           <div className = 'flex mb-2 pb-1'>
                           <div className = 'text-gray-400'>
                              <BsGrid className = 'h-6 w-6'/>
                           </div>

                           <div className = {`pl-3 pr-2 font-Msoft leading-5`}>
                              <ReadMore textContent = { !toggle ? news.content : news.content_preview } />
                           </div>
                           
                           </div>
                           <div className = 'flex'>
                           <div className = 'text-gray-400'>
                              <FcCalendar className = 'h-6 w-6'/>
                           </div>
                           <div className = {`text-gray-400 pl-3 ${language === 'Amharic' ? 'font-Abyssinica' : 'font-Roboto'}`}>{ dayjs(news.created_at).format('MMM D, YYYY') }</div>
                           </div>
                        </div>
                     </div>
                     ))
                  } 
                  {
                     !tenders_loading && toggle && (tenders_page_number >= Math.ceil(tenders_count / tenders_limit))
                     ?
                     <div className = 'py-1 w-full bg-stone-400 text-white'>{ translate(227) }</div>
                     :
                     !news_loading && !toggle && (news_page_number >= Math.ceil(news_count / news_limit))
                     ?
                     <div className = 'py-1 w-full bg-stone-400 text-white'>{ translate(228) }</div>
                     :
                     <button onClick = { () => handleCurrentPage() } className = 'h-8 py-1 w-full bg-stone-400 text-white hover:bg-stone-500'>
                        {
                           news_loading || tenders_loading
                           ?
                           <Loading loading = { news_loading || tenders_loading } loadingStyle = 'text-white w-5 h-5'/>
                           :
                           toggle ? translate(229) : translate(230)
                        }
                     </button>
                  }
               </>
               :
               <div className = 'text-gray-400 italic text-lg py-6'>{ toggle ? translate(231) : translate(232) }</div>
               }
            </div>
            :
            <Loading loading = { news_loading || tenders_loading } loadingStyle = 'text-white w-5 h-5'/>
         }
         </div>
      </>
   );
}

export default HomeGrid;