import { useState, useEffect } from 'react';
import { getRemainingTimeUntilMsTimestamp } from './timerUtils';
import { LuTimerOff } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { getServerTime, setNowUtcTime } from '../features/auctionsSlice';
import dayjs from 'dayjs';
import translate from '../Services/translate';

const defaultRemainingTime = {
   seconds: null,
   minutes: null,
   hours: null,
   days: null,
   isAboutToEnd: false,
}

const names = ['days', 'hours', 'minutes', 'seconds']

const CountdownTimer = ({ timestampMs, view }) => {

   const dispatch = useDispatch()
   const { currenTimeDiff } = useSelector(({ auction }) => auction);
   const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

   const isSystemTimeChanged = () => {
      const currentTime = new Date();
      const previousTime = isSystemTimeChanged.previousTime || currentTime;
      const timeDiff = currentTime - previousTime;
      isSystemTimeChanged.previousTime = currentTime;
      return Math.abs(timeDiff) > 2000;
   };

   useEffect(() => {
      const intervalId = setInterval(() => {

         if(isSystemTimeChanged()) {
               dispatch(getServerTime())
         }

         const utcTime = dayjs().add(currenTimeDiff, 'milliseconds').unix() * 1000;
         dispatch(setNowUtcTime(utcTime))
         updateRemainingTime(timestampMs, utcTime);
      }, 1000);

      return () => clearInterval(intervalId);
      // eslint-disable-next-line
   },[timestampMs, currenTimeDiff]);

   function updateRemainingTime(countdown, nowUtc) {
      setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown, nowUtc));
   }

   const { days, hours, minutes, seconds, isAboutToEnd } = remainingTime

   const displayTime = (name) => {
      switch (name) {
         case 'days':
               if(!Number(days))
                  return [null, null]
               return [days, translate(21)]
         case 'hours':
               if((!Number(days)) && (!Number(hours)))
                  return [null, null]
               return [hours, translate(22)]
         case 'minutes':
               if((!Number(days)) && (!Number(hours)) && (!Number(minutes)))
                  return [null, null]
               return [minutes, translate(23)]
         case 'seconds':
               if((!Number(days)) && (!Number(hours)) && (!Number(minutes)) && (!Number(seconds)))
                  return [null, null]
               return [seconds, translate(24)]
         default:
               return [null, null]
      }
   }

   if((days === '00' && hours === '00' && minutes === '00' && seconds === '00') && (timestampMs + 2500 + 5200 < dayjs().add(currenTimeDiff, 'milliseconds').unix() * 1000) )
      return (
         <div className = 'text-center text-lg font-semibold h-full w-full text-red-300 flex justify-center gap-2'>
            <LuTimerOff className = 'text-lg mt-1'/>
            <div>{ translate(33) }</div>
         </div>
      )
      
   return(
      <>
         <div className = { `text-center w-full flex gap-1 justify-center ${isAboutToEnd ? (view === 'monitor' ? 'text-orange-500' : 'animate-bounce-horizontal text-orange-500') : ''}` }>
            {
               names.map((name, i) => (
                  <div key = { i } className=''>
                     <span className = { `${view === 'monitor' ? 'text-2xl sm:text-5xl' : 'text-base'} font-semibold w-[3ch] pl-2` }>{ displayTime(name)[0] }</span>
                     <span className = { `${view === 'monitor' ? 'text-sm':'text-xs'} pl-0.5` }>{ displayTime(name)[1] }</span>
                  </div>
               ))
            }

         </div>

         {(days === '0' || days === null) && (Number(hours) === 0 || hours === null) && (Number(minutes) === 0 || minutes === null) && Number(seconds) <= 10 &&
            <>
               <div className = {`absolute inset-0 mx-auto my-auto flex items-center justify-center ${view === 'grid' ? ' h-[5rem] pb-9' : 'h-[3rem] pb-3'}`}>
                  <div className = {`${view === 'grid' ? 'text-[5rem]' : 'text-[2.5rem]'} font-bold text-green-700 animate-pingme`}>{ seconds && seconds < 10 ? seconds.slice(1) : seconds }</div>
               </div>
            </>
         }

      </>
   );
}

export default CountdownTimer;