import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import myModel from '../Services/api';
import AuthService from '../Services/AuthService';

const initialState = {
   category_category_loading: false,
   categories: [],
   selectedCategory: null,
}

export const loadCategories = createAsyncThunk(
   'category/loadCategories',
   async (arg, { rejectWithValue }) => {
      try {
         const result = await myModel.find('category')
         return result
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const categorySlice = createSlice({
   name: 'category',
   initialState,
   reducers: {
      selectCategory: (state, { payload: { id } }) => {
         let selected = state.categories.find(c => c.id === id)
         state.selectedCategory = selected
         AuthService.setSelectedCategory(selected)
      }
   },
   extraReducers: ({ addCase }) => {
      addCase(loadCategories.pending, (state) => {
         state.category_loading = true
      })
      addCase(loadCategories.fulfilled, (state, { payload }) => {
         if(payload){
            let list = payload.sort((a, b) => a.order - b.order)
            state.categories = list
            // state.categories = payload
            state.category_loading = false
         }
      })
      addCase(loadCategories.rejected, (state) => {
         state.category_loading = false
      })
   }
});

export const { selectCategory } = categorySlice.actions;
export default categorySlice.reducer;