import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMobViewByToggle, toggleOrderBy, toggleSideCategoryBar, toggleSortBy, toggleViewBy } from '../../features/viewControlsSlice';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import SideCategoryBar from '../sideBar/SideCategoryBar';
import AuthService from '../../Services/AuthService';
import translate from '../../Services/translate';

const MobileControls = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const Auth = AuthService.getToken();
   
   const { showSideCategoryBar, mobViewByToggle } = useSelector(({ viewControls }) => viewControls);
   const { selectedCategory } = useSelector(({ category }) => category);
   const { order } = useSelector(({ viewControls }) => viewControls)
   const { language } = useSelector(({ language }) => language);


      useEffect(() => {
         if(mobViewByToggle){
         if(Auth?.token){
            dispatch(toggleViewBy('my'))
         }else{
            navigate('/login')
         }
         }else{
         dispatch(toggleViewBy('all'))
         }
         // eslint-disable-next-line
      }, [mobViewByToggle])


   return (
      // <div className = 'py-1 pr-2 relative w-full flex justify-evenly md:justify-end gap-1 md:gap-10'>
      <div className = 'py-1 relative w-full flex justify-between px-3 gap-1 sm:gap-3'>
         <div className = ' flex justify-center py-1 w-[31%]'>
            <div className = 'mob-switch-button rounded-[30px] h-8' mylist = { translate(305) }>
               <input 
               id = 'switch_button2'
               checked = { mobViewByToggle }
               onChange = { e => dispatch(toggleMobViewByToggle({ value: e.target.checked })) } className = 'mob-switch-button-checkbox bg-gradient-to-r from-lime-700 to-sky-400 rounded-[30px] h-full' type = 'checkbox'/>
               <label className = 'mob-switch-button-label before:rounded-[30px] h-full before:bg-gradient-to-r from-lime-700 via-sky-500 to-sky-600' htmlFor = 'switch_button2'>
                  <span className = 'mob-switch-button-label-span h-8 text-[14px]'>{ translate(304) }</span>
               </label>
            </div>
         </div>

         <div className = 'w-[69%] bg-lime-600 rounde'>
            <div className = 'flex w-full h-full items-center'>

               <div className = 'w-[45%] border-r-2 border-white h-full flex items-center'>
                  <button 
                     onClick = { () => dispatch(toggleSideCategoryBar({ value: !showSideCategoryBar })) } 
                     className = 'w-[80px w-full text-white py-1 text-xs'
                  >
                     {
                        selectedCategory
                        ? 
                        <div className = 'flex justify-between items-center px-2 pb-p'>
                        <p className = 'truncate'>{ language === 'Amharic' ? selectedCategory.name_am : language === 'Afaan-Oromoo' ? selectedCategory.name_or : selectedCategory.name }</p>
                        <p className = 'font-semibold'>{'⌵'}</p>
                        </div>
                        :
                        <div className = 'flex justify-center px-2'>
                        <p className = 'truncate'>{ translate(306) }</p>
                        <p className = 'font-semibold'>{'⌵'}</p>
                        </div>
                     }
                  </button>
               </div>

            

               <div className = 'w-[40%] border-r-2 border-white h-full flex items-center'>
                  <select id = 'toggle_sort_by' defaultValue = {''} onChange = { e => dispatch(toggleSortBy(e.target.value)) } className = 'w-full bg-transparent py-1 text-xs text-white focus:outline-none  truncate '>
                     <option value = 'date'>{ translate(25) }</option>
                     <option value = 'bids' className = 'truncate'>{ translate(17) }</option>
                     <option value = 'current_price' className = 'truncate'>{ translate(3) }</option>
                     <option value = 'initial_price' className = 'truncate'>{ translate(19) }</option>
                  </select>
               </div>
            
               <div className = 'w-[15%] text-white'>
               {
                  order === 'dsc'
                  ?
                  <div className='py-1 w-full' onClick = { () => dispatch(toggleOrderBy('asc'))}>
                     <TiArrowSortedDown className = 'borde rounde text-white h-[30px] w-full'/>
                  </div>
                  :
                  <div className='py-1 w-full' onClick = { () => dispatch(toggleOrderBy('dsc'))}>
                     <TiArrowSortedUp className = 'borde rounde text-white h-[30px] w-full'/>
                  </div>
               }
               </div>
            </div>
         </div>

         <SideCategoryBar />

      </div>
   )
}

export default MobileControls;