import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  // const { auctions } = useSelector(({ auction }) => auction);
  const { currentPage } = useSelector(({ pagination }) => pagination);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, currentPage]);

  return null;
}

export default ScrollToTop;