import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkUsername, resetCheckUsername, register, setUsernameError, resetRegisterError, resetRegisterStatus } from '../../features/authSlice';
import { setLanguage } from '../../features/languageSlice';
import { BsExclamationCircle, BsEye, BsEyeSlash } from 'react-icons/bs';
import { GiCheckMark } from 'react-icons/gi';
import { recaptcha_site_key } from '../../Services/apiConstants';
import { user_icon } from '../../assets';
import { toast } from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import translate from '../../Services/translate';
import CustomField from '../../utils/CustomField';
import Loading from '../common/Loading';


const Register = () => {

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const initUser = {
      username: '', 
      first_name: '', 
      last_name: '', 
      email: '', 
      phone_number: '', 
      password: '', 
      gender: 'MALE'
   }

   const { language } = useSelector(({ language }) => language);
   const { auth_loading, register_status, username_status, isUsernameAvailable, register_error } = useSelector ( ({ auth }) => auth );

   const [user, setUser] = useState(initUser);
   const [tempPhone, setTempPhone] = useState();
   const [comparePassword, setComparePassword] = useState({ password_1: '', password_2: '' });
   const [captchaPass, setCaptchaPass] = useState(false);
   const [tempUsername, setTempUsername] = useState('');
   const [weakPassword, setWeakPassword] = useState(false);
   const [showPassword, setShowPassword] = useState(false);

   useEffect(() => {
      if(register_status === 'success'){
         setUser(initUser)
         dispatch(resetRegisterStatus())
         navigate('/account-activation')
      }
      // eslint-disable-next-line
   }, [register_status])

   const modifyPhone = num => {
      num?.length && setTempPhone(num[0])
      if(num?.length === 10){
         // eslint-disable-next-line
         if(num[0] == 0){
               return '+251' + num.slice(1)
         }
      }else if(num?.length === 9){
         // eslint-disable-next-line
         if(num[0] == 9){
               return '+251' + num
         }
      }
      return null
   }

   const checkPasswordMatch = () => {
      if(comparePassword.password_2 && comparePassword.password_2 !== comparePassword.password_1)
         return true
      return false
   }

   const handleRegister = () => {
      // if(!comparePassword.password_1.match('^(?=.*[A-Z])(?=.*\d).{6,}$')){
      //     return setWeakPassword(true)
      // }
      dispatch(register(user))
   }

   // const checkAvailability = () => {
   //     if(tempUsername){
   //         if(tempUsername.length < 5){                    
   //             dispatch(setUsernameError('5 or more characters'))
   //             return;
   //         }
   //         dispatch(checkUsername({ tempUsername }))
   //     }
   //     else{dispatch(resetCheckUsername())}
   // }

   const checkAvailability = (username = null) => {
      if(username){
         if(username.length < 5){                    
            dispatch(setUsernameError(translate(94)))
            return;
         }
         dispatch(checkUsername({ username }))
      }
      else{dispatch(resetCheckUsername())}
   }

   const debounce = (cb) => {
      let timer;
      return function (...args) {
         const context = this
         if(timer) clearTimeout(timer)
         timer = setTimeout(() => {
               timer = null
               cb.apply(context, args)
         }, 500)
      }
   }

   // eslint-disable-next-line
   const check = useCallback(debounce(checkAvailability), [])

   useEffect(() => {
      if(comparePassword.password_1.length < 6){
         setWeakPassword(true)
      }else{
         setWeakPassword(false)
      }
      if(comparePassword.password_1 === comparePassword.password_2){
         setUser({ ...user, password: comparePassword.password_1 })
         return
      }
      setUser({ ...user, password: '' })
      // eslint-disable-next-line
   }, [comparePassword.password_1, comparePassword.password_2])


   useEffect(() => {
      if(isUsernameAvailable && tempUsername.length >= 5) {
         setUser({ ...user, username: tempUsername })
      }else{
         setUser({ ...user, username: '' })
      }
      // eslint-disable-next-line
   }, [tempUsername, isUsernameAvailable])


   return (
      <div className = 'w-full pb-4'>
         <div className = 'text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl'>{ translate(95) }</h2>
         </div>

         <div className = 'flex justify-center pt-1 '>
            <img src = { user_icon } alt = 'user icon' className = 'h-12 md:h-24' />
         </div>

         <div className = 'flex justify-center'>
            <div className = 'md:flex w-[90%] md:w-[70%]'>
               <div className = 'w-full md:w-1/2 md:pr-4'>
                  <h2 className = 'text-lg text-gray-600 pb-3 font-semibold text-center'>{ translate(96) }</h2>

                  <div className = 'w-full'>
                     <CustomField
                        id = 'first_name'
                        name = 'first_name'
                        type = 'text'
                        placeholder = { translate(97) }
                        defaultValue = { user.first_name }
                        rules = {{ required: translate(98) }}
                        onBlur = {() => console.log()}
                        onChange = {value => setUser({ ...user, first_name: value })}
                        inputStyle = 'bg-white h-10 font-Roboto text-gray-700 text-xs sm:text-sm border rounded-md'
                        label = { null }
                     />
                  </div>

                  <div className = 'w-full -mt-2 md:mt-0'>
                     <CustomField
                        id = 'last_name'
                        name = 'last_name'
                        type = 'text'
                        placeholder = { translate(99) }
                        defaultValue = { user.last_name }
                        rules = {{ required: translate(100)}}
                        onBlur = {() => console.log()}
                        onChange = {value => setUser({ ...user, last_name: value })}
                        inputStyle = 'bg-white h-10 font-Roboto text-gray-700 text-xs sm:text-sm border rounded-md'
                        label = { null }
                     />
                  </div>

                  <div className = 'w-full -mt-2 md:mt-0 relative'>
                     <CustomField
                        id = 'email'
                        name = 'email'
                        type = 'email'
                        placeholder = { translate(101) }
                        defaultValue = { user.email }
                        onBlur = {() => console.log()}
                        onChange = {value => {
                           setUser({ ...user, email: value })
                           dispatch(resetRegisterError({ field: 'email' })) 
                        }}
                        inputStyle = 'bg-white h-10 font-Roboto text-gray-700 text-xs sm:text-sm border rounded-md'
                        label = { null }
                     />
                     <div className={ `flex pr-1 text-end text-[0.7rem] absolute top-[35px] md:top-10 right-0 text-red-600`}>
                        { register_error?.email && translate(102) }
                     </div>
                  </div>

                  <div className = 'sm:flex w-full gap-3'>

                  <div className = 'w-full sm:w-[70%] -mt-2 md:mt-0 relative'>
                     <CustomField
                        id = 'phone_number'
                        name = 'phone_number'
                        type = 'text'
                        placeholder = { translate(103) + ` ${translate(104)} 0912345678` }
                        defaultValue = { user.phone_number }
                        rules={{ required: translate(60) }}
                        onBlur = {() => console.log()}
                        onChange = {value => {
                           setUser({ ...user, phone_number: modifyPhone(value) }) 
                           dispatch(resetRegisterError({ field: 'phone_number' }))
                        }}
                        inputStyle = 'bg-white h-10 font-Roboto text-gray-700 text-xs sm:text-sm border rounded-md'
                        // eslint-disable-next-line
                        maxLength = { tempPhone == 9 ? 9 : 10 }
                        label = { null }
                     />
                     <div className={ `flex pr-1 text-end text-[0.7rem] absolute top-[35px] md:top-10 right-0 text-red-600`}>
                        { register_error?.phone_number && translate(106) }
                     </div>
                  </div>

                  <div className = 'w-full sm:w-[30%] -mt-2'>
                     <CustomField
                        id = 'gender'
                        name = 'gender'
                        type = 'select'
                        options = {[{ val: 'MALE', prev: translate(285) }, { val: 'FEMALE', prev: translate(286) }]}
                        valueKey = 'val'
                        labelKey = 'prev'
                        defaultValue = { user.gender }
                        onBlur = {() => console.log()}
                        onChange = {value => setUser({ ...user, gender: value })}
                        selectStyle = 'bg-white h-10 font-sans text-gray-700 text-xs sm:text-sm border rounded-md'
                        label = { null }
                     />
                  </div>
                  </div>
                  
               </div>
               
               <div className = 'w-full md:w-1/2 md:pl-4'>
                  <h2 className = 'text-lg text-gray-600  pb-3 font-semibold text-center'>{ translate(107) }</h2>
                  <div className = 'w-full relative md:mb-3'>
                     <div className = 'pt-1'>
                        <label>
                           <input 
                              defaultValue = { '' } 
                              onChange = {e => {
                                 check(e.target.value)
                                 setTempUsername(e.target.value)
                                 setUser({...user, username: e.target.value})
                              }} 
                              name = 'username' 
                              type = 'text' 
                              autoComplete = 'new-username' 
                              required 
                              className = 'relative block w-full appearance-none rounded-md border border-gray-300 px-3 h-10 text-gray-900 focus:border-gray-400 focus:outline-none focus:ring-indigo-400 text-xs sm:text-sm' 
                              placeholder = { translate(82) } 
                           />
                        </label>

                        {username_status === 'checking' ? 
                           <Loading 
                              loading = { username_status === 'checking' } 
                              loadingStyle = 'h-3 md:h-4 w-3 md:w-4 text-sky-600 absolute inset-y-0 right-0 flex items-center mt-4 mr-2' 
                           /> 
                           :
                           username_status === 'Username Available'
                           ? 
                           <span className = 'absolute inset-y-0 right-0 top-0 pt-3 pr-2'>
                              <GiCheckMark className = 'text-lg font-semibold text-green-600' />
                           </span>
                           : 
                           null 
                        }
                        
                        
                        {/* <div className = 'text-end flex justify-end w-full -mt-6 h-2'> */}
                        <div className = 'text-end flex justify-end w-full h-2'>
                           <div 
                              className = { `flex md:ml-64 pr-1 text-end text-[0.7rem] 
                              ${username_status === 'Username Available' ? 'text-green-600' : username_status === 'Username is Taken' ? 'text-red-500' : ''}` } 
                           >
                              { username_status === 'Username is Taken' ? <div className = 'pr-2 pt-1'><BsExclamationCircle className = 'text-base pb-1'/></div>: null } 
                              { username_status !== 'Username Available' ? 
                                 <span className = 'text-red-500'>
                                    { username_status === 'Username is Taken' ? translate(109) : username_status === 'checking' ? translate(110) : username_status === 'Username Available' ? translate(108) : username_status }
                                 </span>
                                 :
                                 null
                              }
                           </div>
                        </div>

                     </div>
                        
                  </div>

                  <div className = 'w-full pt-2 md:pt-1 relative'>
                     <CustomField
                        id = '_password'
                        name = 'password'
                        type = { showPassword ? 'text' : 'password' }
                        placeholder = { translate(83) }
                        defaultValue = { user.password }
                        rules = {{ required: translate(111) }}
                        onBlur = { () => console.log() }
                        onChange = {value => setComparePassword({ ...comparePassword, password_1: value })}
                        inputStyle = 'bg-white h-10 font-sans text-gray-700 text-xs sm:text-sm border rounded-md'
                        minLength = '6'
                        autoComplete = 'new-password'
                        label = { null }
                     />
                     
                     <button 
                        onClick = { () => setShowPassword(!showPassword) }
                        className = 'flex flex-row bg-transparent rounded-md items-center justify-center absolute top-[22px] sm:top-4 right-6'>
                        {showPassword ? 
                           <BsEyeSlash className = 'text-gray-500 text-xl sm:text-2xl'/> 
                           : 
                           <BsEye className = 'text-gray-500 text-xl sm:text-2xl'/>
                        }
                     </button>

                     {!weakPassword && comparePassword.password_1.length >= 6 ?
                        <div className = 'absolute right-1 top-4 pr-2'>
                           <GiCheckMark className = 'text-lg font-semibold text-green-600' />
                        </div>
                        :
                        null
                     }

                     <div className = 'text-start flex justify-start w-full h-2 absolute top-[50px] sm:top-[48px] left-1'>
                        <p className = 'text-[0.7rem] text-gray-700'>{ translate(112) }</p>
                     </div>
                  </div>
                  <div className = 'w-full'>
                     <CustomField
                        id = '_confirmPassword'
                        name = 'confirmPassword'
                        type = 'password'
                        placeholder = { translate(113) }
                        defaultValue = {''}
                        rules = {{ required: translate(114) }}
                        onBlur = { () => console.log() }
                        onChange = {value => setComparePassword({ ...comparePassword, password_2: value })}
                        inputStyle = 'bg-white h-10 sm:h-10 font-sans text-gray-700 text-xs sm:text-sm border rounded-md'
                        notMatch = { checkPasswordMatch() }
                        label = { null }
                     />
                  </div>

                  <div className = 'flex flex-row w-full md:w-full pl-1 sm:pt-2'>
                     <div className = 'text- font-sans text-gray-600 md:pr-5 w-1/2 sm:w-1/3'>{ translate(116) }</div>
                     <div className = 'md:flex justify-start text-left pb-2 w-1/2 sm:w-2/3'>
                        <label htmlFor = '_language1' className = 'flex items-center md:pl-0 pb-1'>
                           <input
                              id = '_language1'
                              name = 'language'
                              type = 'radio'
                              value = 'English'
                              defaultChecked = { language === 'English' }
                              onChange = {e => dispatch(setLanguage({ lang: e.target.value }))}
                              className = 'form-checkbox text-green-500 rounded-full md:ml-0 ml-2 cursor-pointer'
                           />
                           <span className = 'text-gray-700 ml-1 cursor-pointer'>English</span>
                        </label>
                        <label htmlFor = '_language2' className = 'flex items-center md:ml-3 pb-1'>
                           <input
                              id = '_language2'
                              name = 'language'
                              type = 'radio'
                              value = 'Amharic'
                              defaultChecked = { language === 'Amharic' }
                              onChange = {e => dispatch(setLanguage({ lang: e.target.value }))}
                              className = 'form-checkbox text-green-500 rounded-full ml-2 cursor-pointer'
                           />
                           <span className = 'text-gray-700 ml-1 cursor-pointer'>አማርኛ</span>
                        </label>
                        <label htmlFor = '_language3' className = 'flex items-center md:ml-3 pb-1'>
                           <input
                              id = '_language3'
                              name = 'language'
                              type = 'radio'
                              value = 'Afaan-Oromoo'
                              defaultChecked = {language === 'Afaan-Oromoo'}
                              onChange = {e => dispatch(setLanguage({ lang: e.target.value }))}
                              className = 'form-checkbox text-green-500 rounded-full ml-2 cursor-pointer'
                           />
                           <span className = 'text-gray-700 ml-1 cursor-pointer'>Afaan-Oromoo</span>
                        </label>
                     </div>

                  </div>

               </div>

            </div>
         </div>

         <div className = 'py-1 w-full'>
            <div className = 'flex w-full justify-center'>
               <div>
                  <ReCAPTCHA
                     sitekey = { recaptcha_site_key }
                     onChange = {value => value ? setCaptchaPass(true) : setCaptchaPass(false)}
                     onErrored = {() => toast.error(translate(117), { duration: 4000, id: 'copy' })}
                     onExpired = {() => { 
                        toast.error(translate(118), { duration: 4000, id: 'copy' })
                        setCaptchaPass(false) 
                     }}
                  />
               </div>
            </div>

            <div className = 'text-xs flex justify-center pt-3 w-full px-2'>
               <div className = 'text-center'>
                  { translate(119)} 
                  &nbsp; 
                  <span className = 'text-sky-600 cursor-pointer hover:text-sky-700' onClick = { () => navigate('/termsAndConditions') }>
                     { translate(42) }
                     { translate(212) }
                  </span>
               </div>
            </div>

            <div className = 'flex justify-center pt-4'>
               <button 
                  onClick = { handleRegister } 
                  disabled = { !user.username || user.username.length < 5 || !user.first_name || !user.last_name || !user.password || !user.phone_number || !captchaPass }
                  className = { `${!user.username || user.username.length < 5  || !user.first_name || !user.last_name || !user.password || !user.phone_number || !captchaPass ? 
                  'bg-gray-300 text-gray-400 border border-gray-400' : 
                  'bg-secondary-50 hover:bg-sky-500 text-white'} w-72 sm:w-[30%]  rounded-full border drop-shadow py-2` }>
                     {auth_loading ? 
                        <Loading loading = { auth_loading } loadingStyle = 'h-5 w-5 text-white' />
                        :  
                        translate(120) 
                     }
               </button>
            </div>

            <div className = 'text-center text-sm pt-2'>
               { translate(121) }
               <span 
                  onClick = {() => navigate('/login')} 
                  className = 'text-sky-600 font-semibold hover:text-sky-500 cursor-pointer'
               >
                  { translate(86) }
               </span>
            </div>

         </div>
      </div>
   )
}

export default Register;
