import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CountdownTimer, getCharLength, opt } from '../../utils';
import { hit_gavel, image_placeholder, sealed_icon, top_bidder_cup_1, zig_chereta } from '../../assets';
import { selectCategory } from '../../features/categorySlice';
import dayjs from 'dayjs';
import Loading from '../common/Loading';
import translate from '../../Services/translate';


const BidGrid = ({ showNotifierPopup, bidStatuses, canSeeTopBidder }) => {

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const { language } = useSelector(({ language }) => language);
   const { bids } = useSelector(({ bid }) => bid);
   const {  updatedBidId } = useSelector(({ auction }) => auction);


   return (
      <div className = 'pt-2 pb-1'>
         {
            bids.length && bidStatuses.length
            ?
            <div className = 'sm:grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 px-2 md:px-0 mb-2 md:mb-0'>
               {
                  bidStatuses.map(({ bid, current_price, isTopBidder, isAuctionEndingSoon, processing, hitGavel, ended }) => (
                     <div key = { bid.auction.id }  className = 'text-center mt-2 md:mt-0 rounded-lg shadow-lg shadow-stone-400 border border-stone-400 sm:hover:scale-105 duration-200'>
                        <div className = {`${ended ? 'bg-black/10' : 'bg-white'} text-gray-600 h-72 flex-col flex justify-center pt-2 rounded-lg relative`}>
                           <div className = 'px-2 flex relative h-[32%]'>
                              <div className = 'w-28 h-[67.2px] flex flex-col bg-black/50'>
                                 <img 
                                    src = { bid?.auction?.product?.thumbnail ? bid.auction.product.thumbnail : image_placeholder } 
                                    alt = { bid.auction.product.name } 
                                    // className = 'w-full h-[89%] object-cover rounded cursor-pointer' 
                                    className = 'h-full w-full object-contain rounded cursor-pointer' 
                                    onClick = { () => { dispatch(selectCategory({ id: bid.auction.product.category })); navigate(`/auction/${bid.auction.id}`) }}
                                 />
                              </div>
                              <div className = 'w-[55%] flex flex-col gap-1 justify-between pb-2 pl-2 relative'>
                                 <p 
                                    className = 'text-base text-left hover:text-sky-600 text-black font-Roboto flex-none cursor-pointer'
                                    onClick = { () => {
                                       dispatch(selectCategory({ id: bid.auction.product.category })) 
                                       navigate(`/auction/${bid.auction.id}`) 
                                    }} 
                                 >
                                    { bid.auction.product.name }
                                 </p>

                                 <p className = {`text-xs text-start overflow-y-auto flex-grow ${ended ? 'summary-ended' : 'summary'}`}>
                                    {bid.auction.product.summary ?
                                       <>
                                          <span className = ''>{bid.auction.product.summary.slice(0, getCharLength())}</span>
                                          <span 
                                             className = 'text-sky-600 cursor-pointer hover:text-sky-700 pl-1'
                                             onClick = { () => { dispatch(selectCategory({ id: bid.auction.product.category })); navigate(`/auction/${bid.auction.id}`) }} 
                                          >
                                             { translate(50) }
                                          </span>
                                       </> 
                                       : <span>---</span>
                                    }
                                 </p>

                                 {current_price && !bid?.auction?.is_closed ?
                                    <div className = {`absolute top-0 right-0 left-0 h-auto sm:h-14 flex items-center sm:justify-center ${showNotifierPopup && (updatedBidId === bid?.auction?.id) ? 'show-notifier-popup' : 'hide-notifier-popup'}`}>
                                       <div className = 'flex text-white h-full rounded-lg border border-black w-[71%] sm:w-[90%] justify-center items-center notifier-popup  px-3 bg-sky-800'>
                                          <div>
                                             { translate(266) } 
                                             <span className = 'text-base sm:text-lg font-bold'> {current_price && current_price?.toLocaleString('en', opt)} </span>
                                             { translate(267) }
                                          </div>
                                       </div>
                                    </div>
                                    : null
                                 }
                              </div>

                              {isTopBidder && !bid?.auction?.is_closed ?
                                 <div 
                                    title = {`${!ended ? translate(12) : translate(13)}`} 
                                    className = {`absolute ${ended ? 'right-1' : language === 'English' ? 'right-7' : language === 'Amharic' ? 'right-5' : 'right-2'} -inset-y-1 `}>
                                       <div className = 'flex justify-center'>
                                          <img src = { top_bidder_cup_1 } alt = '' className = {`h-10 ${!ended ? 'py-1 top-bidder' : ''} `}/>
                                       </div>
                                       <p className = {`${!ended ? 'text-yellow-600': 'text-lime-700'} text-xs`}>
                                          { !ended ? translate(45) : translate(46) }
                                       </p>
                                 </div>
                                 : null
                              }

                              {isAuctionEndingSoon ?
                                 <div 
                                    title = { translate(29) }
                                    className = { `blob absolute right-1` }>
                                 </div>
                                 : null
                              }
                           </div>

                           <div className = 'h-[9%] flex justify-evenly items-center pb-1 border-b border-gray-400'>
                              <div className = 'text-xs'>{translate(17)} &nbsp; - &nbsp; <span className = 'text-base font-semibold'> { bid.auction.no_of_bids }</span></div>
                              <div className = 'text-sky-600'>|</div>
                              <div className = 'text-xs'>{translate(18)} &nbsp; - &nbsp; <span className = 'text-base font-semibold'> { bid.auction.lot_number }</span></div>
                           </div>

                           {bid.auction.is_closed ?
                              // <div className = 'border-b border-gray-400 flex h-[30%] items-center justify-center font-semibold gap-3'>
                              //    <img src = { language === 'Amharic' ? zig_chereta : language === 'Afaan-Oromoo' ? sealed_icon : sealed_icon } alt = "" className = 'h-10 w-10' />
                              //    <div className = 'text-red-400 text-lg'>{translate(14)}</div>
                              // </div>

                              <div className = 'border-b border-gray-400 flex flex-col h-[30%] items-center justify-between'>
                                 <div className = 'flex items-center justify-center font-semibold gap-3'>
                                    <img src = { language === 'Amharic' ? zig_chereta : language === 'Afaan-Oromoo' ? sealed_icon : sealed_icon } alt = '' className = 'h-10 w-10' />
                                    <div className = 'text-red-400 text-lg'>{translate(14)}</div>
                                 </div>

                                 <div className = 'flex flex-col justify-between border-t border-gray-400 w-full'>
                                    <p className = 'text-xs text-left pl-2 truncate'>{ translate(19) }</p>
                                    <p className = 'text-sm'>
                                       <span className = 'text-lg font-semibold font-Roboto'>{ bid.auction.initial_price.toLocaleString('en', opt) }</span>
                                       { translate(4) }
                                    </p>
                                 </div>
                              </div>
                              :
                              <>
                                 <div className = 'border-b border-gray-400 flex h-[15%]'>
                                    <div className = 'border-r border-gray-400 flex flex-col justify-between w-[60%] bg-fourth-50 text-white'>
                                       <p className = 'text-xs text-left pl-2 truncate'>{ translate(3) }</p>
                                       {canSeeTopBidder() ?
                                          <p className = 'text-sm'>
                                             <span className = 'text-base lg:text-lg font-semibold'>{ current_price && current_price.toLocaleString('en', opt) }</span> 
                                             { translate(4) }
                                          </p>
                                          :
                                          <p title = 'Click to go to Subscription page' className = 'text-base font-semibold text-green-300 hover:text-green-400  cursor-pointer' onClick = { () => navigate('/subscription')}>{ translate(343) }</p>
                                       }

                                    </div>
                                    <div className = 'flex flex-col justify-between w-[40%]'>
                                       <p className = 'text-xs text-left pl-2'>{ translate(15) }</p>
                                       <p className = 'text-sm'>
                                          <span className = 'text-base lg:text-lg font-semibold'>{ bid.auction.min_increment.toLocaleString('en', opt) }</span> 
                                          { translate(4) }
                                       </p>
                                    </div>
                                 </div>

                                 <div className = 'border-b border-gray-400 flex h-[15%]'>
                                    <div className = 'flex flex-col justify-between border-r border-gray-400 w-[60%]'>
                                       <p className = 'text-xs text-left pl-2 truncate'>{ translate(19) }</p>
                                       <p className = 'text-sm'>
                                          <span className = 'text-base lg:text-lg font-semibold'>{ bid.auction.initial_price.toLocaleString('en', opt) }</span> 
                                          { translate(4) }
                                       </p>
                                    </div>
                                    <div className = 'flex flex-col justify-between w-[40%]'>
                                       <p className = 'text-xs text-left pl-2 truncate'>{ translate(16) }</p>
                                       <p className = 'text-sm truncate px-1'>
                                          <span className = {`text-base lg:text-lg ${language === 'Amharic' && !bid.auction.max_increment ? 'font-normal' : 'font-semibold font-Roboto'}`}>{ bid.auction.max_increment ? bid.auction.max_increment.toLocaleString('en', opt) : translate(81) }
                                          </span> 
                                          { bid.auction.max_increment ? translate(4) : null }
                                       </p>
                                    </div>
                                 </div>
                              </>
                           }

                           <div className = 'border-b border-gray-400 flex h-[15%]'>
                              <div className = 'border-r border-gray-400 flex flex-col justify-between w-[47%]'>
                                 <p className = 'text-xs text-left pl-2'>{ translate(20) }</p>
                                 <CountdownTimer
                                    timestampMs = { new Date(bid.auction.end_time).getTime() }
                                    view = 'grid'
                                 />
                              </div>
                              <div className = 'flex flex-col justify-between w-[53%]'>
                                 <p className = 'text-xs text-left pl-2'>{ translate(25) }</p>
                                 <p className = 'text-sm font-semibold '>{ dayjs(new Date(bid.auction.end_time).toISOString()).format('h:mm a, MMM D, YYYY') }</p>
                              </div>
                           </div>

                           <div className = 'flex h-[14%]'>
                              <div className = 'flex justify-center items-center border-r border-gray-400 p-1 w-full'>
                                 <button 
                                    onClick = { () => { dispatch(selectCategory({ id: bid.auction.product.category })); navigate(`/auction/${bid.auction.id}`) }}
                                    className = 'bg-primary-100 w-full h-full text-white text-xs hover:bg-lime-600 rounded-b' 
                                 >
                                    { translate(37) }
                                 </button>
                              </div>
                           </div>

                           {processing ?
										<div className = 'bg-black/80 h-[35%] w-[60%] rounded-[50%] absolute right-[20%] top-[30%] flex items-center justify-center gap-3'>
											<Loading min loading = {true} loadingStyle= 'text-white w-7 h-7' /><div className = 'text-white text-lg font-semibold'>Processing</div>
										</div>
										: null
									}

                           {ended ?
                              <div className = 'absolute inset-0 -rotate-[35deg] flex items-center justify-center text-orange-500 font-bold text-4xl h-12 w-64 mx-auto my-auto pb-6 pl-8' style = {{textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'}}>
                                 <div className = {`${language === 'Afaan-Oromoo' ? 'tracking-[.6em]' : 'tracking-[.8em]'} h-10`}>{ translate(33) }</div>
                              </div>
                              : null
                           }

                           {hitGavel ? 
                              <div className = 'absolute inset-0 bottom-[15%] mx-auto my-auto w-72 flex items-center justify-center'>
                                 <img src = { hit_gavel } alt = '' className = 'w-72 object-cover' />
                              </div>
                              : null
                           }
                        </div>
                     </div>
                  ))
               }
            </div>
            :
            <div className = 'w-full flex justify-center pt-12 text-sky-600 font-semibold pb-20 md:pb-0'>{ translate(265) }</div> 
         }
      </div>
   )
}

export default BidGrid;