import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, sendMessages, setFirstLoad } from '../../features/chatSlice';
import { MdSend } from 'react-icons/md';
import { user_man, user_woman } from '../../assets';
import Loading from '../common/Loading';
import dayjs from 'dayjs';
import translate from '../../Services/translate';


const Chatting = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const { language } = useSelector(({ language }) => language);
  const { chats, chat_loading, send_loading } = useSelector(({ chat }) => chat);
  const { myProfile } = useSelector(({ auth }) => auth);

  const [msg, setMsg] = useState({ message: '' });

  const msg_input = useRef(null)

  const sendMsg = e => {
    e.preventDefault()
    dispatch(sendMessages(msg)).then(() => {
      dispatch(setFirstLoad({ firstLoad: false }))
      setMsg({ message: '' })
      dispatch(getMessages())
    })
  }

  useEffect(() => {
    dispatch(setFirstLoad({ firstLoad: true }))
    dispatch(getMessages())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    msg_input.current.focus();
    // eslint-disable-next-line
  }, [chats])
  

   return (
      <div className = 'relative'>
         <div className = 'self-center pb-1 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-between'>
            <h2 className = 'text-lg md:text-2xl'>{ translate(122) }</h2>
         </div>

         {!chat_loading ?
            <div className = 'chat-thread'>
               {chats.length ?
                  <>
                  {chats.map((chat, i) => (
                     <div key = {i} className = 'flex flex-col'>
                        {chat.sender === myProfile?.user_profile?.username &&
                        <> 
                           <div>
                              <div  className = 'flex justify-end'>
                              <div className = ''>
                                 <div className = {`msg me`}>
                                    { chat.message }
                                 </div>
                              </div>
                              
                              <div className = 'h-11 w-11 rounded full'>
                                 <img 
                                    src = {myProfile?.user_profile?.image_url ? 
                                    `${myProfile.user_profile.image_url}` : 
                                    myProfile?.user_profile?.gender === 'FEMALE' ? user_woman : 
                                    user_man} alt = 'user' className = 'h-full w-full rounded-full object-cover'
                                 />
                              </div>
                              </div>
                           </div>
                           <p className = 'text-xs text-right text-stone-300 pb-7 pr-24 -mt-5'>{ dayjs(chat.timestamp).format('MMM D, YYYY') }</p> 
                        </>}
                        {chat.sender === 'yamlak' &&
                        <>
                           <div className = ''>
                              <div className = 'msg friend'>{ chat.message }</div>
                           </div>
                           <p className = 'text-xs pl-24 text-stone-300 pb-7 flex items-start -mt-5'>{ dayjs(chat.timestamp).format('MMM D, YYYY') }</p>
                        </>
                        }
                     </div>
                  ))
                  }
                  </>
                  :
                  <div className = 'w-full flex justify-center pt-12 text-sky-600 font-semibold'>
                     {translate(123)}
                  </div>
               }
            </div>
            :
            <div className = 'flex justify-center w-full pt-12'>
               <Loading loading = { chat_loading } loadingStyle = 'text-sky-600 w-7 h-7'/>
            </div>
         }

         <form onSubmit = { (e) => sendMsg(e) } className = {`${chat_loading ? 'hidden' : ''} mb-2`}>
            <div className ='absolute bottom-0 flex justify-center items-center w-full'>
               <div className = 'relative w-full px-2 md:px-0 md:w-[70%]'>
                  <label>
                     <input 
                        // id = '_chat_message'
                        className = 'w-full h-10 py-1 border-b-2 border-stone-200 bg-gray-500 text-gray-200 focus:outline-none rounded-lg pl-3 pr-12 md:pr-20 shadow-md shadow-stone-300' 
                        onChange = {e => setMsg({ ...msg, message: e.target.value })} 
                        value = { msg.message }
                        type = 'text' 
                        placeholder = { translate(124) }
                        ref = { msg_input}
                        autoFocus
                     />
                  </label>
                  <button type = 'submit'>
                  {send_loading ?
                     <Loading loading = { send_loading } loadingStyle = 'text-sky-600 w-5 h-5 absolute right-2 top-[30%]' />
                     :
                     <MdSend className = 'w-7 h-7 text-white hover:text-sky-300 cursor-pointer absolute right-2 top-[13%]' />
                  }
                  </button>
               </div>
            </div>
         </form>
      </div>
   )
}

export default Chatting;