import { useNavigate } from 'react-router-dom';
import translate from '../../Services/translate';
import { useSelector } from 'react-redux';


const PageNotFound = () => {

   const navigate = useNavigate();
   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);

   return (
      <div className = 'flex flex-col justify-center'>
         <h1 className = 'py-32 text-primary-100 font-bold text-4xl flex justify-center'>{ translate(277)} !!</h1>

         <div className = 'flex justify-center pb-6'>
         <button 
            className = 'flex justify-center cursor-pointer bg-gray-100 hover:text-sky-600 hover:bg-white border border-primary-100 rounded-md px-3 py-2'
            onClick = { () => navigate('/') }>
               { translate(278) }
         </button>
         </div>
      </div>
   )
}

export default PageNotFound;