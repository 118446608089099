import { useState } from 'react';
import { useSelector } from 'react-redux';
import translate from '../../Services/translate';

const Tutorials = () => {

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { how_to_use_videos } = useSelector(({ auction }) => auction);
   const [modalOpen, setModalOpen] = useState(false);
   const [selectedVideo, setSelectedVideo] = useState('');

   const openModal = (videoId) => {
      setSelectedVideo(videoId);
      setModalOpen(true);
   };

   const closeModal = () => {
      setModalOpen(false);
      setSelectedVideo('');
   };

   return (
      <div className = 'px-3 sm:px-5'>
         <div className = 'pb-1 mb-2 text-primary-100 font-semibold text-left pl-3 md:pl-10 border-b border-gray-400 py-1 flex justify-start items-center'>
            <h2 className = 'text-lg md:text-2xl font-Stencil'>{ translate(339) }</h2>
         </div>

         <div className = "thumbnail-grid px-3 grid grid-cols-2 sm:grid-cols-5 gap-x-7 pt-6">
            {how_to_use_videos.map((video, index) => {
               let videoId = video.link.slice(17,28)
               return(
                  <div
                     key={index}
                     className = "thumbnail hover:scale-105 duration-200 w-auto"
                     onClick = {() => openModal(videoId)}
                  >
                     <img
                        src = {`https://img.youtube.com/vi/${videoId}/0.jpg`}
                        alt = {`Thumbnail ${index}`}
                        className = 'rounded-lg'
                     />
                     <p className = 'text-gray-800 text-sm text-center pt-2'>
                        {video.description} <br/>
                        {video.description_am}
                     </p>
                  </div>
               )
            })}
         </div>

         {modalOpen && (
            <div className = "modal absolute top-0 right-0">
               <div className = "close-icon flex items-center justify-center bg-black rounded-full h-7 w-7" onClick = { closeModal }>
                  <div >&#10006;</div>
               </div>
               <div className = "video-container h-screen w-[100%] absolute">
                  <iframe
                     className = 'iframe-embed'
                     title = "YouTube Video"
                     height = "100%"
                     src = {`https://www.youtube.com/embed/${selectedVideo}?autoplay=1&controls=1&rel=0`}
                     allowFullScreen
                  ></iframe>
               </div>
            </div>
         )}
         
      </div>
   )
}

export default Tutorials
