import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { MdDeleteForever, MdDeleteOutline, MdWarning } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Modal from "../common/Modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccount, resetDeleteStatus } from "../../features/authSlice";
import Loading from "../common/Loading";
import translate from '../../Services/translate';

const Deactivation = () => {

   const navigate = useNavigate();
   const dispatch = useDispatch();

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { delete_loading, delete_status } = useSelector(({ auth }) => auth);
   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

   const deleteMyAccount = () => {
      dispatch(deleteAccount())
   }

   useEffect(() => {
      if(delete_status === 'success'){
         setShowDeleteConfirmation(false)
         dispatch(resetDeleteStatus())
         navigate('/logout')
      }
      // eslint-disable-next-line
   }, [delete_status])

   return (
      <>
         <div className = 'flex justify-center w-full h-[300px] pt-12'>
            <div className = 'w-full md:w-[50%] px-2 md:px-0 flex-row items-center rounded-xl'>
               <div className = 'bg-red-100 border border-red-300 rounded-xl flex h-full items-center px-5'>
                  <div className = 'w-10 h-10 rounded-full border-red-300 flex justify-center items-center'>
                     <MdWarning className = 'h-9 w-9 text-red-400' />
                  </div>

                  <div className = 'text-lg pl-5'>
                     <h1 className = 'text-base sm:text-lg md:text-xl font-semibold text-gray-700'>{ translate(68) }</h1>
                     <p className = 'text-sm sm:text-base pt-4'>{ translate(69) }</p>
                     <div className = 'pl-4 text-sm sm:text-base'>
                        <span className = 'flex'><BsDot className = 'h-7 w-7' />{ translate(70) }</span>
                        <span className = 'flex'><BsDot className = 'h-7 w-7' />{ translate(71) }</span>
                        <span className = 'flex'><BsDot className = 'h-7 w-7' />{ translate(72) }</span>
                        <span className = 'flex'><BsDot className = 'h-7 w-7' />{ translate(73) }</span>
                        <span className = 'flex'><BsDot className = 'h-7 w-7' />{ translate(74) }</span>
                     </div>
                  </div>
               </div>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex justify-between w-full md:w-full p-4'>
                     <button 
                        className = 'bg-stone-400 rounded w-[40%] py-2 text-white hover:bg-gray-600'
                        onClick = {() => navigate(-1)} 
                     >
                        { translate(63) }
                     </button>
                     <button 
                        className = 'bg-red-600 hover:bg-red-700 text-white rounded w-[40%] py-2 relative'
                        onClick = {() => setShowDeleteConfirmation(true)}
                     >
                        <MdDeleteOutline className = 'text-white absolute left-2 sm:left-5 top-2 w-6 h-6' />
                        { translate(75) }
                     </button>
                  </div>
               </div>
            </div>
         </div>

         <Modal visible = { showDeleteConfirmation } customWidth = 'w-[94%] md:w-[35%]' bgBlur = { true }>
            <div className = 'w-full flex justify-end'>
               <Loading loading = { delete_loading } loadingStyle = 'text-sky-600 w-5 h-5'/>
            </div>
            <div className = 'justify-between p-4'>
               <div className = 'w-full flex justify-center'>
                  <MdDeleteForever className = 'w-12 h-12 text-red-300' />
               </div>
               <h2 className = 'text-red-600 text-xl w-full text-center pt-5'>{ translate(68) }</h2>
               <div className = 'mt-8 flex justify-between mr-0 ml-0'>
                  <button 
                     className = 'border border-gray-500 text-white bg-gray-500 hover:bg-white hover:text-sky-600 hover:border-sky-600 p-1 rounded-lg w-36 sm:w-40 md:w-56'
                     onClick = {() => setShowDeleteConfirmation(false)} 
                  >
                     { translate(63) }
                  </button>
                  <button onClick = { deleteMyAccount } className = 'border border-red-700 bg-red-600 hover:bg-red-700 text-white p-1 rounded-lg w-36 sm:w-40 md:w-56'>
                     { translate(76) }
                  </button>
               </div>
            </div>
         </Modal>
      </>
   )
}

export default Deactivation;
