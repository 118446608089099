import { Navigate } from 'react-router-dom';
// import { HiOutlineAtSymbol } from 'react-icons/hi';
import { BsHouse, BsInfoCircle, BsQuestionCircle, BsGlobe2, BsCheck2Square, BsCaretRight, BsCaretDownFill, BsCaretUpFill, BsGem } from 'react-icons/bs';
import { MdLogin, MdOutlinePrivacyTip } from 'react-icons/md';
import { FaChalkboardTeacher } from "react-icons/fa";
import { HiOutlineUser } from 'react-icons/hi';
import { TfiEye } from 'react-icons/tfi/index.esm';
import { Home, Register, Login, Logout, Auctions, AuctionDetail, Activation, AboutUs, Faq, MyAccount, TermsAndConditions, Bid, PageNotFound, MyWatchList, Purchases, MyBalance, MyDocuments, Chatting, Deactivation, MobViewAboutUs, MobViewFaq, MonitoringScreen, Subscriptions, PrivacyPolicy, MobViewSubscriptionSucess, Tutorials } from '../components';


export const routes = [
  {path: '/', element: <Navigate to = '/home' />},
  {path: '/home', element: <Home />},
  {path: '/category/auctions/:id', element: <Auctions />},
  {path: '/auction/:id', element: <AuctionDetail />},
  {path: '/register', element: <Register />},
  {path: '/account-activation', element: <Activation />},
  {path: '/login', element: <Login />},
  {path: '/logout', element: <Logout />},
  {path: '/termsAndConditions', element: <TermsAndConditions />},
  {path: '/privacyPolicy', element: <PrivacyPolicy />},
  {path: '/aboutUs', element: <AboutUs />},
  // {path: '/contactUs', element: <ContactUs />},
  {path: '/faq', element: <Faq />},
  // {path: '/service', element: <Service />},
  {path: '/mobile-aboutUs', element: <MobViewAboutUs />},
  {path: '/mobile-faq', element: <MobViewFaq />},
  {path: '/not-found', element: <PageNotFound />},
  {path: '*', element: <Navigate to = '/not-found' />},
  {path: '/tutorials', element: <Tutorials /> },
]

export const privateRoutes = [
  {path: '/my-watchlist', element: <MyWatchList /> },
  {path: '/my-bids', element: <Bid /> },
  {path: '/my-purchases', element: <Purchases /> },
  {path: '/myAccount', element: <MyAccount /> },
  {path: '/chatting', element: <Chatting /> },
  {path: '/my-balance', element: <MyBalance /> },
  {path: '/my-Documents', element: <MyDocuments /> },
  {path: '/delete-account', element: <Deactivation /> },
  {path: '/monitor/:id', element: <MonitoringScreen /> },
  {path: '/subscription', element: <Subscriptions /> },
  // {path: '/subscription/waiting', element: <SubscriptionWaiting /> },
  {path: '/subscription-success', element: <MobViewSubscriptionSucess /> }
]

export const SidebarRoutes = [
  {
    title: 'Home',
    path: '/home',
    icon: <BsHouse className = 'w-5 h-5' />,
    id: 142
    // id: 1
  },
  {
    title: 'My Watchlist',
    path: '/my-watchlist',
    icon: <TfiEye className = 'w-5 h-5' />,
    id: 147
    // id: 32
  },
  // {
  //   title: 'My Bids',
  //   path: '/my-bids',
  //   icon: <TbGavel className = 'w-5 h-5' />,
  //   // id: 129
  //   id: 46
  // },
  // {
  //   title: 'My Purchases',
  //   path: '/my-purchases',
  //   icon: <TfiTag className = 'w-5 h-5' />,
  //   // id: 130
  //   id: 47
  // },
  // {
  //   title: 'My Balance',
  //   path: '/my-balance',
  //   icon: <MdAttachMoney className = 'w-5 h-5' />,
  //   // id: 131
  //   id: 16
  // },
  // {
  //   title: 'My Documents',
  //   path: '/my-documents',
  //   icon: <HiOutlineDocumentDuplicate className = 'w-5 h-5' />,
  //   // id: 132
  //   id: 17
  // },
  // {
  //   title: 'Help Desk',
  //   path: '/chatting',
  //   icon: <MdOutlineSupportAgent className = 'w-5 h-5' />,
  //   // id: 327
  //   id: 71
  // },
  {
    title: 'About Auction-Ethiopia',
    path: '/aboutUs',
    icon: <BsInfoCircle className = 'w-5 h-5' />,
    id: 125
    // id: 2
  },
  {
    title: 'FAQ',
    path: '/faq',
    icon: <BsQuestionCircle className = 'w-5 h-5' />,
    id: 127
    // id: 3
  },
  {
    title: 'Pricing',
    path: '/subscription',
    icon: <BsGem className = 'w-5 h-5' />,
    id: 337
    // id:356
  },
  // {
  //   title: 'Contact Us',
  //   path: '/contactUs',
  //   icon: <HiOutlineAtSymbol className = 'w-5 h-5' />,
  //   // id: 128
  //   id: 4
  // },
  // {
  //   title: 'Chat With Us',
  //   path: '/chatting',
  //   icon: <BsChat className = 'w-5 h-5' />,
  //   // id: 149
  //   id: 70
  // },
  {
    title: 'Language',
    path: '#',
    icon: <BsGlobe2 className = 'w-5 h-5'/>,
    id: 326,
    // id: 15,
    iconClosed: <BsCaretDownFill className = 'pt-1'/>,
    iconOpened: <BsCaretUpFill className = 'pt-1'/>,
    subNav: [
      {
        title: 'English',
        path: '#',
        icon: <BsCaretRight/>,
        onClick: true,
        value: 'English'
      },
      {
        title: 'አማርኛ',
        path: '#',
        icon: <BsCaretRight/>,
        onClick: true,
        value: 'Amharic'
      },
      {
        title: 'Afaan-Oromoo',
        path: '#',
        icon: <BsCaretRight/>,
        onClick: true,
        value: 'Afaan-Oromoo'
      }
    ]
  },
  // {
  //   title: 'My Profile',
  //   path: '/myAccount',
  //   icon: <SlUser className = 'w-5 h-5'/>,
  //   // id: 146
  //   id: 69
  // },
  {
    title: 'Register',
    path: '/register',
    icon: <HiOutlineUser className = 'w-5 h-5'/>,
    id: 95
    // id: 6
  },
  {
    title: 'Login',
    path: '/login',
    icon: <MdLogin className = 'w-5 h-5'/>,
    id: 86
    // id: 5
  },
  {
    title: 'Terms & Conditions',
    path: '/termsAndConditions',
    icon: <BsCheck2Square className = 'w-5 h-5'/>,
    id: 42
    // id: 10
  },
  // {
  //   title: 'Logout',
  //   path: '/logout',
  //   icon: <MdLogout className = 'w-5 h-5'/>,
  //   // id: 150
  //   id: 45
  // },
  {
    title: 'Tutorials',
    path: '/tutorials',
    icon: <FaChalkboardTeacher className = 'w-5 h-5'/>,
    id: 339
    // id: 358
  },
  {
    title: 'Privacy Policies',
    path: '/privacyPolicy',
    icon: <MdOutlinePrivacyTip className = 'w-5 h-5'/>,
    id: 134
    // id: 208
  }
];