import { useState, createRef, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTopTenBidders, loadAuctionById } from '../../features/auctionsSlice';
import { CountdownTimer, opt } from '../../utils';
import ImageGallery from 'react-image-gallery';
import dayjs from 'dayjs';
import AnimateTopBidders from './AnimateTopBidders';
import BidderBar from './BidderBar';
import translate from '../../Services/translate';


const MonitoringScreen = () => {
   const dispatch = useDispatch();
   const { id } = useParams();

   const { auctionById } = useSelector(({ auction }) => auction);
   const { detail, topTenBidders } = auctionById || {}
   const [topBidders, setTopBidders] = useState(topTenBidders);
   const [missedTopBidders, setMissedTopBidders] = useState([]);
   const defTopTen = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

   const memoizedBidderBars = useMemo(() => (
      topBidders.map(({ user_id, first_name, last_name, amount }, idx) => (
        <BidderBar
            idx = { idx }
            key={user_id}
            id={user_id}
            first_name={first_name}
            last_name={last_name}
            amount={amount}
            ref={createRef()}
            monitor
        />
      ))
   ), [topBidders]);

   useEffect(() => {
      if(topBidders.length){
         let arr = [];
         for(let i = 1; i <= 10 - topBidders.length; i++) {
            arr.push({num: topBidders.length + i})
         }
         setMissedTopBidders(arr)
      }
   }, [topBidders])

   useEffect(() => {
      dispatch(loadAuctionById({ id }))
      dispatch(getTopTenBidders({ id }))
      // eslint-disable-next-line
   }, [id]);

   useEffect(() => {
      if (topTenBidders?.length) {
         setTopBidders(topTenBidders.slice().sort((a, b) => b.amount - a.amount).slice(0, 10))
      }
      // eslint-disable-next-line
   }, [auctionById]);

   const images = detail?.product?.images?.map(url => ({
      original: `${url}`,
      thumbnail: `${url}`,
      description: url.description
   }))

   return (
      <div className = 'px-4 min-h-screen py-5'>

         <div className = 'sm:flex justify-between w-full px-3 sm:px-5  rounded-xl py-2 border border-sky-400 bg-white'>
            <div className = 'hidden sm:block'>
               <div className = 'flex justify-between flex-col gap-2 pl-8'>
                  <p className = 'text-sky-600 text-4xl font-semibold'>{ detail?.product?.name }</p>
                  <p className = 'text-2xl text-gray-500 font-Roboto'>{ translate(18) }: { detail?.lot_number }</p>
               </div>
            </div>

            <div className = 'block sm:hidden'>
               <p className = 'text-sky-600 text-2xl font-semibold'>{ detail?.product?.name }</p>
               <div className = 'flex justify-between'>
                  <div className = 'text-lg text-gray-500 font-Roboto'>{ translate(18) }:</div>
                  <div className = 'text-lg text-sky-600 font-Roboto'>{ detail?.lot_number }</div>
               </div>
            </div>
            
            <div className = 'flex flex-row sm:flex-col gap-2 items-center justify-between'>
               <div className = 'flex justify-start sm:justify-center text-gray-500 text-lg sm:text-xl font-Roboto'>
                  {detail?.status === 'PENDING' ? translate(243) : detail?.status === 'ACTIVE' ? translate(244) : null}
               </div>
               <div className = 'text-center text-2xl text-sky-600'>
                  <CountdownTimer timestampMs = { new Date(detail?.end_time).getTime() } view = 'monitor'/>
               </div>
            </div>

            <div className = 'flex flex-row sm:flex-col justify-between gap-6 h-full'>
               <div className = 'text-left sm:text-center text-gray-500 text-lg sm:text-xl font-Roboto'>{ translate(245) }</div>
               <div className = 'text-center text-lg sm:text-xl text-green-500 font-bold pt-'>{detail?.status}</div>
            </div>
         </div>

         <div className = 'sm:flex w-full pb-4'>
            <div className = 'w-full sm:w-[65%] border-r sm:pr-2 border-gray-200 items-center justify-center'>

               <div className = 'pt-2 sm:pt-14 sm:w-full'>
                  <div className = 'py-1 sm:py-2 md:py-4 bg-gradient-to-r from-lime-700 via-sky-700 to-lime-700 text-white rounded-t-lg flex items-center justify-center text-base sm:text-lg md:text-2xl mb-1 sm:mb-2 md:mb-5'>
                     <p className = ''>{ detail?.lot_number }</p>
                  </div>
                  {
                     detail?.product?.images?.length
                     ?
                     <div className = 'overflow-y-hidden gallery-container w-full' >
                        <ImageGallery 
                           items = {images} 
                           autoPlay = {true}
                           showIndex = {true}
                           showBullets = {false}
                           infinite = {true}
                           showThumbnails = {false}
                           showFullscreenButton = {true}
                           showGalleryFullscreenButton = {true}
                           showPlayButton = {true}
                           showGalleryPlayButton = {true}
                           showNav = {true}
                           isRTL = {false}
                           slideDuration = {450}
                           slideInterval = {5000}
                           slideOnThumbnailOver = {true}
                           useWindowKeyDown = {true}
                           description = {false}
                           additionalClass = 'image_gallery md:pl-2 monitor'
                        />
                     </div>
                     :
                     <div className = 'w-full h-full md:pr-2 md:w-[50%] md:h-[342px]'>
                        <div className = 'w-full h-full flex justify-center items-center text-gray-500 text-lg italic md:border border-gray-300 rounded-lg font-thin md:font-medium'>{ translate(34) }</div>
                     </div>
                  }
               </div>

            </div>

            <div className = 'w-full sm:w-[35%]'>
               <div className = {`w-full flex justify-center font-Roboto text-xl lg:text-3xl pt-3 sm:py-2 text-sky-600 md:font-semibold`}>{ translate(41) }</div>
               { topTenBidders.length ?
                  <>
                     <AnimateTopBidders>
                        { memoizedBidderBars }
                     </AnimateTopBidders>
                     {
                        missedTopBidders.length &&
                        missedTopBidders.map((m, i) => (
                           <div key = { i } className = 'w-full'>
                              <div className = {`relative opacity-75 bg-gray-300 text-gray-700 h-[30px] my-[0.6rem] lg:h-[70px] pr-1 sm:pr-5 sm:mx-[0.6rem] flex justify-between gap-2 rounded-md border border-sky-700 ${m.num <= 3 ? 'font-semibold' : ''}`}>

                                 <span className = {`pl-1 sm:pl-2 flex items-center text-gray-700 w-[10%] justify-center text-base lg:text-3xl ${m.num <= 3 ? 'font-bold' : 'font-[300]'}`}>{m.num} 
                                 </span>

                                 <div className={`flex justify-start w-[55%] text-gray-700 items-center h-full truncate italic text-base lg:text-3xl`}>no bidder</div>
                                 
                              </div>
                           </div>
                        ))
                     }
                  </>
                  :
                  defTopTen.map((d, idx) => (
                     <div key = { idx } className = 'w-full'>
                        <div className = {`relative opacity-75 bg-gray-300 text-gray-700 h-[30px] my-[0.6rem] lg:h-[70px] pr-1 sm:pr-5 sm:mx-[0.6rem] flex justify-between gap-2 rounded-md border border-sky-700 ${idx <= 2 ? 'font-semibold' : ''}`}>

                           <span className = {`pl-1 sm:pl-2 flex items-center text-gray-700 w-[10%] justify-center text-base lg:text-3xl ${idx <= 2 ? 'font-bold' : 'font-[300]'}`}>{idx + 1} 
                           </span>

                           <div className={`flex justify-start w-[55%] text-gray-700 items-center h-full truncate italic text-base lg:text-3xl`}>
                              {!detail?.is_closed ? 'no bidder' : 'hidden'}
                           </div>
                           
                        </div>
                     </div>
                  ))
               }
            </div>
         </div>

         {
            !detail?.is_closed ?
         <>
            <div className = 'hidden sm:block'>
               <div className = 'w-full rounded-lg border border-sky-700 px-2 py-3 flex justify-between text-2xl'>
                  <div className = 'flex flex-col gap-1 w-1/3 border-r border-sky-400'>
                     <div className = 'flex w-full'>
                        <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(19) }:</div>
                        <div className = 'text-left text-sky-500 w-2/5'>{ detail?.initial_price ? detail.initial_price.toLocaleString('en', opt) : '-' } 
                           <span className = 'text-black'>{ translate(4) }</span>
                        </div>
                     </div>
                     <div className = 'flex w-full'>
                        <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(247) }:</div>
                        <div className = 'text-left text-sky-500 w-2/5'>{ detail?.current_price ? detail.current_price.toLocaleString('en', opt) : '-' } 
                           <span className = 'text-black'>{ translate(4) }</span>
                        </div>
                     </div>
                  </div>
                  <div className = 'flex flex-col gap-1 w-1/3 border-r border-sky-400 pl-8'>
                     <div className = 'flex w-full'>
                        <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(15) }:</div>
                        <div className = 'text-left text-sky-500 w-2/5'>{ detail?.min_increment ? detail.min_increment.toLocaleString('en', opt) : '-' } 
                           <span className = 'text-black'>{ translate(4) }</span>
                        </div>
                     </div>
                     <div className = 'flex w-full'>
                        <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(16) }:</div>
                        <div className = 'text-left text-sky-500 w-2/5'>{ detail?.max_increment ? detail.max_increment.toLocaleString('en', opt) : '-' } 
                           <span className = 'text-black'>{ translate(4) }</span>
                        </div>
                     </div>
                  </div>
                  <div className = 'flex flex-col gap-1 w-1/3 pl-8'>
                     <div className = 'flex w-full'>
                        <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(248) }:</div>
                        <div className = 'text-left text-sky-500 w-2/5'>{ detail?.no_of_bids }</div>
                     </div>
                     <div className = 'flex w-full'>
                        <div className = 'text-gray-600 font-semibold w-1/2'>{ translate(25) }:</div>
                        <div className = 'text-left text-sky-500 w-1/2'>{detail?.end_time ? dayjs(new Date(detail?.end_time).toISOString()).format('h:mm a, MMM D, YYYY') : null }</div>
                     </div>
                  </div>

               </div>
            </div>

            <div className = 'block sm:hidden'>
               <div className = 'flex w-full justify-between'>
                  <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(19) }:</div>
                  <div> 
                     <span className = 'text-sky-500 w-2/5'>{ detail?.initial_price ? detail.initial_price.toLocaleString('en', opt) : '-' }</span> 
                     <span className = 'text-gray-600'>{ translate(4) }</span>
                  </div>
               </div>
               <div className = 'flex w-full justify-between'>
                  <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(247) }:</div>
                  <div> 
                     <span className = 'text-sky-500 w-2/5'>{ detail?.current_price ? detail.current_price.toLocaleString('en', opt) : '-' }</span>
                     <span className = 'text-gray-600'>{ translate(4) }</span>
                  </div>
               </div>
               <div className = 'flex w-full justify-between'>
                  <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(15) }:</div>
                  <div> 
                     <span className = 'text-sky-500 w-2/5'>{ detail?.min_increment ? detail.min_increment.toLocaleString('en', opt) : '-' }</span>
                     <span className = 'text-gray-600'>{ translate(4) }</span>
                  </div>
               </div>
               <div className = 'flex w-full justify-between'>
                  <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(16) }:</div>
                  <div> 
                     <span className = 'text-sky-500 w-2/5'>{ detail?.max_increment ? detail.max_increment.toLocaleString('en', opt) : '-' }</span> 
                     <span className = 'text-gray-600'>{ translate(4) }</span>
                  </div>
               </div>
               <div className = 'flex w-full justify-between'>
                  <div className = 'text-gray-600 font-semibold w-3/5'>{ translate(248) }:</div>
                  <div className = 'text-right text-sky-500 w-2/5'>{ detail?.no_of_bids }</div>
               </div>
               <div className = 'flex w-full justify-between'>
                  <div className = 'text-gray-600 font-semibold w-1/2'>{ translate(25) }:</div>
                  <div className = 'text-right text-sky-500 w-1/2 text-sm pt-1'>{detail?.end_time ? dayjs(new Date(detail?.end_time).toISOString()).format('h:mm a, MMM D, YYYY') : null }</div>
               </div>

            </div>
         </>
         : null }

      </div>
   );
};

export default MonitoringScreen;
