import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './App/store';
import App from './App';
import './index.css';
import ScrollToTop from './utils/ScrollToTop';

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <BrowserRouter>
      <Provider store = { store }>
        <ScrollToTop />
        <App />
      </Provider>
    </BrowserRouter>
  </StrictMode>
);