import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { forgotPassword, getProfile, login, resetLoginStatus, resetPassword, setLoginError, setRemember } from '../../features/authSlice';
import { checkSubscriptionStarted, getMySubscriptions, loadBidsLight } from '../../features/bidSlice';
import { getNewNotifications, getNotifications } from '../../features/chatSlice';
import { BsExclamationCircle, BsEye, BsEyeSlash } from 'react-icons/bs';
import { CiLogin, CiLock } from 'react-icons/ci';
import { FiX } from 'react-icons/fi';
import { logo192 } from '../../assets';
import translate from '../../Services/translate';
import Loading from '../common/Loading';
import toast from 'react-hot-toast';
import Modal from '../common/Modal';


const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line
  const { language } = useSelector(({ language }) => language);
  const { token, login_error, auth_loading, profile_status, login_status, password_status, password_loading, available_in } = useSelector(({ auth }) => auth);

  const [user, setUser] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const [forgotPasswordModal, toggleForgotPasswordModal] = useState(false);
  const [resetPasswordModal, toggleResetPasswordModal] = useState(false);
  const [phone, setPhone] = useState({ phone_number: '' });
  const [resetData, setResetData] = useState({ phone_number: phone?.phone_number, code: '' });
  const [tempPhone, setTempPhone] = useState();
  const [phoneNo, setPhoneNo] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [shakeText, setShakeText] = useState(false);


   const handleLogin = (e) => {
      e.preventDefault()
      if(!user?.username || !user?.password){
         dispatch(setLoginError({ msg: translate(77) }))
         return
      }
      dispatch(setRemember({ value: rememberMe }))
      dispatch(setLoginError({ msg: '' }))
      dispatch(login(user))
      if(available_in){
         setShakeText(true)
         dispatch(setLoginError({ msg: '' }))
      }
   }

   const modifyPhone = num => {
      num?.length && setTempPhone(num[0])
      if(num?.length === 10){
         // eslint-disable-next-line
         if(num[0] == 0){
            return '+251' + num.slice(1)
         }
      }else if(num?.length === 9){
         // eslint-disable-next-line
         if(num[0] == 9){
            return '+251' + num
         }
      }
      return null
   }

   const handleForgotPassword = () => {
      dispatch(forgotPassword(phone))
   }

   const handleResetPassword = () => {
      dispatch(resetPassword(resetData))
   }

   useEffect(()=> {
      if(token){
         dispatch(resetLoginStatus())
         setUser({})
         dispatch(getProfile()).then(() => {
         if(login_status === 'success' && profile_status === 'success'){
            toast.success(translate(78))
            dispatch(resetLoginStatus())
         }
         dispatch(getNotifications())
         dispatch(getNewNotifications())
         dispatch(loadBidsLight())
         })
         dispatch(checkSubscriptionStarted())
         dispatch(getMySubscriptions())
         if(location?.state?.from){
            navigate(location.state.from.pathname, { state: {cameFrom: (location.state.from.pathname.includes('/auction/')) ? 'login' : null}  })
         }
         else{
            navigate('/')
         }
      }
      // eslint-disable-next-line
   }, [token])

   useEffect(() => {
      if(password_status === 'success'){
         toggleForgotPasswordModal(false)
         toggleResetPasswordModal(true)
      }
      if(password_status === 'reset success'){
         toggleResetPasswordModal(false)
         toast.success(translate(79), { duration: 4000, id: 'copy' })
         setResetData({phone_number: phone?.phone_number})
      }
      // eslint-disable-next-line
   }, [password_status])

   useEffect(() => {
      if(shakeText){
         setTimeout(() => {
            setShakeText(false)
         }, 1000);
      }
   }, [shakeText])


   return (
      <div>
         <div className = 'flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
            <div className = 'w-full max-w-md space-y-8'>
               <div>
                  <img className = 'mx-auto h-12 w-auto' src = { logo192 } alt='auction ethiopia'/>
                  <h2 className = 'mt-6 text-center text-3xl font-bold tracking-tight text-gray-500'>{ translate(80) }</h2>
               
                  <div className = ' pt-6 flex justify-center items-center'>
                     <div className = { `h-5 sm:h-6 px-3 w-full text-center text-sm sm:w-96 rounded text-red-700 ${shakeText ? 'shake-text' : ''} ${login_error ? 'bg-red-100' : ''}` }>
                        {login_error ?
                           <>
                              <div className = 'flex text-xs sm:text-sm items-center'> 
                                 <BsExclamationCircle className = 'sm:mt-1'/>
                                 <div className = 'pl-3 text-xs sm:text-sm'> { login_error } </div>
                              </div>
                              {available_in ?
                                 <div className = 'flex text-sm'>
                                    <div className = 'pl-6 pt-1 text-xs sm:text-sm'> 
                                       {translate(316)} 
                                       {Math.floor(Number(available_in.slice(0, available_in.length - 8)))} 
                                       {translate(317)}
                                    </div>
                                 </div>
                                 :
                                 null
                              }
                           </>
                           :
                           null
                        }
                     </div>
                  </div>
               </div>
               
               <form  onSubmit = {(e) => handleLogin(e) } >
                  <div className = 'mt-8 space-y-6' >
                     <div className = '-space-y-px rounded-md shadow-sm'>
                        <div>
                           <label htmlFor = 'user_name' className = 'sr-only'>Username</label>
                           <input 
                              defaultValue = { user.username } 
                              onChange = { e => setUser({...user, username: e.target.value}) } 
                              id = 'user_name' 
                              name = 'username' 
                              type = 'text' 
                              autoComplete = 'username' 
                              required 
                              className = 'relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-indigo-400 sm:text-sm' 
                              disabled = { available_in }
                              placeholder = { translate(82) }
                           />
                        </div>

                        <div className = 'pt-3 relative'>
                           <label htmlFor = 'pass_word' className = 'sr-only'>Password</label>
                           <input 
                              defaultValue = { user.password } 
                              onChange = {e => setUser({ ...user, password: e.target.value })} 
                              id = 'pass_word' 
                              name = 'password' 
                              type = { showPassword ? 'text' : 'password' } 
                              autoComplete = 'current-password' 
                              className = 'relative block w-full appearance-none rounded-md border border-gray-300 pl-3 pr-10 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-indigo-400 sm:text-sm ' 
                              disabled = { available_in }
                              placeholder = { translate(83) }
                              required 
                           />

                           <div 
                              onClick = {e => {
                                 e.stopPropagation()
                                 setShowPassword(!showPassword)
                              }}
                              className = 'flex flex-row bg-transparent rounded-md items-center justify-center p-3 absolute top-[0.55rem] right-0 z-10'>
                              {showPassword 
                              ? <BsEyeSlash className = 'text-primary-dark text-xl'/>
                              : <BsEye className = 'text-primary-dark text-xl'/>}
                           </div>
                        </div>
                     </div>

                     <div className = 'flex items-center justify-between'>
                        <div className = 'flex items-center'>
                           <input 
                              onChange = { e => setRememberMe(e.target.checked) } 
                              id = 'remember_me' 
                              name = 'remember-me' 
                              type = 'checkbox' 
                              className = 'h-4 w-4 rounded border-gray-300 text-third-50 focus:ring-third-50'
                              disabled = {available_in}
                           />
                           <label htmlFor = 'remember-me' className = 'ml-2 block text-sm text-gray-900'>{ translate(84) }</label>
                        </div>

                        <div className = 'text-sm'>
                           <div onClick = {e => toggleForgotPasswordModal(true)} className = 'font-medium text-sky-600 hover:text-third-50 flex cursor-pointer'>
                              <span className = 'inset-y-0 left-0 flex items-center'>
                                 <CiLock className = 'h-5 w-5 mr-1'/>
                              </span>
                              <span>{ translate(85) }</span>
                           </div>
                        </div>
                     </div>

                     <div>
                        <label>
                           <input id = '_login' type = 'submit' className = 'hidden' />
                        </label>
                        <button onClick = { (e) => handleLogin(e)} className = 'group relative flex w-full justify-center rounded-md border border-transparent bg-sky-600 py-2 px-4 text-sm font-medium text-white hover:bg-third-50 focus:outline-none '>
                           <span className = 'absolute inset-y-0 left-0 flex items-center pl-3'>
                              <CiLogin className = 'h-6 w-6'/>
                           </span>
                           {auth_loading ? 
                              <Loading loading = { auth_loading } loadingStyle = 'h-5 w-5 text-white' />
                              :  
                              translate(86) 
                           }
                        </button>
                     </div>

                     <div className = 'text-sm md:text-base text-gray-900'>
                        { translate(87) } 
                        <span onClick = { () => navigate('/register') } className = 'text-sky-600 hover:text-third-50 font-semibold cursor-pointer'>
                           &nbsp; { translate(88) }
                        </span>
                     </div>
                  </div>
               </form>

            </div>
         </div>

         <Modal visible = { forgotPasswordModal } customWidth = 'w-[94%] md:w-[35%]'>
            <div className = 'w-full justify-end items-center flex pt-2 pr-2'> 
               <FiX 
                  className = 'text-gray-500 text-2xl cursor-pointer hover:scale-105 hover:text-red-500' 
                  onClick = { () => {
                     setPhoneNo('') 
                     setPhone({})
                     toggleForgotPasswordModal(false)
                  }} 
               />
            </div>
            <div className = 'flex w-full justify-center pt-4'>
               <div className = 'w-full px-4'>
                  <label htmlFor = '_telephone' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400 '>{ translate(58) }</label>
                  <input 
                     value = { phoneNo }
                     onChange = { e => { setPhoneNo(e.target.value); setPhone({ ...phone, phone_number: modifyPhone(e.target.value) }); setResetData({ ...resetData, phone_number: modifyPhone(e.target.value)}) }} 
                     type = 'text' 
                     id = '_telephone' 
                     className = 'block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:outline-none' 
                     placeholder = { translate(89) } 
                     // eslint-disable-next-line
                     maxLength = { tempPhone == 9 ? 9 : 10 } 
                  />
               </div>
            </div>
            <div className = 'flex justify-center w-full pt-7'>
               <div className = 'flex justify-between w-full md:w-full p-4'>
                  <button 
                  onClick = { () => { setPhoneNo(''); setPhone({}); toggleForgotPasswordModal(false)} } 
                  className = 'bg-stone-400 rounded w-[40%] py-1 text-white hover:bg-gray-600'
                  >
                     { translate(63) }
                  </button>

                  <button 
                     disabled = { !phone.phone_number }
                     onClick = { handleForgotPassword } 
                     className = { `${!phone.phone_number ? 'bg-gray-300 text-gray-400' : 'bg-lime-600 hover:bg-lime-700 text-white '} rounded w-[40%] py-1 ` }
                  >
                     {password_loading ?
                        <Loading loading = { password_loading } loadingStyle = 'h-5 w-5 text-white' />
                        :
                        translate(90)
                     }
                  </button>
               </div>
            </div>
         </Modal>

         <Modal visible = { resetPasswordModal } customWidth = 'w-[94%] md:w-[45%]'>
            <p className = 'text-center pt-6 text-gray-600'>
               { translate(91) }
               <span className = 'text-sky-500'>{ phone?.phone_number }</span>
            </p>
            <div className = 'flex w-full justify-center pt-4'>
               <div className = 'w-full px-4'>
                  <label htmlFor = '_code' className = 'block mb-1 text-[0.7rem] font-medium text-gray-400 '>{ translate(92) }</label>
                  <input 
                     onChange = { e => setResetData({ ...resetData, code: e.target.value })} 
                     type = 'number' 
                     id = '_code' 
                     className = 'block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:outline-none' 
                     placeholder = { translate(93) } 
                  />
               </div>
            </div>

            <div className = 'flex justify-center w-full pt-7'>
               <div className = 'flex justify-between w-full md:w-full p-4'>
                  <button 
                     className = 'rounded w-[40%] py-1 text-white bg-stone-400 hover:bg-gray-600'
                     onClick = { () => {
                        setPhone({})
                        toggleResetPasswordModal(false)
                     }} 
                  >
                     { translate(63) }
                  </button>

                  <button 
                     disabled = { !resetData.code }
                     onClick = { handleResetPassword } 
                     className = { `${!resetData.code ? 'bg-gray-300 text-gray-400' : 'bg-lime-600 hover:bg-lime-700 text-white '} rounded w-[40%] py-1` }>
                        {password_loading ?
                           <Loading loading = { password_loading } loadingStyle = 'h-5 w-5 text-white' />
                           :
                           translate(90)
                        }
                  </button>
               </div>
            </div>
         </Modal>
      </div>
   );
};

export default Login;