import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myModel from '../Services/api';

const initialState = {
   purchase_loading: false,
   purchases: [],
   purchase_status: ''
}

export const loadMyPurchases = createAsyncThunk(
   'purchase/loadMyPurchases',
   async (arg, { rejectWithValue }) => {
      try {
         const response = await myModel.find('my-purchases')
         return response
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

const purchaseSlice = createSlice({
   name: 'purchase',
   initialState,
   extraReducers: ({ addCase }) => {
      addCase(loadMyPurchases.pending, (state) => {
         state.purchase_loading = true
         state.purchase_status = 'pending'
      })
      addCase(loadMyPurchases.fulfilled, (state, { payload }) => {
         state.purchases = payload
         state.purchase_status = 'success'
         state.purchase_loading = false
      })
      addCase(loadMyPurchases.rejected, (state) => {
         state.purchase_status = 'rejected'
         state.purchase_loading = false
      })
   }
});

export default purchaseSlice.reducer;