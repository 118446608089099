import AuthService from '../Services/AuthService'

const isInMyBids = (list, bidsLight) => {
   const token = AuthService.getToken();
   let startedBidding = false
   let isTopBidder = false
   let isActive = false

   if(bidsLight?.length && token){
      let idx = bidsLight.findIndex(b => b.auction === list.id)
      if(idx >= 0){
         startedBidding = true
         isActive = bidsLight[idx].is_active
         if(bidsLight[idx].my_bid_value === list.current_price){
            isTopBidder = bidsLight[idx].is_active
         }
      }
   }
   return { startedBidding, isTopBidder, isActive }
}

export default isInMyBids;