import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkMyAuction,
  getWatchedList,
  loadAuctionById,
  loadAuctions,
  setAuctionLoading,
  setNewBidder,
} from "../../features/auctionsSlice";
import {
  addWatchList,
  loadAllWatchList,
  removeWatchList,
} from "../../features/watchListSlice";
import {
  getMySubscriptions,
  loadBanks,
  loadMyBids,
  subscriptionCheck,
  toggleBidSecurityModal,
  toggleBidSubmitModal,
  toggleOneTimeModal,
} from "../../features/bidSlice";
import { isAuctionAboutToEnd, isInMyBids } from "../../utils";
import { toast } from "react-hot-toast";
import AuctionsList from "./AuctionsList";
import AuctionsGrid from "./AuctionsGrid";
import ViewControls from "../viewControls/ViewControls";
import Loading from "../common/Loading";
import AuthService from "../../Services/AuthService";
import Pagination from "../../utils/Pagination";
import translate from "../../Services/translate";
import { Axios } from "axios";
import { base_url } from "../../Services/apiConstants";
import myModel from "../../Services/api";

const Auctions = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const token = AuthService.getToken();

  // eslint-disable-next-line
  const { language } = useSelector(({ language }) => language);
  const { watchlist_status, watchlist_loading, allWatchList } = useSelector(
    ({ watchList }) => watchList
  );
  const {
    auctions,
    auction_loading,
    auctionIsWatchlist,
    auctionById,
    keyword,
    newBidder,
    nowUtcTime,
  } = useSelector(({ auction }) => auction);
  const { viewType, viewBy } = useSelector(({ viewControls }) => viewControls);
  const { sort_by, order } = useSelector(({ viewControls }) => viewControls);
  const { currentPage, limit } = useSelector(({ pagination }) => pagination);
  const {
    bids,
    myBidsLight,
    is_subscription_started,
    my_subscriptions,
    subscriptionCheck_status,
  } = useSelector(({ bid }) => bid);

  const [auctionStatuses, setAuctionStatuses] = useState([]);
  const [selectedAuct, setSelectedAuct] = useState(0);
  const [showNotifierPopup, setShowNotifierPopup] = useState(false);
  const [currentPriceBg, setCurrentPriceBg] = useState(
    "bg-fourth-50 text-white"
  );

  const toggleCurrentPriceBg = () => {
    setCurrentPriceBg((prev) =>
      prev === "bg-fourth-50 text-white"
        ? "bg-white text-gray-800 border border-orange-400"
        : "bg-fourth-50 text-white"
    );
  };

  const getWatchList = () => {
    if (viewBy === "my") {
      dispatch(getWatchedList({ id }));
    } else {
      if (auctionIsWatchlist) {
        getAuctions();
      }
    }
  };

  const getAuctions = () => {
    if (id) {
      if (token) {
        dispatch(loadAllWatchList());
      }
      dispatch(
        loadAuctions({
          id,
          params: { limit, page: currentPage, sort_by, order, keyword },
        })
      );
    }
  };

  const canSeeTopBidder = () => {
    if (is_subscription_started) {
      return token && subscriptionCheck_status;
    } else {
      return true;
    }
  };

  const checkMySubscriptions = async (auction_id) => {
    const response = await myModel.find(
      ``,
      "subscription-check?auction_id=" + auction_id
    );
    return response;
  };
  const goToBidSecurity = (id, auction) => {
    console.log("fffff",id)
    
    dispatch(loadBanks()).then(() => {
      dispatch(toggleBidSecurityModal({ value: true }));
    });

  };

  const goToBidSubmission = (id, auction) => {
 console.log("id",id)
    if (token) {
      setSelectedAuct(id);
      checkMySubscriptions(id).then((data) => {
        checkMyAuction(id).then((auc_data) => {
          if (data.require_subscription && data.user_has_subscription) {
            if (auc_data.can_bid) {
              dispatch(loadAuctionById({ id })).then(() => {
                dispatch(toggleBidSubmitModal({ value: true }));
              });
            } else {
              dispatch(loadAuctionById({ id })).then(() => {
                goToBidSecurity(id, auction);
              });
              
            }
          } else if (!data.require_subscription) {
            if (auc_data.can_bid) {
              dispatch(loadAuctionById({ id })).then(() => {
                dispatch(toggleBidSubmitModal({ value: true }));
              });
            } else {
              goToBidSecurity(id, auction);
            }
          } else if (is_subscription_started) {
            if (
              my_subscriptions.length &&
              my_subscriptions?.some((pack) => pack.is_active === true)
            ) {
              if (auc_data.can_bid) {
                dispatch(loadAuctionById({ id })).then(() => {
                  dispatch(toggleBidSubmitModal({ value: true }));
                });
              } else {
                goToBidSecurity(id, auction);
              }
            } else {
              navigate("/subscription", { state: { auction: auction } });
            }
          } else {
            if (auc_data.can_bid) {
              dispatch(loadAuctionById({ id })).then(() => {
                dispatch(toggleBidSubmitModal({ value: true }));
              });
            } else {
              goToBidSecurity(id, auction);
            }
          }
        });
      });
    } else {
      navigate("/login", { state: { from: location } });
    }
  };

  const addToWatchList = (id) => {
    dispatch(addWatchList({ id })).then(() => {
      if (watchlist_status === "success") {
        dispatch(loadAllWatchList()).then(() => {
          if (watchlist_status === "success" && !watchlist_loading)
            toast.success(translate(1), { duration: 3000 });
        });
      }
    });
  };

  const removeFromWatchList = (id) => {
    dispatch(removeWatchList({ id })).then(() => {
      if (watchlist_status === "success") {
        if (auctionIsWatchlist) getWatchList();
        else dispatch(loadAllWatchList());
        setTimeout(() => {
          if (watchlist_status === "success") {
            toast.success(translate(2), { duration: 3000 });
          }
        }, 500);
      }
    });
  };

  const isWatched = (id) => {
    if (allWatchList.length) {
      const index = allWatchList.findIndex((watchList) => watchList.id === id);
      if (index >= 0) return true;
      return false;
    }
    return false;
  };

  const handleWatchClick = (id) => {
    if (token) {
      if (!isWatched(id)) {
        addToWatchList(id);
      } else {
        removeFromWatchList(id);
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (auctions.length) {
      setAuctionStatuses(
        auctions.map((auction) => ({
          auction,
          current_price: null,
          startedBidding: false,
          isTopBidder: false,
          isAuctionEndingSoon: false,
          processing: false,
          hitGavel: false,
          ended: false,
        }))
      );
      setTimeout(() => {
        dispatch(setAuctionLoading({ value: false }));
      }, 500);
    }
    // eslint-disable-next-line
  }, [auctions]);

  useEffect(() => {
    if (auctions.length) {
      setAuctionStatuses((prevAuctionStatuses) =>
        prevAuctionStatuses.map((auctionStatus) => {
          let idx = auctions.findIndex(
            (auct) => auct.id === auctionStatus.auction.id
          );
          const current_price = auctions[idx].current_price;
          const { startedBidding, isTopBidder } = isInMyBids(
            auctions[idx],
            myBidsLight
          );
          const isAuctionEndingSoon = isAuctionAboutToEnd(
            auctionStatus.auction.end_time,
            nowUtcTime
          );
          const processing =
            new Date(auctionStatus.auction.end_time).getTime() - nowUtcTime <
              0 &&
            nowUtcTime <
              new Date(auctionStatus.auction.end_time).getTime() + 2500;
          const hitGavel =
            new Date(auctionStatus.auction.end_time).getTime() +
              2500 -
              nowUtcTime <
              0 &&
            nowUtcTime -
              (new Date(auctionStatus.auction.end_time).getTime() + 2500) <=
              5200;
          const ended =
            new Date(auctionStatus.auction.end_time).getTime() + 2500 + 5200 <
            nowUtcTime;

          return {
            ...auctionStatus,
            current_price,
            startedBidding,
            isTopBidder,
            isAuctionEndingSoon,
            processing,
            hitGavel,
            ended,
          };
        })
      );
    }

    // eslint-disable-next-line
  }, [auctions, myBidsLight, nowUtcTime]);

  useEffect(() => {
    if (!auctionIsWatchlist) {
      getAuctions();
    }
    // eslint-disable-next-line
  }, [id, currentPage, sort_by, order, keyword]);

  useEffect(() => {
    if (token) {
      dispatch(subscriptionCheck());
      dispatch(getMySubscriptions());
      if (!bids.length) dispatch(loadMyBids());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getWatchList();
    // eslint-disable-next-line
  }, [viewBy, auctionById]);

  useEffect(() => {
    let timer;
    if (newBidder) {
      setShowNotifierPopup(true);
      timer = setTimeout(() => {
        dispatch(setNewBidder(false));
        setShowNotifierPopup(false);
      }, 4000);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [newBidder]);

  useEffect(() => {
    let timer;
    let counter = 0;
    if (newBidder && counter < 5) {
      timer = setTimeout(() => {
        counter += 1;
        toggleCurrentPriceBg();
      }, 100);
    }
    return () => clearTimeout(timer);
  }, [newBidder, currentPriceBg]);

  const auctionProps = {
    goToBidSubmission,
    selectedAuct,
    handleWatchClick,
    isWatched,
    newBidder,
    setNewBidder,
    showNotifierPopup,
    auctionStatuses,
    currentPriceBg,
    canSeeTopBidder,
  };

  return (
    <>
      <div className={`${auctions.length ? "lg:block" : "lg:hidden"} sm:block`}>
        <ViewControls />
      </div>

      {!auctionIsWatchlist ? (
        <div className="hidden md:block w-full">
          <div className="pb-2 w-full flex justify-end">
            <Pagination />
          </div>
        </div>
      ) : null}

      {auction_loading ? (
        <div className="flex justify-center w-full pt-12">
          <Loading
            loading={auction_loading}
            loadingStyle="text-sky-600 w-7 h-7"
          />
        </div>
      ) : (
        <>
          {viewType === "list" ? (
            <AuctionsList {...auctionProps} />
          ) : (
            <AuctionsGrid {...auctionProps} />
          )}
        </>
      )}
    </>
  );
};

export default Auctions;
