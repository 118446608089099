import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentPlatforms, getSubscriptionPackages, resetCheckoutUrl, submitPackage } from '../../features/bidSlice';
import useOutsideClickAction from '../../hooks/useOutsideClickAction';
import Loading from '../common/Loading';
import Modal from '../common/Modal';
import translate from '../../Services/translate';
import { WsUrl } from '../../Services/apiConstants';

const Subscription = () => {

   const dispatch = useDispatch();
   const platformListRef = useRef(null);
   const navigate = useNavigate();
   const location = useLocation();
   const auction_id=location?.state?.auction_id
   const auction=location?.state?.auction

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const { subscription_packages, payment_platforms, subscription_loading, checkout_url } = useSelector(({ bid }) => bid);

   const [showPlatformList, setShowPlatformList] = useState(false);
   const [selectedPackage, setSelectedPackage] = useState(null)
   const [selectedPayment, setSelectedPayment] = useState(null)
   const [showContinuePopup, setShowContinuePopup] = useState(false);

   useOutsideClickAction(platformListRef, () => {
      setShowPlatformList(false);
   });

   const submit_package = () => {
      dispatch(submitPackage({pay_via: selectedPayment.id, package: selectedPackage.id}))
   }

   useEffect(() => {
      dispatch(getSubscriptionPackages(auction_id?auction_id:""))
      dispatch(getPaymentPlatforms())
      // eslint-disable-next-line
   }, [])

   useEffect(() => {
      if(checkout_url) {
         window.open(checkout_url, '_blank')
         navigate('/home')
      }
      dispatch(resetCheckoutUrl())
      // eslint-disable-next-line
   }, [checkout_url])

   // const {url, title,payment_id,auctionData} = route.params;
   const payment_id=selectedPayment?.id;
   const [waitingToReconnect, setWaitingToReconnect] = useState(null);
   useEffect(() => {
     if (waitingToReconnect) {
       return;
     }
       var ws = new WebSocket(WsUrl);
       ws.onopen = () => {
         console.log("payment Websocket Connected",ws);
         // navigation.navigate('item-screen', {auctionItem:auctionData });
       };
 
       ws.onmessage = (e) => {
         console.log("socketData")

         const socketData = JSON.parse(e.data);
         // console.log(socketData)
 
         const type = socketData?.type;
         console.log("type",type)
 
         if (type === "payment_complete") {
           const id = socketData?.payment_id;
           const auction_id = socketData?.auction_id;
           console.log("id",id)
           console.log("auction_id",auction_id)
           if(id==payment_id){
             console.log("kkkkkk",socketData)
           //   navigation.navigate('item-screen', {auctionItem:auctionData })
           }
          
         }

       };
 
       ws.onerror = (e) => {
         console.log("ERROR ->",e);
       };
 
       ws.onclose = (e) => {
         if (waitingToReconnect) {
           return;
         }
      
         console.log("Web-Socket Closed");
         setWaitingToReconnect(true);
         setTimeout(() => setWaitingToReconnect(null), 2000);
       };
 
       return () => {
         console.log("Web-Socket Cleanup");
         ws.close();
       };
     
   }, [waitingToReconnect]);

   return (
      <>
         {subscription_packages.length && payment_platforms.length ?
            <div className = "flex flex-col text-center p-6">
               <h1 className = "text-2xl text-gray-500 font-bold">{ translate(298) }</h1>
               <div className = 'w-full flex justify-center pt-4'>
                  <p className = "text-sm text-gray-700 mb-4 w-[90%] sm:w-[65%] md:w-[35%]">
                     { translate(299) }:
                  </p>
               </div>

               <div className = "grid grid-cols-1 sm:grid-cols-3 sm:gap-4 md:gap-10 px-0 sm:px- md:px-36 text-gray-500 pt-4">
                  {
                     subscription_packages.map((s, idx) => (
                        <div key = { idx } className = "flex flex-col bg-gray-100 p-6 hover:bg-green-600 hover:text-white duration-200 rounded-2xl border border-gray-300 shadow-md shadow-gray-400 group relative overflow-hidden">
                           <h2 className = "text-lg font-bold">{ s.name }</h2>
                           <p className = "text-2xl sm:text-3xl md:text-4xl font-Stencil pt-4 text-green-600 group-hover:text-white">{ s.price }</p>
                           <p className = 'text-sm'>{ translate(300) }</p>
                           <p className = 'pt-5'>{ s.description }</p>
                           <button 
                              className = {`rounded-lg border border-gray-500 hover:bg-white hover:text-green-600 font-bold py-2 px-4 group-hover:border-white ${selectedPayment && selectedPackage.id === s.id ? 'mt-14' : 'mt-28'}`}
                              onClick = {() => {
                                 setSelectedPackage(s)
                                 setShowPlatformList(!showPlatformList)
                              }}
                           >
                              { selectedPayment && selectedPackage.id === s.id ? translate(301) : translate(302) }
                           </button>

                           {selectedPayment && selectedPackage.id === s.id &&
                              <div className = 'bg-gray-100 px-2 py- text-gray-800 rounded-lg absolute bottom-28 right-[45%] text-[0.65rem] group-hover:bg-green-600 group-hover:text-white'>
                                 { payment_platforms.filter(p => p.id === selectedPayment.id)[0].name }
                              </div>
                           }
                        
                           {selectedPayment && selectedPackage.id === s.id ?
                              <button 
                                 className = "rounded-lg border border-gray-500 hover:bg-white hover:text-green-600 font-bold py-2 px-4 mt-3 group-hover:border-white uppercase"
                                 onClick = { submit_package }
                              >
                                 { translate(175) }
                              </button>
                              : 
                              null
                           }

                           <div ref = { platformListRef } className = {`py-2 flex flex-col gap-2 absolute z-30 inset-0 bg-white w-full h-1/2 ${showPlatformList && selectedPackage.id === s.id ? 'show-platform-list' : 'hide-platform-list'} shadow-sm shadow-gray-400 overflow-hidden rounded-t-2xl border-slate-300 border-[1px] px-1`}>
                              <p className = 'text-gray-500 border-b border-gray-400 pt-1 pb-2 text-lg'>{ translate(303) }</p>
                              <div className = 'flex justify-evenly'>
                                 {payment_platforms.map((p, i) => (
                                    <div 
                                       key = { i } 
                                       className = {`border rounded-lg w-20 p-1 flex flex-col items-center justify-center border-gray-300 text-sm shadow-sm shadow-gray-500 hover:scale-105 duration-200 cursor-pointer ${selectedPayment?.id === p.id ? 'border-sky-600' : ''}`}
                                       onClick = {() => {
                                          setSelectedPayment(p)
                                          setShowPlatformList(false);
                                       }}
                                    >
                                       <img src = {p?.logo ? p?.logo : null } className = {`h-14 object-contain`} alt = '' />
                                       <p className = 'text-gray-700' >{ p.name }</p>
                                    </div>
                                 ))}
                              </div>

                           </div>

                        </div>
                     ))
                  }
               </div>
            </div>
            :
            <div className = 'flex justify-center w-full pt-12'>
               <Loading loading = { subscription_loading } loadingStyle = 'text-sky-600 w-7 h-7'/>
            </div>
         }

         <Modal visible = { showContinuePopup } customWidth = 'w-[94%] md:w-[45%]'>
            <div className = 'w-full pb-7 px-4 md:px-8'>
            
               <p className = 'text-center text-lime-600 text-2xl pt-10'>Some text...</p>
               <p className = 'text-center font-semibold pt-2'>some text...</p>
               
               <p className = 'text-center pt-5 text-gray-500'>Have you submitted Successfully?</p>

               <div className = 'flex justify-center w-full pt-7'>
                  <div className = 'flex justify-center w-full md:w-80'>
                     <button onClick = { () => {alert('yes Continue'); setShowContinuePopup(false); navigate('/home')} } className = 'bg-lime-600 rounded w-40 py-2 text-white hover:bg-lime-700'>
                        Yes Continue
                     </button>
                  </div>
               </div>
            </div>
         </Modal>
      </>
   )
}

export default Subscription;