import { useState } from 'react';
import { useSelector } from 'react-redux';
import translate from '../Services/translate';

const ReadMore = ({ textContent }) => {

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);
   const [isReadMore, setIsReadMore] = useState(true);
   
   const toggleReadMore = () => {
      setIsReadMore(!isReadMore)
   }

   return (
      <p className = 'inline w-full'>
         { isReadMore && textContent.length > 200 ? textContent.slice(0, 200) : textContent }
         {
            textContent.length > 200
            ?
            <span
               className = {`text-sky-500 hover:text-sky-700 pl-1 cursor-pointer ${language === 'Amharic' ? 'text-sm' : 'text-lg'}`}
               onClick = { toggleReadMore }
            >
               {/* { isReadMore ? ' ...Read more ▼' : ' Read less ▲'} */}
               { isReadMore ? `${translate(331)} ▼` : `${translate(332)} ▲`}
            </span>
            :
            null
         }
      </p>
   )
}

export default ReadMore;