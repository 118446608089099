import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BsCaretRightFill, BsInstagram, BsYoutube, BsTelephone } from 'react-icons/bs';
import { FaFacebookF, FaTwitter, FaTiktok } from 'react-icons/fa';
import { TfiEmail, TfiLocationPin } from 'react-icons/tfi';
import { playStore_badge, apple_badge, main_logo } from '../../assets';
import Modal from './Modal';
import translate from '../../Services/translate';


const Footer = () => {

   const navigate = useNavigate();

   // eslint-disable-next-line
   const { language } = useSelector(({ language }) => language);

   const [showLocation, setShowLocation] = useState(false);
   
   const footerLinks = [
      {id: 125, url: '/aboutUs'},
      {id: 337, url: '/subscription'},
      {id: 127, url: '/faq'},
      {id: 129, url: '/my-bids'},
      {id: 130, url: '/my-purchases'},
      {id: 131, url: '/my-balance'},
      {id: 132, url: '/my-documents'},
      {id: 42, url: '/termsAndConditions'},
      {id: 134, url: '/privacyPolicy'},
      {id: 339, url: '/tutorials'}
   ]

   const links = [
      {
         url: 'https://instagram.com/auctionethiopia?igshid=NTc4MTIwNjQ2YQ==',
         title: 'Instagram',
         icon: <BsInstagram className = 'w-5 text-rose-600 hover:text-rose-800' />
      },
      {
         url: 'https://www.facebook.com/auctionethiopia?mibextid=LQQJ4d',
         title: 'Facebook',
         icon: <FaFacebookF className = 'w-3 text-third-50 hover:text-sky-500' />
      },
      {
         url: 'https://twitter.com/auctionethiopia',
         title: 'Twitter',
         icon: <FaTwitter className = 'w-5 text-sky-500 hover:text-sky-600'/>
      },
      {
         url: 'https://youtube.com/@auctionethiopia',
         title: 'YouTube',
         icon: <BsYoutube className = 'w-6 text-red-700 hover:text-red-950' />
      },
      {
         url: 'http://www.tiktok.com/@auctionethiopia',
         title: 'Tiktok',
         icon: <FaTiktok className = 'w-[17px] text-gray-900 hover:text-gray-700' />
      }
   ]

   return (
      <div className = 'mt-3 sticky bottom-[-80] right-0 left-0 w-full'>
         <div className = 'mt-2 bg-white flex-row sm:flex justify-between p-4 w-full'>
            <div className = 'text-zinc-500 flex-1'>
               
               <p className = 'text-sky-600 font-bold text-lg'>{ translate(135) }</p>
               <div className = 'py-3'>{ translate(136) }</div>
                  
               <NavLink to = '/aboutUs' className = {({isActive}) => isActive ? 'text-third-50' : 'text-sky-600 hover:text-sky-700 hover:font-semibold'}>
                  { translate(137) }
               </NavLink>

            </div>

            <div className = 'text-zinc-500 flex-1 md:pl-40 pt-3 sm:pt-0'>
               <p className = 'text-sky-600 font-bold text-lg'>{ translate(138) }</p>

               {footerLinks.map((link, i) => (
                  <div key = { i } className = ' flex pt-'>
                     <div className = 'pt-2'><BsCaretRightFill className = 'text-sky-600 text-sm' /></div>
                     <span className = 'pl-3'>
                        <NavLink
                        to = { link.url }
                        className = { ({ isActive }) => isActive ? 
                        'text-sky-700 font-semibold' : 'text-zinc-500 hover:text-primary-50 hover:font-semibold text-xs' }
                        target = { link.id === 42 || link.id === 134 ? `_blank` : null }
                        >
                        { translate(link.id) }
                        </NavLink>
                     </span>
                  </div>
                  ))
               }
            </div>

            <div className = 'text-zinc-500 flex-1 md:pl-16 pb-1 sm:pb-7 pt-3 sm:pt-0'>
               <p className = 'text-sky-600 font-bold text-lg'>{ translate(139) }</p>
               <div className = 'flex flex-col'>
                  <div className = ''>

                  <div className = ' flex pt-1 sm:pt-3'>
                     <div className = ' text-3xl'><TfiEmail className = 'text-sky-600' /></div>
                     <span className = 'pl-5 text-zinc-500 hover:text-primary-50 hover:font-semibold text-base'> 
                        <a href = 'mailTo:info@auctionethiopia.com'>info@auction.et</a>
                     </span>
                  </div>

                  <div className = 'flex pt-2'>
                     <div className = 'text-3xl'><BsTelephone className = 'text-sky-600' /></div>
                     <span className = 'pl-5 text-base'> 
                        <a className = 'text-zinc-500 hover:text-primary-50 hover:font-semibold' href = 'tel:+251905115511'>+251905115511</a> / <a className = 'text-zinc-500 hover:text-primary-50 hover:font-semibold' href = 'tel:+251116668828'>+251116668828</a>
                     </span>
                  </div>

                  <div className = 'flex pt-2 cursor-pointer' onClick = { () => setShowLocation(true) }>
                     <div className = 'text-4xl'><TfiLocationPin className = 'text-sky-600' /></div>
                     <span className = 'pl-5 text-zinc-500 text-base'>
                        <NavLink to = '#' className = { 'text-zinc-500 hover:text-primary-50' }>
                        { translate(140) }
                        </NavLink>
                     </span>
                  </div>

                  </div>

                  <div className = 'pt-4 md:pt-12 flex justify-evenly md:justify-center gap-2'>

                  <a href = 'https://play.google.com/store/apps/details?id=com.auctionethiopia.auctionethiopia&hl=en&gl=US' target = '_blank' rel = "noreferrer">
                     <img src = { playStore_badge } alt = ''  className = 'w-28 hover:border-lime-600 border-2 rounded-lg' />
                  </a>
                  <a href = 'https://apps.apple.com/ke/app/auction-ethiopia/id6468837215' target = '_blank' rel = "noreferrer">
                     <img src = { apple_badge } alt = ''  className = 'w-28 hover:border-lime-600 border-2 rounded-lg' />
                  </a>
                  
                  </div>
               </div>
            </div>
         </div>

         <div className = 'w-full flex justify-center bg-white'>
            <p className = 'border-t border-stone-500 text-center w-[80%]'></p>
         </div>

         <div className = 'h-24 bg-white flex flex-col-reverse md:flex-row justify-around items-center w-full'>

            <div className = 'hidden md:block'>
               <img src = { main_logo } alt = 'Auction Ethiopia S.C' onClick = { () => navigate('/home') } className = 'h-8 md:h-10'/>
            </div>

            <div className = 'hidden sm:block'><p className = 'text-gray-400'>Copyright © 2023 - Auction-Ethiopia,  All rights reserved</p></div>
            <div className = 'block sm:hidden text-center text-gray-400'><p className = 'text-gray-400'>Copyright © 2023 - Auction-Ethiopia, </p><p> All rights reserved,</p></div>

            <div className = 'flex text-3xl md:pr-24'>
               {
                  links.map((link, i) => (
                  <a key = { i } href = { link.url } className = 'pr-5' target = '_blank' rel = "noreferrer" title = { link.title }>{ link.icon }</a>
                  ))
               }
            </div>

         </div>

         <Modal visible = { showLocation } customWidth = 'h-[400px] w-[90%] md:w-[48vw]'>
         <div className = 'h-[85%] w-full'>
            <div className = 'bg-gradient-to-r from-sky-700 via-lime-500 to-lime-50 flex justify-between rounded-t-lg items-center'>
               <div className = 'text-white rounded-t-lg font-Roboto pl-1 md:pl-3 py-1 flex flex-row items-center'>{ translate(141) }</div>
               <div className = 'text-gray-700 text-lg md:text-xl pr-2 cursor-pointer hover:text-red-600' onClick = { () => setShowLocation(false) }>
                  &#10006;
               </div>
            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.29544008865315!2d38.77893939614295!3d8.997245128807883!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b858bf1b02c55%3A0xeac6a7a2ca7f5c6c!2sTO.MO.CA%20-%20Sip%20and%20Create!5e0!3m2!1sen!2set!4v1710037215183!5m2!1sen!2set" className='h-full w-full border-none' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Auction-Ethiopia'></iframe>

         </div>
         </Modal>
      </div>
   );
};

export default Footer;